import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PoiEntry } from 'models/kakao-maps';

import { MapViewInfo } from 'utils/map';
import { MapLevel } from 'types';

interface MapStateInit {
  currentMapViewInfo: MapViewInfo;
  currentPoi: PoiEntry | null;
  showOverlay: boolean;
  showPoiMarker: boolean;
  showPolygons: boolean;
}

const initialState: MapStateInit = {
  currentMapViewInfo: {
    boundsNe: null,
    boundsSw: null,
    level: MapLevel.m250,
  },
  currentPoi: null,
  showOverlay: true,
  showPoiMarker: true,
  showPolygons: true,
};

const map = createSlice({
  name: 'mapState',
  initialState,
  reducers: {
    setCurrentMapViewInfo(state, action: PayloadAction<MapViewInfo>) {
      state.currentMapViewInfo = action.payload;
    },
    setCurrentMapLevel(state, action: PayloadAction<MapLevel>) {
      state.currentMapViewInfo = {
        ...state.currentMapViewInfo,
        level: action.payload,
      };
    },
    setPoi(state, action: PayloadAction<PoiEntry | null>) {
      state.currentPoi = action.payload;
    },
    setShowOverlay(state, action: PayloadAction<boolean>) {
      state.showOverlay = action.payload;
    },
    setShowPoiMarker(state, action: PayloadAction<boolean>) {
      state.showPoiMarker = action.payload;
    },
    setShowPolygons(state, action: PayloadAction<boolean>) {
      state.showPolygons = action.payload;
    },
  },
});

export const {
  setCurrentMapViewInfo,
  setCurrentMapLevel,
  setPoi,
  setShowOverlay,
  setShowPolygons,
  setShowPoiMarker,
} = map.actions;

export default map.reducer;
