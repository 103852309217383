import { gql, useQuery, useMutation } from '@apollo/client';
import jwtDecode from 'jwt-decode';

import { User as CashnoteAccount } from 'graphql/types';

import { useSetAccount } from 'contexts/AccountContext';

import accountFragment from 'graphql/fragments/account.fragment';

import { setToken } from 'utils/jwtToken';
import { hasErrorWithCode } from 'utils/graphql';
import { identifyUser, clearUser } from 'utils/trackEvent';
import t from 'i18n';

const GET_ACCOUNT = gql`
  query getAccount {
    account {
      ...account
    }
  }
  ${accountFragment}
`;

export function useCashnoteLoginGetAccount(skip: boolean) {
  const { data, loading } = useQuery<{ account: CashnoteAccount }>(
    GET_ACCOUNT,
    {
      skip: skip,
    }
  );

  if (loading) {
    return undefined;
  }

  return data?.account;
}

const LOGIN_WITH_PASSWORD = gql`
  mutation login($login: ID!, $password: String!) {
    login(input: { login: $login, password: $password }) {
      user {
        ...account
      }
      jwt
      errors {
        field
        messages
      }
    }
  }
  ${accountFragment}
`;

export function useCashnoteLoginWithPassword() {
  const [loginWithPassword, { loading }] = useMutation(LOGIN_WITH_PASSWORD);
  const setAccount = useSetAccount();

  return async (params: { login: string; password: string }) => {
    if (loading) {
      return;
    }

    try {
      const { data } = await loginWithPassword({
        variables: {
          login: params.login.trim(),
          password: params.password.trim(),
        },
      });

      const { jwt, user } = data.login;

      if (!jwt) {
        alert(
          '아이디/휴대전화번호나 비밀번호가 틀립니다.\n확인 후 정확하게 다시 입력해주세요.'
        );
        return;
      }

      loginSuccess(jwt, user.name);
      setAccount({
        source: 'CASHNOTE',
        name: user.name,
        cashnoteAccount: user,
      });

      return data;
    } catch (error) {
      if (hasErrorWithCode(error, 'no_password')) {
        alert('카카오로 가입된 휴대전화번호입니다. 카카오로 로그인해주세요.');
      } else {
        alert(t('errors.messages.generic'));
      }
    }
  };
}

const LOGIN_WITH_KAKAO = gql`
  mutation loginWithKakao($accessToken: String!) {
    loginWithKakao(input: { accessToken: $accessToken }) {
      user {
        ...account
      }
      jwt
      errors {
        field
        messages
      }
    }
  }
  ${accountFragment}
`;

export function useCashnoteLoginWithKakao() {
  const [loginWithKakao, { loading }] = useMutation(LOGIN_WITH_KAKAO);
  const setAccount = useSetAccount();

  async function login(accessToken: string) {
    try {
      const { data } = await loginWithKakao({
        variables: { accessToken },
      });

      const { jwt, user } = data.loginWithKakao;
      if (!jwt) {
        alert('카카오로 가입된 캐시노트 계정이 아닙니다.');
        return;
      }

      loginSuccess(jwt, user.name);
      setAccount({
        source: 'CASHNOTE',
        name: user.name,
        cashnoteAccount: user,
      });
    } catch (e) {
      console.log(e);
      alert(t('errors.messages.generic'));
    }
  }

  return () => {
    if (loading) {
      return;
    }

    window.Kakao.Auth.login({
      success: (auth: any) => {
        login(auth.access_token);
      },
      fail: (err: any) => {
        if (err.error && err.error === 'access_denied') {
          alert('카카오로 로그인 권한 신청이 거부되었습니다.');
        } else {
          alert(t('errors.messages.generic'));
        }
      },
    });
  };
}

export function useCashnoteLogout() {
  const setAccount = useSetAccount();

  return () => {
    setToken(null);
    setAccount(null);
    clearUser();
  };
}

// FIXME:
function loginSuccess(token: string, name: string) {
  const { sub: userId } = jwtDecode(token);

  identifyUser(`CA-${userId}`, {
    username: name,
    name,
  });

  setToken({ source: 'CASHNOTE', token });
}
