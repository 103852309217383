import styled from 'styled-components';

import colors from '../../constants/colors';

const Component = styled.div`
  width: 390px;

  > .selection {
    padding-bottom: 16px;
    border-bottom: 1px solid #ececef;
  }

  > .description {
    :first-child {
      margin-top: 0;
    }

    font-size: 13px;
    word-break: break-all;

    > .emphasis {
      color: ${colors.red};
      font-weight: bold;
    }

    > .range {
      color: ${colors.blue};
      font-weight: bold;
    }
  }
`;

export default Component;
