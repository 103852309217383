import React from 'react';

import { Box, Text } from 'components/common';
import Position from 'components/common/Position';

import Hint from '../features/storeAnalysis/Hint';

interface SectionProps {
  title: string;
  hint?: {
    title: string;
    contents: React.ReactElement;
  };
  children: React.ReactNode;
}

//     margin: 30px;
//     padding-bottom: 30px;
const Section = ({ title, hint, children }: SectionProps) => (
  <Box p="20px">
    <Position position="relative">
      <Text
        as="h2"
        m="0 0 20px"
        fontSize="16px"
        lineHeight="1"
        fontWeight="bold"
      >
        {title}
      </Text>
      {hint && <Hint {...hint} />}
    </Position>
    {children}
  </Box>
);

export default Section;
