import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

interface ChunkLoadError extends Error {
  name: 'ChunkLoadError';
  type: 'missing' | string | undefined;
}

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error: ChunkLoadError | Error, info: React.ErrorInfo) {
    if (error.name === 'ChunkLoadError' && window.location.hash !== '#retry') {
      window.location.hash = '#retry';
      window.location.reload(true);
      return;
    }

    this.setState({ hasError: true });
    Sentry.withScope((scope) => {
      // @ts-ignore
      scope.setExtras(info);
      Sentry.captureException(error);
    });
  }

  render() {
    // if (this.state.hasError) {
    // }

    return this.props.children;
  }
}

export default ErrorBoundary;
