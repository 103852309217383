import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { format, parse, addWeeks, endOfWeek, getYear } from 'date-fns';
import { useSnackbar } from 'notistack';

import { RootState } from 'app/rootReducer';

import DatePicker from 'components/DatePicker';
import Dropdown from 'components/Dropdown';
import TabBar from 'components/TabBar';
import Section from 'components/Section';
import HintContents from 'components/HintContents';
import HintContentsItem from 'components/HintContentsItem';
import TipBox from 'components/TipBox';

import { formatYearMonthWeek } from 'utils/formatting';
import { getStartDatesOfEachWeekInMonth } from 'utils/date';
import { trackButtonClick } from 'utils/trackEvent';

import ConversionDurationControl from './ConversionDurationControl';
import TrendTabView from './TrendTabView';
import {
  ConversionDuration,
  SalesPeriod,
  setSalesPeriod,
  setSalesStartDate,
  setConversionDuration,
  setUserType,
  fetchChartData,
} from './conversionTrackingAnalysisSlice';

const toConversionDurationDesc = (
  salesStartDate: string,
  conversionDuration: ConversionDuration
) => {
  const startDate = addWeeks(
    parse(salesStartDate, 'yyyy-MM-dd', new Date()),
    conversionDuration.startWeek
  );
  const endDate = endOfWeek(
    addWeeks(
      parse(salesStartDate, 'yyyyy-MM-dd', new Date()),
      conversionDuration.endWeek
    ),
    { weekStartsOn: 1 }
  );

  let conversionDurationDescription;
  if (getYear(startDate) !== getYear(endDate)) {
    conversionDurationDescription = `${format(
      startDate,
      'yyyy.MM.dd'
    )} ~ ${format(endDate, 'yyyy.MM.dd.')}`;
  } else {
    conversionDurationDescription = `${format(
      startDate,
      'yyyy.MM.dd.'
    )} ~ ${format(endDate, 'MM.dd.')}`;
  }

  conversionDurationDescription += ` (판매기간 이후 ${conversionDuration.startWeek}~${conversionDuration.endWeek}주)`;

  return conversionDurationDescription;
};

const ConversionTrackingAnalysisView = ({
  businessId,
}: {
  businessId: number;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  // @ts-ignore
  const {
    chartData,
    salesStartDate,
    salesPeriod,
    conversionDuration,
    userType,
    error,
  } = useSelector(
    (state: RootState) => state.conversionTrackingAnalysis,
    shallowEqual
  );

  const titles = ['전체고객', '신규고객', '재방문고객'];
  const userTypes = ['all', 'new', 'revisit'];

  const [openSales, setOpenSales] = useState(false);
  const [openConversion, setOpenConversion] = useState(false);
  const [tabBarIndex, setTabBarIndex] = useState(0);

  useEffect(() => {
    dispatch(
      fetchChartData(businessId, salesStartDate, conversionDuration, userType)
    );
  }, [businessId, salesStartDate, conversionDuration, userType, dispatch]);

  useEffect(() => {
    if (error !== null) {
      enqueueSnackbar(
        '고객 전환분석에 오류가 발생했습니다. 나중에 다시 시도해 주세요.',
        { variant: 'error' }
      );
    }
  }, [enqueueSnackbar, error]);

  return (
    <Section
      title="고객 전환분석"
      hint={{
        title: '기준 정의',
        contents: (
          <HintContents p="20px" width="333px">
            <HintContentsItem
              title="지속방문 고객"
              description="판매기간에 구매한 고객 중 전환기간 동안 1회 이상 재구매한 고객을 월별로 집계"
            />
            <HintContentsItem
              title="이탈고객"
              description="판매기간에 구매한 고객 중 전환기간 동안 결제하지 않은 고객을 월별로 집계"
            />
          </HintContents>
        ),
      }}
    >
      <Dropdown
        title="판매기간"
        contents={formatYearMonthWeek(salesPeriod)}
        open={openSales}
        onClick={() => setOpenSales(true)}
        onClose={() => setOpenSales(false)}
      >
        <DatePicker
          year={salesPeriod.year}
          month={salesPeriod.month}
          week={salesPeriod.week}
          years={[2019, 2020]}
          onUpdate={(salesPeriod: SalesPeriod) => {
            const { year, month, week } = salesPeriod;
            const salesStartDate = format(
              getStartDatesOfEachWeekInMonth(year, month)[week - 1],
              'yyyy-MM-dd'
            );
            dispatch(setSalesPeriod(salesPeriod));
            dispatch(setSalesStartDate(salesStartDate));
          }}
        />
      </Dropdown>
      <Dropdown
        title="전환기간"
        contents={toConversionDurationDesc(salesStartDate, conversionDuration)}
        open={openConversion}
        onClick={() => setOpenConversion(true)}
        onClose={() => setOpenConversion(false)}
      >
        <ConversionDurationControl
          conversionDuration={conversionDuration}
          onUpdate={(conversionDuration: ConversionDuration) =>
            dispatch(setConversionDuration(conversionDuration))
          }
        />
      </Dropdown>
      <TabBar
        value={tabBarIndex}
        titles={titles}
        onChange={(index) => {
          const value = userTypes[index] as any;
          trackButtonClick(`Store_ConversionAnalysis_${value}`, {
            salesDate: `${salesPeriod.year}/${salesPeriod.month}/${salesPeriod.week}w`,
          });
          setTabBarIndex(index);
          dispatch(setUserType(value));
        }}
      />
      {chartData !== undefined && (
        <TrendTabView
          title={titles[tabBarIndex]}
          data={chartData}
          salesStartDate={salesStartDate}
          conversionDuration={conversionDuration}
        />
      )}
      <TipBox
        mt="20px"
        title="트렌드 데이터 활용TIP!"
        tips={[
          '특정 기간에 구매한 고객을 기준으로 얼마나 지속방문 했고 이탈고객은 어떤 시점에 일어나는지 확인하는 분석입니다. 특정기간에 마케팅 및 프로모션의 성과를 확인할 수 있습니다.',
        ]}
      />
    </Section>
  );
};

export default ConversionTrackingAnalysisView;
