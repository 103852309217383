import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Box } from 'rebass/styled-components';

import Position from 'components/common/Position';

import { RootState } from 'app/rootReducer';
import { setCurrentMapLevel } from './mapSlice';

import type { ButtonProps } from 'rebass';
import { MapLevel } from 'types';

import { ReactComponent as IconZoomIn } from 'assets/icons/ZoomIn.svg';
import { ReactComponent as IconZoomOut } from 'assets/icons/ZoomOut.svg';

interface ZoomButtonProps extends ButtonProps {
  disabled: boolean;
}

const ZoomButton = (props: ZoomButtonProps) => (
  <Button
    width="50px"
    height="50px"
    bg="white"
    sx={{
      display: 'flex',
      alignItems: 'center',
      cursor: props.disabled ? undefined : 'pointer',
      'svg rect': {
        fill: props.disabled ? '#E0E0E0' : undefined,
      },
    }}
    {...props}
  />
);

const Divider = () => <Box mx="auto" width="34px" height="1px" bg="#E0E0E0" />;

export default () => {
  const dispatch = useDispatch();
  const { level } = useSelector(
    (state: RootState) => state.mapView.currentMapViewInfo
  );

  const handleZoomIn = () => {
    if (level > MapLevel.m20) {
      dispatch(setCurrentMapLevel(level - 1));
    }
  };

  const handleZoomOut = () => {
    if (level < MapLevel.km128) {
      dispatch(setCurrentMapLevel(level + 1));
    }
  };

  return (
    <Position
      position="absolute"
      top="20px"
      right="20px"
      width="50px"
      height="101px"
      bg="white"
      sx={{
        borderRadius: '4px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
      }}
    >
      <ZoomButton
        disabled={level <= MapLevel.m20}
        alt="확대"
        onClick={handleZoomIn}
      >
        <IconZoomIn />
      </ZoomButton>
      <Divider />
      <ZoomButton
        disabled={level >= MapLevel.km128}
        alt="축소"
        onClick={handleZoomOut}
      >
        <IconZoomOut />
      </ZoomButton>
    </Position>
  );
};
