const layouts = {
  sidebar: {
    width: 64,
  },
  floatingContainer: {
    width: 440,
  },
};

export default layouts;
