import React from 'react';

import Position from 'components/common/Position';

import layouts from 'constants/layouts';

import type { BoxProps } from 'rebass';

const Container = (props: BoxProps) => (
  <Position
    position="absolute"
    top="0"
    left={`${layouts.sidebar.width}px`}
    width={`${layouts.floatingContainer.width}px`}
    height="100%"
    overflowX="hidden"
    overflowY="auto"
    bg="white"
    sx={{ boxShadow: '2px 0px 6px rgba(0, 0, 0, 0.15)' }}
    {...props}
  />
);

export default Container;
