import React from 'react';
import { Flex } from 'rebass/styled-components';

import paths from 'paths';

import franchiseSettings from 'assets/franchise-settings.svg';

const FranchiseSettings = () => (
  <Flex
    height="64px"
    alignItems="center"
    justifyContent="center"
    sx={{ cursor: 'pointer' }}
  >
    <a
      href={paths.external.franchiseNote.stores()}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={franchiseSettings} alt="Franchise Settings" />
    </a>
  </Flex>
);

export default FranchiseSettings;
