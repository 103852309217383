import { useRef, useEffect } from 'react';

import { useKakaoMap } from 'hooks/useKakaoMap';

interface PolygonProps {
  path: daum.maps.LatLng[];
  strokeWeight?: number;
  strokeColor?: string;
  strokeOpacity?: string;
  strokeStyle?: 'solid' | 'dashed';
  fillColor?: string | number;
  fillOpacity?: number;
  zIndex?: number;
}

const Polygon = ({
  path,
  strokeWeight,
  strokeColor,
  strokeOpacity,
  strokeStyle,
  fillColor,
  fillOpacity,
  zIndex,
}: PolygonProps) => {
  const { kakao, map } = useKakaoMap();

  const marker = useRef<daum.maps.Polygon>();
  if (!marker.current) {
    marker.current = new kakao.maps.Polygon({
      map,
      path,
      strokeWeight,
      strokeColor,
      strokeOpacity,
      strokeStyle,
      fillColor,
      fillOpacity,
      zIndex,
    });
  }

  useEffect(() => {
    return () => {
      marker.current!.setMap(null);
    };
  }, []);

  return null;
};

export default Polygon;
