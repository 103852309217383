import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import Selection from './Selection';
import { getStartDatesOfEachWeekInMonth } from 'utils/date';

const StyledDatePicker = styled.div`
  width: 324px;
  h2 {
    font-size: 13px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;

interface DatePickerProps {
  year: number;
  month: number;
  week: number;
  years: number[];
  onUpdate: (value: any) => void;
}

const DatePicker = ({
  year,
  month,
  week,
  years,
  onUpdate,
}: DatePickerProps) => {
  const yearKinds = years.map((year) => ({
    label: `${year}년`,
    value: year,
  }));

  const monthKinds = _.range(1, 13).map((month) => ({
    label: `${month}월`,
    value: month,
  }));

  const weekKinds = getStartDatesOfEachWeekInMonth(year, month).map(
    (_, index) => ({
      label: `${index + 1}주`,
      value: index + 1,
    })
  );

  const handleSetYear = (year: number) => {
    onUpdate({ year, month, week });
  };

  const handleSetMonth = (month: number) => {
    onUpdate({ year, month, week });
  };

  const handleSetWeek = (week: number) => {
    onUpdate({ year, month, week });
  };

  return (
    <StyledDatePicker>
      <h2>년도 선택</h2>
      <Selection
        multiple={false}
        kinds={yearKinds}
        values={[year]}
        setValues={(values) => {
          values[0] !== undefined && handleSetYear(values[0]);
        }}
      />
      <h2>월 선택</h2>
      <Selection
        multiple={false}
        kinds={monthKinds}
        values={[month]}
        setValues={(values) =>
          values[0] !== undefined && handleSetMonth(values[0])
        }
      />
      <h2>주 선택</h2>
      <Selection
        multiple={false}
        kinds={weekKinds}
        values={[week]}
        setValues={(values) =>
          values[0] !== undefined && handleSetWeek(values[0])
        }
      />
    </StyledDatePicker>
  );
};

export default DatePicker;
