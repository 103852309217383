import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Box, Text } from 'rebass/styled-components';
import InputMask from 'react-input-mask';

import type { BoxProps, ButtonProps } from 'rebass';

import Button from 'components/common/Button';

import colors from 'constants/colors';

const Label = styled.label`
  display: inline-block;
  color: ${colors.charcoal};
  margin-bottom: 8px;
`;

export const Help = styled.span`
  display: block;
  margin-top: 4px;
  font-size: 12px;
  color: ${colors.darkgrey};
  white-space: pre-wrap;
  word-break: break-all;
`;

export const Error = styled(Help)`
  color: ${colors.maroon};
`;

interface GroupProps extends BoxProps {
  id?: string;
  label?: string;
  labelHelp?: string;
  children: React.ReactElement;
  error?: string | boolean;
  help?: string;
}

export const InputGroup = ({
  id,
  label,
  labelHelp,
  children,
  error,
  help,
  ...rest
}: GroupProps) => (
  <Box width="100%" mb="24px" mx="auto" {...rest}>
    {label && (
      <Label htmlFor={id}>
        {label}
        {labelHelp && (
          <Text
            as="span"
            className="label-help"
            fontSize="0.95rem"
            color="primary"
            ml={1}
          >
            {labelHelp}
          </Text>
        )}
      </Label>
    )}
    {React.cloneElement(children, { id, hasError: !!error })}
    {help && !error && <Help>{help}</Help>}
    {error && <Error>{error}</Error>}
  </Box>
);

const inputStyle = css`
  display: block;
  width: 100%;
  padding: 1rem 0.75rem;
  border: 1px solid;
  border-color: ${(props: any) =>
    props.hasError ? colors.maroon : 'rgba(0,0,0,.15)'};
  border-radius: 3px;
  background-color: ${(props: any) =>
    props.readOnly || props.disabled ? colors.concrete : colors.white};
  font-size: 1.1rem;
  line-height: 1.618;
  appearance: none;

  ${(props: any) =>
    props.secure
      ? '-webkit-text-security: disc;'
      : ''} ::-webkit-input-placeholder {
    font-size: 1.1rem;
  }
  ::-moz-placeholder {
    font-size: 1.1rem;
  }
  :-ms-input-placeholder {
    font-size: 1.1rem;
  }
  :-moz-placeholder {
    font-size: 1.1rem;
  }

  ::placeholder {
    color: ${colors.darkgrey};
  }
`;

export const Input = styled.input`
  ${inputStyle};
`;

export const Textarea = styled.textarea`
  ${inputStyle};
  resize: none;
`;

export const MaskedInput = styled(({ hasError, secure, onChange, ...rest }) => (
  <InputMask
    {...rest}
    onChange={(event) => {
      event.target.value = event.target.value.replace(/\s/g, '');
      onChange(event);
    }}
  />
))`
  ${inputStyle};
`;

export const Submit = (props: ButtonProps) => (
  <Button type="submit" variant="primary" mt={4} {...props} />
);
