import React, { useState } from 'react';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Text } from 'components/common';
import Position from 'components/common/Position';
import IconHint from 'components/common/IconHint';
import colors from 'constants/colors';

const Tooltip = withStyles({
  tooltipArrow: {
    left: -4,
    maxWidth: '100vw',
    border: `1px solid ${colors.componentBorder}`,
    boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.15)',
    padding: 0,
    backgroundColor: colors.white,
  },
  arrow: {
    // top: '41px !important',
    color: colors.white,
  },
})(MuiTooltip);

interface HintProps {
  title: string;
  contents: React.ReactElement;
}

const Hint = ({ title, contents }: HintProps) => {
  const [helpSelected, setHelpSelected] = useState(false);

  const handleOpen = () => {
    setHelpSelected(true);
  };

  const handleClose = () => {
    setHelpSelected(false);
  };

  return (
    <Tooltip
      title={contents}
      arrow
      placement="right-start"
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Position
        position="absolute"
        top="0"
        right="0"
        sx={{
          display: 'flex',
          alignItems: 'center',
          lineHeight: 0,
          cursor: 'help',
        }}
      >
        <Text mr="8px" color="sub">
          {title}
        </Text>
        <IconHint size="20px" selected={helpSelected} />
      </Position>
    </Tooltip>
  );
};

export default Hint;
