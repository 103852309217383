import { useEffect, useRef } from 'react';

/**
 * 이전의 값을 저장하고 보여준다. 첫 값이 undefined 인것을 주의해야한다.
 *
 * 아래의 레퍼런스를 참고했다.
 *
 * https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
 *
 * @param value
 */
function usePrevious(value: any) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

export default usePrevious;
