import axios from 'axios';

import { IntervalRange } from 'models/QuantizedRange';
import { TrendChartData, BarChartData } from 'models/ChartData';
import QueryFilters from 'models/QueryFilters';
import {
  formatCurrency,
  formatPopulationCount,
  formatSimpleCurrency,
} from 'utils/formatting';

import { GeoLevel } from 'types';
import { UserType } from 'features/conversionTrackingAnalysis/conversionTrackingAnalysisSlice';
import { getRecentMonths } from 'utils/date';

// TEMOPORARY
const OPTIONS = {
  headers: {
    jwt: process.env.REACT_APP_PLOVER_ACCESS_TOKEN,
  },
};

const PLOVER_URL_BASE = process.env.REACT_APP_PLOVER_API_ENDPOINT;

const TAG_CATEGORIES = ['A', 'B', 'C'];
const VISIT_USER_CATEGORIES = ['new', 'visit_2', 'visit_3_9', 'visit_10_more'];

interface Coordinate {
  lat: number;
  lon: number;
}

interface StoreCategory {
  category1?: string;
  category2?: string;
  category3?: string;
}

export interface MarketAreaSearchParams {
  aggregationCriteria: string;
  geoPoints: Coordinate[];
  geoLevel: GeoLevel;
  interval: IntervalRange;
  revenuePercentileRange: string[];
  queryFilters: QueryFilters;
  storeCategory?: StoreCategory;
}

export interface MarketAreaItem {
  geo_location: string;
  filter_value: any;
  count: number;
  value: number;
  prev_value: number;
}

export async function marketAreaSearch(
  params: MarketAreaSearchParams
): Promise<MarketAreaItem[]> {
  const url = `${PLOVER_URL_BASE}/main/search`;

  const { data } = await axios.post(url, params, OPTIONS);

  return data.result as MarketAreaItem[];
}

export interface GeoPolygon {
  geo_location: string;
  address: string;
  polygon: number[][];
}

export async function marketAreaGeoPolygons(
  geoLocations: string[],
  geoLevel: GeoLevel
): Promise<GeoPolygon[]> {
  const url = `${PLOVER_URL_BASE}/geo_polygons`;
  const params = {
    geoLocations,
    geoLevel,
  };

  const { data } = await axios.post(url, params, OPTIONS);
  return data.result as GeoPolygon[];
}

/**
 * 예: [{category1: "외식업", category2: "한식", category3: "백반/한정식 전문점"}, ...]
 */
export async function fetchMarketAreaClassifications(): Promise<
  [Record<string, string>]
> {
  const url = `${PLOVER_URL_BASE}/classifications`;
  const { data } = await axios.get(url, OPTIONS);

  return data;
}

export async function storeAnalysisTagStatus(
  businessId: number
): Promise<BarChartData | null> {
  const url = `${PLOVER_URL_BASE}/store_analysis/${businessId}/tag_status`;
  const data = (await axios.get(url, OPTIONS)).data;

  if (!data.tag_status) {
    return null;
  }

  const status = new Map<String, number>(
    data.tag_status.map((value: any) => [value.tag, value.visitors_count])
  );
  const values = TAG_CATEGORIES.map((v) => status.get(v) ?? 0);
  const captions = values.map((v) => formatPopulationCount(v));

  return {
    values,
    captions,
  };
}

export async function storeAnalysisTagTrend(
  businessId: number
): Promise<TrendChartData | null> {
  const url = `${PLOVER_URL_BASE}/store_analysis/${businessId}/tag_trend`;
  const data = (await axios.get(url, OPTIONS)).data;

  if (!data.tag_trend) {
    return null;
  }

  const labels = getRecentMonths().map(
    (item) => `${parseInt(item.substr(item.length - 2))}`
  );
  const trend = new Map<String, any>(
    data.tag_trend.map((value: any) => [value.tag, value.monthly_visitor_count])
  );

  const values = TAG_CATEGORIES.map((tag: string) => {
    const value = trend.get(tag) ?? [];
    const map = new Map<string, number>();
    for (let i in value) {
      let item = value[i] as any;
      if (!map.has(item.date_month)) {
        map.set(item.date_month, item.visitors_count);
      }
    }
    return getRecentMonths().map((item) => map.get(item) ?? 0);
  });
  const captions = values.map((sublist: number[]) =>
    sublist.map((item: number) => `${item}`)
  );

  return {
    labels: labels,
    values: values,
    captions: captions,
  };
}

export async function storeAnalysisVisitUserSalesAmountStatus(
  businessId: number
): Promise<BarChartData | null> {
  const url = `${PLOVER_URL_BASE}/store_analysis/${businessId}/visit_user_sales_amount_status`;
  const data = (await axios.get(url, OPTIONS)).data;

  if (!data.visit_user_sales_amount_status) {
    return null;
  }

  const status = new Map<String, number>(
    data.visit_user_sales_amount_status.map((value: any) => [
      value.user_type,
      value.value,
    ])
  );
  const values = VISIT_USER_CATEGORIES.map((v) => status.get(v) ?? 0);
  const captions = values.map((v) => formatSimpleCurrency(v));

  return {
    values,
    captions,
  };
}

export async function storeAnalysisVisitUserSalesAmountTrend(
  businessId: number
): Promise<TrendChartData | null> {
  const url = `${PLOVER_URL_BASE}/store_analysis/${businessId}/visit_user_sales_amount_trend`;
  const data = (await axios.get(url, OPTIONS)).data;

  if (!data.visit_user_sales_amount_trend) {
    return null;
  }

  const labels = getRecentMonths().map(
    (item) => `${parseInt(item.substr(item.length - 2))}`
  );
  const trend = new Map<String, any>(
    data.visit_user_sales_amount_trend.map((value: any) => [
      value.user_type,
      value.monthly_trend,
    ])
  );

  const values = VISIT_USER_CATEGORIES.map((userType: string) => {
    const value = trend.get(userType) ?? [];
    const map = new Map<string, number>();
    for (let i in value) {
      let item = value[i] as any;
      if (!map.has(item.date_month)) {
        map.set(item.date_month, item.value);
      }
    }
    return getRecentMonths().map((item) => map.get(item) ?? 0);
  });
  const captions = values.map((sublist: number[]) =>
    sublist.map((item: number) => formatCurrency(item))
  );

  return {
    labels,
    values,
    captions,
  };
}

export async function storeAnalysisVisitUserUserCountStatus(
  businessId: number
): Promise<BarChartData | null> {
  const url = `${PLOVER_URL_BASE}/store_analysis/${businessId}/visit_user_user_count_status`;
  const data = (await axios.get(url, OPTIONS)).data;

  if (!data.visit_user_user_count_status) {
    return null;
  }

  const status = new Map<String, number>(
    data.visit_user_user_count_status.map((value: any) => [
      value.user_type,
      value.value,
    ])
  );
  const values = VISIT_USER_CATEGORIES.map((v) => status.get(v) ?? 0);
  const captions = values.map((v) => formatPopulationCount(v));

  return {
    values,
    captions,
  };
}

export async function storeAnalysisVisitUserUserCountTrend(
  businessId: number
): Promise<TrendChartData | null> {
  const url = `${PLOVER_URL_BASE}/store_analysis/${businessId}/visit_user_user_count_trend`;
  const data = (await axios.get(url, OPTIONS)).data;

  if (!data.visit_user_user_count_trend) {
    return null;
  }

  const labels = getRecentMonths().map(
    (item) => `${parseInt(item.substr(item.length - 2))}`
  );
  const trend = new Map<String, any>(
    data.visit_user_user_count_trend.map((value: any) => [
      value.user_type,
      value.monthly_trend,
    ])
  );

  const values = VISIT_USER_CATEGORIES.map((userType: string) => {
    const value = trend.get(userType) ?? [];
    const map = new Map<string, number>();
    for (let i in value) {
      let item = value[i] as any;
      if (!map.has(item.date_month)) {
        map.set(item.date_month, item.value);
      }
    }
    return getRecentMonths().map((item) => map.get(item) ?? 0);
  });
  const captions = values.map((sublist: number[]) =>
    sublist.map((item: number) => `${item}명`)
  );

  return {
    labels,
    values,
    captions,
  };
}

export async function storeAnalysisVisitUserUnitPriceStatus(
  businessId: number
): Promise<BarChartData | null> {
  const url = `${PLOVER_URL_BASE}/store_analysis/${businessId}/visit_user_unit_price_status`;
  const data = (await axios.get(url, OPTIONS)).data;

  if (!data.visit_user_unit_price_status) {
    return null;
  }

  const status = new Map<String, number>(
    data.visit_user_unit_price_status.map((value: any) => [
      value.user_type,
      value.value,
    ])
  );
  const values = VISIT_USER_CATEGORIES.map((v) => status.get(v) ?? 0);
  const captions = values.map((v) => formatSimpleCurrency(v));

  return {
    values,
    captions,
  };
}

export async function storeAnalysisVisitUserUnitPriceTrend(
  businessId: number
): Promise<TrendChartData | null> {
  const url = `${PLOVER_URL_BASE}/store_analysis/${businessId}/visit_user_unit_price_trend`;
  const data = (await axios.get(url, OPTIONS)).data;

  if (!data.visit_user_unit_price_trend) {
    return null;
  }

  const labels = getRecentMonths().map(
    (item) => `${parseInt(item.substr(item.length - 2))}`
  );
  const trend = new Map<String, any>(
    data.visit_user_unit_price_trend.map((value: any) => [
      value.user_type,
      value.monthly_trend,
    ])
  );

  const values = VISIT_USER_CATEGORIES.map((userType: string) => {
    const value = trend.get(userType) ?? [];
    const map = new Map<string, number>();
    for (let i in value) {
      let item = value[i] as any;
      if (!map.has(item.date_month)) {
        map.set(item.date_month, item.value);
      }
    }
    return getRecentMonths().map((item) => map.get(item) ?? 0);
  });
  const captions = values.map((sublist: number[]) =>
    sublist.map((item: number) => formatCurrency(item))
  );

  return {
    labels,
    values,
    captions,
  };
}

export interface StoreAnalysisCohortTrendRequestPayload {
  salesDate: string;
  conversionDuration: {
    from: string;
    to: string;
  };
  userType: UserType;
}

interface CountPerDate {
  date: string;
  count: number;
}

export interface CohortTrend {
  business_id: number;
  name: string;
  conversion_stat: CountPerDate[];
  bounce_stat: CountPerDate[];
}

/**
 * GET /store_analysis/${businessId}/cohort_trend
 *
 * 예.
 *
 * - 판매기간: 2020년 7월 1주 -> 2020년 6월 29일(7월 1주는 7월 1일이 월화수목 안에 있기 때문에
 *     7월 1일이 든 주가 첫 주며, 이 API 에서는 그 주의 월요일을 무조건 값으로 사용해야 한다.
 * - 전환기간: 2020.07.06 - 2020.07.20 (판매기간 이후 1 부터 3주) -> 2020.07.20 일이 아니라
 *  2020.07.26일(일)까지여야 할 것으로 보임
 *
 * Request payload:
 * {
 *   "salesDate": "2020-06-29", // required (판매기간: 해당 주의 월요일)
 *   "conversionDuration": {
 *     "from": "2020-07-06",
 *     "to": "2020-07-20"
 *   },
 *   "userType": "all"
 * }
 *
 * @param businessId
 * @param payload
 */
export async function fetchStoreAnalysisCohortTrend(
  businessId: number,
  payload: StoreAnalysisCohortTrendRequestPayload
): Promise<CohortTrend> {
  const url = `${PLOVER_URL_BASE}/store_analysis/${businessId}/cohort_trend`;
  const { data } = await axios.post<CohortTrend>(url, payload, OPTIONS);

  return data;
}

interface StoreInfo {
  address: Record<'sido' | 'sigungu' | 'dong', string>;
  classification: Record<
    'depth_1_name' | 'depth_2_name' | 'depth_3_name',
    string
  >;
}

export async function fetchSearchStore(publicId: string): Promise<StoreInfo> {
  const url = `${PLOVER_URL_BASE}/store_info/${publicId}`;
  const { data } = await axios.get<StoreInfo>(url, OPTIONS);

  return data;
}
