import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components/macro';

import colors from 'constants/colors';

const theme = {
  colors,
  shadows: {
    container: '2px 0px 6px rgba(0, 0, 0, 0.15)',
  },
  text: {
    link: {
      color: 'primary',
      textDecoration: 'underline',
      fontSize: '12px',
    },
  },
  buttons: {
    primary: {
      color: 'white',
      bg: 'primary',
    },
    primaryLight: {
      color: 'white',
      bg: 'primaryLight',
    },
    outline: {
      bg: 'white',
      color: 'initial',
      border: `1px solid ${colors.border}`,
    },
    kakao: {
      bg: '#F8DE32',
      color: 'initial',
    },
  },
};

const ThemeProvider = ({ children }: { children: React.ReactNode }) => (
  <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
);

export default ThemeProvider;
