import React from 'react';
import styled from 'styled-components';

import colors from '../constants/colors';
import SelectionKind from '../models/Selection';

export interface SelectionProps<T> {
  kinds: SelectionKind<T>[];
  values: T[];
  setValues: (values: T[]) => void;
  multiple?: boolean;
  disabled?: boolean;
}

const SelectionComponent = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
`;

interface SelectionItemComponentProps {
  active: boolean;
  disabled?: boolean;
}

const SelectionItemComponent = styled.div<SelectionItemComponentProps>`
  border: 1px solid ${colors.componentBorder};
  border-radius: 4px;
  padding: 5px 14px;
  margin-right: 10px;
  margin-bottom: 10px;

  font-size: 13px;
  color: ${(props) =>
    props.disabled ? colors.gray300 : props.active ? colors.white : '#555555'};
  background-color: ${(props) =>
    props.active ? colors.primary : 'transparent'};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const toggleAll = <T extends {}>(
  currentValues: T[],
  kinds: SelectionKind<T>[]
): T[] => {
  if (currentValues.length === kinds.length) {
    return [];
  } else {
    return kinds.map((value) => {
      return value.value;
    });
  }
};

const toggle = <T extends {}>(
  currentValues: T[],
  kind: SelectionKind<T>
): T[] => {
  const index = currentValues.indexOf(kind.value);
  if (index >= 0) {
    return [
      ...currentValues.slice(0, index),
      ...currentValues.slice(index + 1),
    ];
  } else {
    return [...currentValues, kind.value];
  }
};

const Selection = <T extends {}>(props: SelectionProps<T>) => {
  const multiple = props.multiple ?? true;

  return (
    <SelectionComponent className="selection-container">
      {multiple ? (
        <SelectionItemComponent
          active={props.values.length === props.kinds.length}
          onClick={() => {
            props.setValues(toggleAll(props.values, props.kinds));
          }}
        >
          전체
        </SelectionItemComponent>
      ) : null}
      {props.kinds.map((value, index) => {
        return (
          <SelectionItemComponent
            key={`${index}`}
            active={props.values.indexOf(value.value) >= 0}
            disabled={value.disabled}
            onClick={() => {
              if (value.disabled) {
                return;
              }
              if (multiple) {
                props.setValues(toggle(props.values, value));
              } else {
                props.setValues([value.value]);
              }
            }}
          >
            {value.label}
          </SelectionItemComponent>
        );
      })}
    </SelectionComponent>
  );
};

export default Selection;
