// @ts-nocheck
import { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { useKakaoMap } from 'hooks/useKakaoMap';

interface CustomOverlayProps {
  position: daum.maps.LatLng;
  xAnchor?: number;
  yAnchor?: number;
  zIndex?: number;
  children: React.ReactNode;
}

const CustomOverlay = ({
  position,
  xAnchor,
  yAnchor,
  zIndex,
  children,
}: CustomOverlayProps) => {
  const { kakao, map } = useKakaoMap();

  const customOverlay = useRef<daum.maps.CustomOverlay>();
  const content = useRef(document.createElement('div'));
  if (!customOverlay.current) {
    customOverlay.current = new kakao.maps.CustomOverlay({
      map,
      content: content.current,
      position,
      xAnchor,
      yAnchor,
      zIndex,
    });
  }

  useEffect(() => {
    ReactDOM.render(children, content.current);
    customOverlay.current!.setContent(content.current);
  }, [children]);

  useEffect(() => {
    customOverlay.current!.setPosition(position);
  }, [position]);

  useEffect(() => {
    return () => {
      customOverlay.current!.setMap(null);
    };
  }, []);

  return null;
};

export default CustomOverlay;
