import React from 'react';
import styled from 'styled-components';

import colors from '../constants/colors';
import { BarChartData, ChartLegend } from '../models/ChartData';
import { shadeColor } from '../utils/color';

export const BAR_CHART_COLORS = [
  { color: '#FFAAB5', borderColor: '#D66B79' },
  { color: '#FFC68D', borderColor: '#EB8520' },
  { color: '#C0CEFF', borderColor: '#7C97F8' },
  { color: '#A1F2F0', borderColor: '#11D4CE' },
];

interface BarChartProps {
  width: number;
  height?: number;
  margin?: number;
  data: BarChartData;
  legends: ChartLegend[];
}

const StyledBarChart = styled.svg`
  > .label {
    font-size: 13px;
    fill: #555555;
    line-height: 19px;
  }

  > .caption {
    font-size: 13px;
    fill: #555555;
    line-height: 19px;

    > .emphasis {
      font-weight: bold;
    }
  }

  > .divider {
    stroke: ${colors.black};
  }

  > .diff {
    font-size: 12px;
    font-weight: bold;
    line-height: 19px;
  }

  > .positive {
    fill: #3965ff;
  }

  > .negative {
    fill: #ff1919;
  }
`;

const LabelWidth = 75;

const BarChart = (props: BarChartProps) => {
  if (
    props.legends.length === 0 ||
    props.legends.length !== props.data.values.length
  ) {
    return <div />;
  }

  const height = props.height ?? props.legends.length * 48;
  const margin = props.margin ?? 9;

  const viewBox = `0 0 ${props.width} ${height}`;
  const barHeight = height / props.legends.length;

  const elements = [];
  let y = 0;
  const max = Math.max(...props.data.values);
  const sum = props.data.values.reduce((a, b) => a + b);

  let maxWidth = props.width - 190;
  if (props.data.diff !== undefined) {
    maxWidth -= 50;
  }

  const darkerColors = props.legends.map((value) =>
    shadeColor(value.color, -15)
  );

  for (let i = 0; i < props.legends.length; ++i) {
    const labelY = y + margin + barHeight / 2 - 5;
    elements.push(
      <text
        key={`label-${i}`}
        className="label"
        textAnchor="end"
        x="65"
        y={labelY}
      >
        {props.legends[i].label}
      </text>
    );
    const barWidth = (props.data.values[i] / max) * maxWidth;
    elements.push(
      <rect
        key={`bar-${i}`}
        x={LabelWidth}
        y={y + margin}
        width={barWidth}
        height={barHeight - margin * 2}
        rx="1.5"
        fill={props.legends[i].color}
        stroke={darkerColors[i]}
      />
    );
    elements.push(
      <text
        key={`caption-${i}`}
        className="caption"
        x={LabelWidth + barWidth + 10}
        y={labelY}
      >
        <tspan className="emphasis">{props.data.captions[i]}</tspan>
        &nbsp;({Math.round((props.data.values[i] / sum) * 100)}%)
      </text>
    );
    if (props.data.diff !== undefined) {
      let className;
      if (props.data.diff[i] >= 0) {
        className = 'diff positive';
      } else {
        className = 'diff negative';
      }
      elements.push(
        <text
          key={`diff-${i}`}
          x={props.width}
          y={labelY}
          className={className}
          textAnchor="end"
        >
          {props.data.diff[i].toFixed(1)}%
        </text>
      );
    }
    y += barHeight;
  }

  return (
    <StyledBarChart
      className="chart bar-chart"
      width={props.width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="70" y1="0" x2="70" y2={height} className="divider" />
      {elements}
    </StyledBarChart>
  );
};

export default BarChart;
