import styled from 'styled-components/macro';
import { Box } from 'rebass/styled-components';
import { position } from 'styled-system';

import type { BoxProps } from 'rebass';
import type { PositionProps as PositionPropsType } from 'styled-system';

export interface PositionProps extends BoxProps, PositionPropsType {}

const Position = styled(Box)<PositionProps>(position);

export default Position;
