import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as _ from 'lodash';

import { AppThunk } from 'app/store';
import {
  StoreCategory,
  SubStoreCategoriesByStoreCategoryPath,
} from 'models/StoreCategories';
import { fetchMarketAreaClassifications } from 'api/plover';

/**
 * 대분류부터 시작해서 하나씩 하위 분류를 찾아가는 구조이므로 대분류와 분류당
 * 하위 분류(예, 대분류 -> 중분류) 룩업을 가지고 있으면 된다.
 */
export interface StoreCategoriesState {
  largeCategories: StoreCategory[];
  categoryLookup?: SubStoreCategoriesByStoreCategoryPath;
}

const initialState: StoreCategoriesState = {
  largeCategories: [],
  categoryLookup: undefined,
};

const storeCategories = createSlice({
  name: 'storeCategories',
  initialState,
  reducers: {
    setLargeCategories(state, action: PayloadAction<[Record<string, string>]>) {
      state.largeCategories = _.uniq(
        action.payload.map((r) => r.category1).filter((c) => c !== undefined)
      );
    },
    setCategoryLookup(state, action: PayloadAction<[Record<string, string>]>) {
      state.categoryLookup = {};
      action.payload.forEach((r) => {
        const key = r.category3 ? `${r.category1}:${r.category2}` : r.category1;
        const value = r.category3 ? r.category3 : r.category2;

        if (!(key in state.categoryLookup!)) {
          state.categoryLookup![key] = [];
        }

        state.categoryLookup![key].push(value);
      });
    },
  },
});

export const {
  setLargeCategories,
  setCategoryLookup,
} = storeCategories.actions;

export default storeCategories.reducer;

export const buildStoreCategories = (): AppThunk => async (dispatch) => {
  const records = await fetchMarketAreaClassifications();

  dispatch(setLargeCategories(records));
  dispatch(setCategoryLookup(records));
};
