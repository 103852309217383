import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass/styled-components';

import Button, { LinkButton } from 'components/common/Button';
import Hr from 'components/common/Hr';
import Icon from 'components/common/Icon';

import withKakao from 'hocs/withKakao';
import { useCashnoteLoginWithKakao } from 'hooks/useCashnoteLogin';

import logo from 'assets/logo.png';

import paths from 'paths';

const Label = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text fontSize="16px" fontWeight="bold" mb="20px">
      {children}
    </Text>
  );
};

const FranchiseLogin = () => {
  return (
    <Box>
      <Label>프랜차이즈 본사</Label>
      <Link to={paths.auth.franchise()}>
        <Button variant="primaryLight">로그인</Button>
      </Link>
      <LinkButton
        to={paths.external.franchiseNote.signup()}
        chevron="right"
        mt="20px"
      >
        프랜차이즈 본사 계정 신청
      </LinkButton>
    </Box>
  );
};

const CashnoteLogin = withKakao(
  ({ isKakaoLoaded }: { isKakaoLoaded: boolean }) => {
    const loginWithKakao = useCashnoteLoginWithKakao();

    return (
      <Box>
        <Label>가맹점 고객 (캐시노트 계정으로 로그인)</Label>
        {isKakaoLoaded && (
          <Button variant="kakao" mb="18px" onClick={loginWithKakao}>
            <Icon name="comment" size="18px" mr="10px" />
            카카오 로그인
          </Button>
        )}
        <Link to={paths.auth.cashnote()}>
          <Button variant="outline">휴대전화번호 로그인</Button>
        </Link>
      </Box>
    );
  }
);

const SelectLogin = () => {
  return (
    <Box px="30px">
      <Flex
        py="60px"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <img src={logo} alt="상권노트" />
        <Text fontSize="21px" mt="20px">
          안녕하세요. 상권노트입니다
        </Text>
      </Flex>
      <FranchiseLogin />
      <Hr my="30px" />
      <CashnoteLogin />
    </Box>
  );
};

export default SelectLogin;
