import { FranchiseUserAccount } from 'api/bluebird';
import { User as CashnoteAccount } from 'graphql/types';

const AUTH_SOURCES = {
  CASHNOTE: 'cashnote',
  FRANCHISE: 'franchise',
} as const;

export type AuthSourceType = keyof typeof AUTH_SOURCES;

export interface Account {
  source: AuthSourceType;
  name: string;
  cashnoteAccount?: CashnoteAccount;
  franchiseAccount?: FranchiseUserAccount;
}

/**
 * Kakao 지도 level(mapLevel)
 *
 *  1: ~ 20m
 *  2: ~ 30m
 *  3: ~ 50m (초기값)
 *  4: ~ 100m
 *  5: ~ 250m
 *  6: ~ 500m
 *  7: ~ 1km
 *  8: ~ 2km
 *  9: ~ 4km
 * 10: ~ 8km
 * 11: ~ 16km
 * 12: ~ 32km
 * 13: ~ 64km
 * 14: ~ 128km
 */
export enum MapLevel {
  m20 = 1,
  m30,
  m50,
  m100,
  m250, // 5
  m500,
  km1,
  km2,
  km4,
  km8, // 10
  km16,
  km32,
  km64,
  km128,
}

/**
 * geoLevel
 *
 * 1: 시
 * 2: 구
 * 3: 동
 */
export enum GeoLevel {
  Si = 1,
  Gu,
  Dong,
}
