import axios from 'axios';
import camelize from 'camelize';
import snakeize from 'snakeize';

import { getToken } from 'utils/jwtToken';
import { Franchise } from 'models/Store';

const defaultTransformRequest = axios.defaults.transformRequest ?? [];
const defaultTransformResponse = axios.defaults.transformResponse ?? [];
const transformResponse = Array.isArray(defaultTransformResponse)
  ? defaultTransformResponse
  : [defaultTransformResponse];

const api = axios.create({
  baseURL: process.env.REACT_APP_BLUEBIRD_API_ENDPOINT,
  transformRequest: [snakeize].concat(...defaultTransformRequest),
  transformResponse: transformResponse.concat(camelize),
});

const cashnoteToken = (token?: string) => ({
  Authorization: `Bearer ${token || getToken()?.token}`,
});

export type FranchiseUserAccount = {
  id: number;
  type: 'head' | 'branch';
  franchiseBrandName: string | null;
  franchiseName: string;
  managerName: string;
};

type FranchiseAuthResponse = FranchiseUserAccount & {
  jwtToken: string;
  temporaryPasswordFlag: boolean;
  message?: string;
};

type FranchiseStores = { brands: Franchise[] };
type FranchiseNamesForBusinesses = { [key: string]: string | null };

export default {
  auth: {
    login(login: string, password: string) {
      return api.post<FranchiseAuthResponse>('/login', {
        login,
        password,
      });
    },
    info() {
      return api.get<FranchiseUserAccount>('/my_info', {
        headers: cashnoteToken(),
      });
    },
  },
  franchiseStores: {
    stores() {
      return api.get<FranchiseStores>('/franchise_stores', {
        headers: cashnoteToken(),
      });
    },
    allowed(registrationNumbers: string[], token?: string) {
      return api.get<FranchiseNamesForBusinesses>('/franchise_stores/allowed', {
        params: { registration_numbers: registrationNumbers.join(',') },
        headers: cashnoteToken(token),
      });
    },
  },
};
