import React, { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Popup from 'components/Popup';
import ClearIcon from 'assets/icons/Clear.svg';
import { togglePopup } from './mapExposureCriteriaSlice';
import colors from 'constants/colors';

interface QueryFilterItemStyleProps {
  active: boolean;
  enabled: boolean;
}

const QueryFilterItemComponent = styled.div<QueryFilterItemStyleProps>`
  margin: 0 10px 10px 0;
  padding: 4px 13px;
  border: 1px solid
    ${(props) => (props.active ? colors.primary : colors.componentBorder)};
  border-radius: 4px;
  color: ${(props) => (props.active ? colors.primary : colors.gray)};
  background: ${(props) => (props.active ? '#F3F6F6' : 'none')};
  font-size: 13px;
  line-height: 20px;
  opacity: ${(props) => (props.enabled ? 1.0 : 0.3)};
  cursor: pointer;

  > .clear-button {
    margin-left: 8px;
    vertical-align: middle;
  }
`;

export interface QueryFilterItemProps {
  name: string;
  description: string | null;
  onCommit: () => void;
  onClearFilter: () => void;
  onClose: () => void;
  currentPopupKey: string | null;
  enabled: boolean;
  children: React.ReactNode;
}

const ClearButton = ({ onClick }: { onClick: (e: MouseEvent) => void }) => (
  <img className="clear-button" alt="clear" src={ClearIcon} onClick={onClick} />
);

function QueryFilterItem({
  name,
  description,
  currentPopupKey,
  enabled,
  onClearFilter,
  onCommit,
  onClose,
  children,
}: QueryFilterItemProps) {
  const dispatch = useDispatch();

  function handleOnClear(e: MouseEvent) {
    e.stopPropagation();
    onClearFilter();
  }

  return (
    <div>
      <QueryFilterItemComponent
        active={currentPopupKey === name || description !== null}
        enabled={enabled}
        onClick={() => enabled && dispatch(togglePopup(name))}
      >
        <span>
          {name}
          {description && <>&nbsp;({description})</>}
        </span>
        {description && <ClearButton onClick={handleOnClear} />}
      </QueryFilterItemComponent>
      <Popup open={currentPopupKey === name} onClose={onClose}>
        {children}
        <button className="submit" onClick={() => onCommit()}>
          저장
        </button>
      </Popup>
    </div>
  );
}

export default QueryFilterItem;
