import browserHistory from 'browserHistory';

export default {
  push: (to: any) => {
    if (typeof to !== 'string' || to.startsWith('/')) {
      browserHistory.push(to);
    } else {
      window.location.href = to;
    }
  },
  replace: browserHistory.replace,
  goBack: browserHistory.goBack,
};
