import React, { useState } from 'react';

import { Box, Flex, Text } from 'components/common';
import TabBar from 'components/TabBar';

import Store, { Franchise } from 'models/Store';

import { ReactComponent as ArrowIcon } from 'assets/icons/Arrow.svg';
import StoreIcon from 'assets/icons/Store.svg';

import type { FlexProps } from 'rebass';
import Popup from 'components/Popup';

interface StoreSelectionItemProps extends FlexProps {
  allowed: boolean;
}

const StoreSelectionItem = ({ allowed, ...rest }: StoreSelectionItemProps) => (
  <Flex
    py="16px"
    alignItems="center"
    {...rest}
    sx={{
      borderBottom: '1px solid #ececef',
      cursor: allowed ? 'pointer' : undefined,
    }}
  />
);

const AllowedTag = ({ allowed }: any) => {
  const text = allowed ? '연동 완료' : '연동 대기';
  const bg = allowed ? 'teal' : '#9491A1';

  return (
    <Text
      width="59px"
      p="5px 0"
      bg={bg}
      color="white"
      fontSize="11px"
      fontWeight="bold"
      lineHeight="1"
      textAlign="center"
      sx={{ borderRadius: '21px' }}
    >
      {text}
    </Text>
  );
};

interface StoreSelectionListProps {
  franchises: Franchise[];
  onClick: (value: any) => void;
}

const StoreSelectionList = ({
  franchises,
  onClick,
}: StoreSelectionListProps) => {
  const [value, setValue] = useState(0);

  const components = franchises.map((item, i) => (
    <Box>
      {item.stores.map((store, j) => (
        <StoreSelectionItem
          key={`store-selection-${i}-${j}`}
          allowed={store.allowed}
          onClick={() => store.allowed && onClick(store)}
        >
          <Box flex="1" sx={{ lineHeight: 1 }}>
            <Text color="gray700" fontSize="14px" fontWeight="bold">
              {store.name}
            </Text>
            <Text mt="8px" color="sub" fontSize="12px">
              {store.address}
            </Text>
          </Box>
          <AllowedTag allowed={store.allowed} />
        </StoreSelectionItem>
      ))}
    </Box>
  ));

  return (
    <Box minWidth="340px" maxHeight="325px" overflowY="auto">
      <TabBar
        value={value}
        titles={franchises.map((v) => v.name)}
        onChange={setValue}
      />
      {components[value]}
    </Box>
  );
};

interface DropdownArrowIconProps {
  up?: boolean;
}
const DropdownArrowIcon = ({ up = false }: DropdownArrowIconProps) => (
  <ArrowIcon
    style={{
      transform: up ? 'rotate(180deg)' : undefined,
      transition: 'transform 0.2s',
    }}
  />
);

interface StoreInfoProps {
  store?: Store;
  arrowUp?: boolean;
  onClick?: () => void;
}
const StoreInfo = ({ store, arrowUp = false, onClick }: StoreInfoProps) => {
  const clickable = onClick !== undefined;

  if (!store) {
    return null;
  }

  return (
    <>
      <Flex
        alignItems="center"
        sx={{ cursor: clickable ? 'pointer' : undefined }}
        onClick={onClick}
      >
        <Text px="2px" fontSize="20px" lineHeight="1" fontWeight="bold">
          {store.name}
        </Text>
        {clickable && <DropdownArrowIcon up={arrowUp} />}
      </Flex>
      <Flex mt="8px" alignItems="center" sx={{ lineHeight: '1' }}>
        <img src={StoreIcon} alt="Store icon" />
        <Text ml="8px" color="#333333" fontSize="12px">
          {store.address}
        </Text>
      </Flex>
    </>
  );
};

interface StoreSelectionProps {
  franchises?: Franchise[];
  currentStore?: Store;
  onSelect: (store: Store) => void;
}

const StoreSelection = ({
  franchises,
  currentStore,
  onSelect,
}: StoreSelectionProps) => {
  const [open, setOpen] = useState(false);

  const hasMoreThanOneStore =
    franchises !== undefined &&
    franchises.reduce((acc, franchise) => acc + franchise.stores.length, 0) > 1;

  return (
    <Box p="20px">
      <StoreInfo store={currentStore} arrowUp={open} />
      {franchises && hasMoreThanOneStore && (
        <Popup open={open} onClose={() => setOpen(false)}>
          <StoreSelectionList
            franchises={franchises}
            onClick={(value: Store | undefined) => {
              if (value !== undefined) {
                onSelect(value);
              }
              setOpen(false);
            }}
          />
        </Popup>
      )}
    </Box>
  );
};

export default StoreSelection;
