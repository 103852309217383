import React from 'react';
import { Switch, useRouteMatch, Redirect, useLocation } from 'react-router-dom';

import Route from 'components/Route';
import FloatingContainer from 'components/common/FloatingContainer';
import SelectLogin from './SelectLogin';
import Franchise from './Franchise';
import Cashnote from './Cashnote';

import { useAccount } from 'contexts/AccountContext';

import paths from 'paths';

const Auth = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const account = useAccount();

  if (account) {
    return <Redirect to={paths.map()} />;
  }

  function handleClose() {
    if (pathname.endsWith('auth')) {
      paths.push(paths.map());
    } else {
      paths.goBack();
    }
  }

  return (
    <FloatingContainer onClose={handleClose}>
      <Switch>
        <Route exact path={path} pageName="Auth">
          <SelectLogin />
        </Route>
        <Route exact path={`${path}/franchise`} pageName="Auth_Franchise">
          <Franchise />
        </Route>
        <Route exact path={`${path}/cashnote`} pageName="Auth_Cashnote">
          <Cashnote />
        </Route>
      </Switch>
    </FloatingContainer>
  );
};

export default Auth;
