import React, { useState } from 'react';
import styled from 'styled-components';

import colors from '../constants/colors';

interface ViewSwitchProps {
  titles: string[];
  components: React.ReactNode[];
  children?: React.ReactNode;
  onChange?: (index: number) => void;
}

const StyledViewSwitch = styled.div`
  > .switch {
    display: inline-flex;
    list-style-type: none;
    background: #f2f2f2;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    height: 34px;

    > li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 34px;
      margin: -1px;
      float: left;
      cursor: pointer;
      font-size: 13px;
      color: #555;
    }

    > .selected {
      background: ${colors.white};
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      font-weight: bold;
    }
  }
`;

const ViewSwitch = (props: ViewSwitchProps) => {
  const [currentView, setCurrentView] = useState(0);

  if (
    props.titles.length === 0 ||
    props.titles.length !== props.components.length
  ) {
    return <div />;
  }

  return (
    <StyledViewSwitch>
      <ul className="switch">
        {props.titles.map((value, index) => (
          <li
            key={index}
            className={currentView === index ? 'selected' : undefined}
            onClick={() => {
              props.onChange?.(index);
              setCurrentView(index);
            }}
          >
            {value}
          </li>
        ))}
      </ul>
      {props.children}
      {props.components[currentView]}
    </StyledViewSwitch>
  );
};

export default ViewSwitch;
