import React from 'react';

import { Flex, Text } from 'components/common';

const DurationView = ({ start, end }: { start: string; end: string }) => (
  <Flex
    mb="10px"
    alignItems="center"
    height="40px"
    sx={{ border: '1px solid #e0e0e0', borderRadius: '4px' }}
  >
    <Text ml="14px" color="gray" fontSize="13px">
      분석기간
    </Text>
    <Text ml="8px" color="black" fontSize="13px">{`${start} ~ ${end}`}</Text>
  </Flex>
);

export default DurationView;
