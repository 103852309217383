import React from 'react';
import styled from 'styled-components/macro';
import { space, color } from 'styled-system';

import type { BaseProps } from 'rebass';
import type { SpaceProps, ColorProps } from 'styled-system';

interface IconKnownProps extends BaseProps, SpaceProps, ColorProps {
  size?: string;
}

interface IconProps
  extends IconKnownProps,
    Omit<React.HTMLProps<HTMLDivElement>, keyof IconKnownProps> {}

const I = styled.i<IconProps>(
  {
    width: '1em',
    textAlign: 'center',
  },
  space,
  color,
  (props) => `font-size: ${props.size || 'inherit'}`
);

const Icon = ({ name, ...rest }: IconProps & { name: string }) => {
  return <I className={`fa fa-${name}`} aria-hidden="true" {...rest} />;
};

const SpoqaGlyphicon = styled.i<IconProps>(
  {
    display: 'inline-block',
    fontWeight: 'bold',
    fontStyle: 'normal',
  },
  space,
  color,
  (props) => `font-size: ${props.size || 'inherit'}`
);

Icon.Left = (props: IconProps) =>
  document.body.classList.contains('spoqa-han-sans-loaded') ? (
    <SpoqaGlyphicon {...props}>&#xE000;</SpoqaGlyphicon>
  ) : (
    <Icon name="chevron-left" {...props} />
  );

Icon.Right = (props: IconProps) =>
  document.body.classList.contains('spoqa-han-sans-loaded') ? (
    <SpoqaGlyphicon {...props}>&#xE001;</SpoqaGlyphicon>
  ) : (
    <Icon name="chevron-right" {...props} />
  );

Icon.Plus = (props: IconProps) => <Icon name="plus" {...props} />;

Icon.Close = (props: IconProps) =>
  document.body.classList.contains('spoqa-han-sans-loaded') ? (
    <SpoqaGlyphicon {...props}>&#xE002;</SpoqaGlyphicon>
  ) : (
    <Icon name="times" {...props} />
  );

Icon.Bang = (props: IconProps) => <Icon name="exclamation-circle" {...props} />;

Icon.Check = (props: IconProps) => <Icon name="check" {...props} />;

Icon.Clock = (props: IconProps) => <Icon name="clock-o" {...props} />;

export default Icon;
