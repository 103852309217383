import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CustomerTransactions,
  QueryFilterStoreCategory,
} from 'models/QueryFilters';

export interface MapExposureCriteriaState {
  currentPopupKey: string | null;
  customerTransactions?: CustomerTransactions;
  customerTransactionsRange: {
    min: number;
    max: number;
  };
  storeCategory?: QueryFilterStoreCategory;
}

const initialState: MapExposureCriteriaState = {
  // TODO: 팝업은 스테이트에 과연 있어야 하는가?
  currentPopupKey: null,
  customerTransactionsRange: {
    min: 0,
    max: 1000000,
  },
};

const mapExposureCriteriaSlice = createSlice({
  name: 'mapExposureCriteria',
  initialState,
  reducers: {
    setPopup(state, action: PayloadAction<string>) {
      state.currentPopupKey = action.payload;
    },
    togglePopup(state, action: PayloadAction<string>) {
      state.currentPopupKey =
        state.currentPopupKey !== null ? null : action.payload;
    },
    clearPopup(state) {
      state.currentPopupKey = null;
    },
    setCustomerTransactions(
      state,
      action: PayloadAction<CustomerTransactions | undefined>
    ) {
      state.customerTransactions = action.payload;
    },
    setStoreCategory(
      state,
      action: PayloadAction<QueryFilterStoreCategory | undefined>
    ) {
      state.storeCategory = action.payload;
    },
  },
});

export const {
  setPopup,
  togglePopup,
  clearPopup,
  setCustomerTransactions,
  setStoreCategory,
} = mapExposureCriteriaSlice.actions;

export default mapExposureCriteriaSlice.reducer;
