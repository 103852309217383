import styled from 'styled-components';

import colors from 'constants/colors';

import SidebarBlock from './SidebarBlock';

interface SelectableProps {
  selected: boolean;
}

const SidebarListItem = styled(SidebarBlock)<SelectableProps>`
  background-color: ${(props) =>
    props.selected ? colors.primary : colors.white};

  .label {
    margin: 6px 0 0;
    color: ${(props) => (props.selected ? colors.white : colors.inactive)};
    font-size: 11px;
    line-height: 11px;
    font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  }

  svg > path {
    ${(props) => props.selected && `fill: ${colors.white}`}
  }
`;

export default SidebarListItem;
