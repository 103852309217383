import React from 'react';
import styled from 'styled-components';

import {
  StoreCategoryCombination,
  StoreCategory,
} from 'models/StoreCategories';
import SelectedIcon from 'assets/icons/Selected.svg';

const StyledStoreCategories = styled.div`
  display: flex;
  line-height: 0;

  > .category-section {
    flex: 1;
    select {
      width: 100%;
      height: 128px;
      overflow-y: auto;
      background-color: #f4f4f4;
      border-radius: 0;

      option {
        box-sizing: border-box;
        padding: 4px 0 4px 28px;
        font-size: 12px;
        height: 28px;
      }

      option:checked {
        font-weight: bold;
        background: url(${SelectedIcon}) no-repeat;
        background-position: 10px center;
      }
    }
  }

  > .category-1 {
    select {
      border: 1px solid #ececef;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  > .category-2 {
    select {
      border: 1px solid #ececef;
      border-left: none;
      border-right: none;
    }
  }

  > .category-3 {
    select {
      border: 1px solid #ececef;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;

interface StoreCategoryPickerProps {
  largeCategories: StoreCategory[];
  mediumCategories?: StoreCategory[];
  smallCategories?: StoreCategory[];
  storeCategoryCombination: StoreCategoryCombination;
  onChange: (value: StoreCategoryCombination) => void;
}
type Event = React.ChangeEvent<HTMLSelectElement>;

const toOptions = (items: string[]) => {
  return items.map((item: string, i) =>
    item === '' ? (
      <option key={i} value={item}>
        전체
      </option>
    ) : (
      <option key={i} value={item} title={item}>
        {item}
      </option>
    )
  );
};

const StoreCategoryPicker = ({
  largeCategories,
  mediumCategories,
  smallCategories,
  storeCategoryCombination,
  onChange,
}: StoreCategoryPickerProps) => {
  const handleOnChangeLargeCat = (event: Event) =>
    onChange({
      large: event.target.value === '' ? undefined : event.target.value,
      medium: undefined,
      small: undefined,
    });

  const handleOnChangeMediumCat = (event: Event) =>
    onChange({
      large: storeCategoryCombination.large,
      medium: event.target.value === '' ? undefined : event.target.value,
      small: undefined,
    });

  const handleOnChangeSmallCat = (event: Event) =>
    onChange({
      large: storeCategoryCombination.large,
      medium: storeCategoryCombination.medium,
      small: event.target.value === '' ? undefined : event.target.value,
    });

  const { large, medium, small } = storeCategoryCombination;

  return (
    <StyledStoreCategories>
      <div className="category-section category-1">
        <select size={2} onChange={handleOnChangeLargeCat} value={large ?? ''}>
          {toOptions(largeCategories)}
        </select>
      </div>
      <div className="category-section category-2">
        <select
          size={2}
          onChange={handleOnChangeMediumCat}
          value={medium ?? ''}
        >
          {large && mediumCategories && toOptions(mediumCategories)}
        </select>
      </div>
      <div className="category-section category-3">
        <select size={2} onChange={handleOnChangeSmallCat} value={small ?? ''}>
          {medium && smallCategories && toOptions(smallCategories)}
        </select>
      </div>
    </StyledStoreCategories>
  );
};

export default StoreCategoryPicker;
