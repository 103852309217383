import styled from 'styled-components';

import colors from 'constants/colors';

export const StyledVisitUserView = styled.div`
  .bar-chart {
    margin-top: 10px;
  }

  .trend-chart {
    margin-top: 20px;
  }
`;

interface IProps {
  mt?: string;
  mb?: string;
}

export const AnalasysText = styled.div`
  margin: 0;
  margin-top: ${(props: IProps) => props.mt || '20px'};
  margin-bottom: ${(props: IProps) => props.mb || '10px'};
  font-size: 13px;
  word-break: break-all;

  b {
    font-weight: bold;
  }

  strong {
    font-weight: bold;
    color: ${colors.red};
  }
`;
