import { useEffect, useRef } from 'react';
import { RootState } from 'app/rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import { useKakaoMap } from 'hooks/useKakaoMap';
import MarkerIcon from 'assets/icons/MapMarker.svg';

const PoiView = () => {
  const dispatch = useDispatch();
  const currentPoi = useSelector(
    (state: RootState) => state.mapView.currentPoi
  );
  const { kakao, map } = useKakaoMap();
  const poiMarker = useRef<any>();

  useEffect(() => {
    if (poiMarker.current !== undefined) {
      poiMarker.current.setMap(null);
    }

    if (!currentPoi) {
      return;
    }

    const markerImage = new kakao.maps.MarkerImage(
      MarkerIcon,
      new kakao.maps.Size(52, 66),
      {
        offset: new kakao.maps.Point(26, 63),
        alt: 'POI Marker',
      }
    );

    const position = new kakao.maps.LatLng(currentPoi.y!, currentPoi.x!);
    map.setCenter(position);
    const newPoiMarker = new kakao.maps.Marker({
      map,
      position,
      image: markerImage,
    });

    newPoiMarker.setMap(map);
    poiMarker.current = newPoiMarker;
  }, [dispatch, currentPoi, kakao, map]);

  return null;
};

export default PoiView;
