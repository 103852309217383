import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import { Box } from 'components/common';
import Dropdown from 'components/Dropdown';
import Selection from 'components/Selection';
import { endOfMonth, format } from 'date-fns';
import { ko } from 'date-fns/locale';

const StyledPeriodPicker = styled.div`
  width: 324px;
  h2 {
    font-size: 13px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;

interface DeliveryStatControlProps {
  period: Date;
  periodOptions: any[];
  until: Date;
  onPeriodChange: (startOfMonth: Date) => void;
  deliveryApp: string;
  deliveryAppOptions: any[];
  onDeliveryAppChange: (deliveryApp: string) => void;
}

const DeliveryStatControl = ({
  period,
  periodOptions,
  until,
  onPeriodChange,
  deliveryApp,
  deliveryAppOptions,
  onDeliveryAppChange,
}: DeliveryStatControlProps) => {
  const [openPeriod, setOpenPeroid] = useState<boolean>(false);
  const refPeriodOptions = useRef(periodOptions);

  const [openDeliveryApp, setOpenDeliveryApp] = useState<boolean>(false);
  const refDeliveryAppOptions = useRef(deliveryAppOptions);

  const formatString = 'yyyy,MM,dd(E)';
  const start = format(period, formatString, { locale: ko });
  const endDate = endOfMonth(period) > until ? until : endOfMonth(period);
  const end = format(endDate, formatString, { locale: ko });

  return (
    <>
      <Box p="20px">
        <Dropdown
          title="분석기간"
          contents={`${start} - ${end}`}
          open={openPeriod}
          onClick={() => setOpenPeroid(!openPeriod)}
          onClose={() => setOpenPeroid(!openPeriod)}
        >
          <StyledPeriodPicker>
            <h2>월 선택</h2>
            <Selection
              multiple={false}
              kinds={refPeriodOptions.current}
              values={[period]}
              setValues={([value]) => onPeriodChange(value)}
            />
          </StyledPeriodPicker>
        </Dropdown>
        <Dropdown
          title="배달앱"
          contents={deliveryApp}
          open={openDeliveryApp}
          onClick={() => setOpenDeliveryApp(!openDeliveryApp)}
          onClose={() => setOpenDeliveryApp(!openDeliveryApp)}
        >
          <StyledPeriodPicker>
            <h2>배달앱 선택</h2>
            <Selection
              multiple={false}
              kinds={refDeliveryAppOptions.current}
              values={[deliveryApp]}
              setValues={([value]) => onDeliveryAppChange(value)}
            />
          </StyledPeriodPicker>
        </Dropdown>
      </Box>
    </>
  );
};

export default DeliveryStatControl;
