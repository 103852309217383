import React from 'react';
import styled from 'styled-components';
import colors from 'constants/colors';
import { ReactComponent as DroppedPin } from 'assets/icons/DroppedPin.svg';
import { PoiEntry } from 'models/kakao-maps';

const StyledPoiAutocompleteOption = styled.div`
  display: flex;
  align-items: center;

  > .contents {
    margin-left: 10px;
    width: auto;
    color: ${colors.gray};

    > .place-name {
      font-size: 14px;
      .emphasis {
        color: ${colors.primaryLight};
        font-weight: bold;
      }
    }

    > .place-address {
      font-size: 13px;
    }
  }
`;

const PlaceSearchAutocompleteOption = ({
  option,
  keyword,
}: {
  option: PoiEntry;
  keyword: string;
}) => {
  let placeName;
  const index = option.place_name.indexOf(keyword);
  if (index >= 0) {
    placeName = (
      <React.Fragment>
        {option.place_name.substring(0, index)}
        <span className="emphasis">
          {option.place_name.substring(index, index + keyword.length)}
        </span>
        {option.place_name.substring(index + keyword.length)}
      </React.Fragment>
    );
  } else {
    placeName = <React.Fragment>{option.place_name}</React.Fragment>;
  }

  return (
    <StyledPoiAutocompleteOption>
      <DroppedPin />
      <div className="contents">
        <span className="place-name">{placeName}</span>
        <br />
        <span className="place-address">{option.address_name}</span>
      </div>
    </StyledPoiAutocompleteOption>
  );
};

export default PlaceSearchAutocompleteOption;
