import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';

import SvgIcon from 'components/common/SvgIcon';
import Position from 'components/common/Position';
import paths from 'paths';
import colors from 'constants/colors';

import SideNav from './SideNav';
import SidebarBlock from './SidebarBlock';
import SidebarList from './SidebarList';
import SidebarListItem from './SidebarListItem';
import UserProfile from './UserProfile';
import FranchiseSettings from './FranchiseSettings';

import { useAccount } from 'contexts/AccountContext';

const Header = styled(SidebarBlock)`
  height: 64px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.primary};
`;

interface NavigationLinkProps {
  to: string;
  icon: React.ReactNode;
  label: string;
}

const NavigationLink = (props: NavigationLinkProps) => {
  const location = useLocation();
  const isActive = location.pathname === props.to;
  return (
    <SidebarListItem selected={isActive}>
      <Link to={props.to}>
        {props.icon}
        <p className="label">{props.label}</p>
      </Link>
    </SidebarListItem>
  );
};

const Sidebar = () => {
  const account = useAccount();

  return (
    <SideNav>
      <Header>KCD</Header>
      <SidebarList>
        <li>
          <NavigationLink
            to={paths.map()}
            icon={<SvgIcon name="Map" size="24px" />}
            label="지도분석"
          />
        </li>
        {/* <li>
          <NavigationLink
            to={paths.store.index()}
            icon={<SvgIcon name="Store" size="24px" />}
            label="점포분석"
          />
        </li> */}
        {/* <li>
          <NavigationLink
            to={paths.delivery.index()}
            icon={<SvgIcon name="Delivery" size="36px" />}
            label="배달분석"
          />
        </li> */}
        {account && (
          <li>
            <NavigationLink
              to={paths.weather.index()}
              icon={<SvgIcon name="Weather" size="24px" />}
              label="날씨분석"
            />
          </li>
        )}
      </SidebarList>
      <Position
        position="absolute"
        bottom={0}
        width="100%"
        py="8px"
        sx={{ borderTop: `1px solid ${colors.border}` }}
      >
        <UserProfile />
        {account && <FranchiseSettings />}
      </Position>
    </SideNav>
  );
};

export default Sidebar;
