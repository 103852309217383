import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import TabBar from 'components/TabBar';

import Section from '../../components/Section';
import HintContents from '../../components/HintContents';
import HintContentsItem from '../../components/HintContentsItem';
import { StyledVisitUserView } from '../storeAnalysis/style';
import VisitUserDataView, {
  VisitUserData,
} from '../storeAnalysis/VisitUserDataView';

import {
  storeAnalysisVisitUserSalesAmountStatus,
  storeAnalysisVisitUserSalesAmountTrend,
  storeAnalysisVisitUserUserCountStatus,
  storeAnalysisVisitUserUserCountTrend,
  storeAnalysisVisitUserUnitPriceStatus,
  storeAnalysisVisitUserUnitPriceTrend,
} from 'api/plover';

import { formatSimpleCurrency, formatPopulationCount } from 'utils/formatting';

// TODO: useBusinessId hook
interface VisitUserViewProps {
  businessId: number;
}

const CustomerVisitAnalysisView = (props: VisitUserViewProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState(0);
  const [
    visitUserSalesAmountData,
    setVisitUserSalesAmountData,
  ] = useState<VisitUserData | null>(null);
  const [
    visitUserUserCountData,
    setVisitUserUserCountData,
  ] = useState<VisitUserData | null>(null);
  const [
    visitUserUnitPriceData,
    setVisitUserUnitPriceData,
  ] = useState<VisitUserData | null>(null);

  useEffect(() => {
    const doFetchVisitUserSalesAmount = async () => {
      const [status, trend] = await Promise.all([
        storeAnalysisVisitUserSalesAmountStatus(props.businessId),
        storeAnalysisVisitUserSalesAmountTrend(props.businessId),
      ]);
      setVisitUserSalesAmountData({ status, trend });
    };
    const doFetchVisitUserCount = async () => {
      const [status, trend] = await Promise.all([
        storeAnalysisVisitUserUserCountStatus(props.businessId),
        storeAnalysisVisitUserUserCountTrend(props.businessId),
      ]);
      setVisitUserUserCountData({ status, trend });
    };
    const doFetchVisitUserUnitPrice = async () => {
      const [status, trend] = await Promise.all([
        storeAnalysisVisitUserUnitPriceStatus(props.businessId),
        storeAnalysisVisitUserUnitPriceTrend(props.businessId),
      ]);
      setVisitUserUnitPriceData({ status, trend });
    };

    const fetchAll = async () => {
      try {
        await Promise.all([
          doFetchVisitUserSalesAmount(),
          doFetchVisitUserCount(),
          doFetchVisitUserUnitPrice(),
        ]);
      } catch (e) {
        enqueueSnackbar(
          '방문횟수 분석에 오류가 발생했습니다. 나중에 다시 시도해 주세요.',
          { variant: 'error' }
        );
      }
    };

    fetchAll();
  }, [enqueueSnackbar, props.businessId]);

  const salesAmountView = (
    <VisitUserDataView
      data={visitUserSalesAmountData}
      subject="매출비중이"
      yFormatter={formatSimpleCurrency}
      eventPrefix="Store_VisitAnalysis_SalesAmount"
    />
  );

  const userCountView = (
    <VisitUserDataView
      data={visitUserUserCountData}
      subject="결제고객수가"
      yFormatter={formatPopulationCount}
      eventPrefix="Store_VisitAnalysis_UserCount"
    />
  );

  const unitPriceView = (
    <VisitUserDataView
      data={visitUserUnitPriceData}
      subject="객단가가"
      yFormatter={formatSimpleCurrency}
      eventPrefix="Store_VisitAnalysis_UnitPrice"
    />
  );

  const components = [salesAmountView, userCountView, unitPriceView];

  return (
    <StyledVisitUserView>
      <Section
        title="방문횟수 분석"
        hint={{
          title: '방문 기준 정의',
          contents: (
            <HintContents p="20px" width="333px">
              <HintContentsItem
                title="신규고객"
                description="분석 기간내 방문하고, 최근 1년 이내 구매내역이 없는 고객"
              />
              <HintContentsItem
                title="2회방문"
                description="최근 1년 이내 총구매 2회인 고객중 분석 기간내 방문한 고객"
              />
              <HintContentsItem
                title="3-9회방문"
                description="최근 1년 이내 총구매 3회에서 9회인 고객중 분석 기간내 방문한 고객"
              />
              <HintContentsItem
                title="10회이상방문"
                description="최근 1년 이내 총구매 9회 이상인 고객중 분석 기간내 방문한 고객"
              />
            </HintContents>
          ),
        }}
      >
        <TabBar
          value={value}
          titles={['매출', '결제고객수', '객단가']}
          onChange={setValue}
        />
        {components[value]}
      </Section>
    </StyledVisitUserView>
  );
};

export default CustomerVisitAnalysisView;
