import axios from 'axios';
import type { Moment } from 'moment';

import { getToken } from 'utils/jwtToken';

// TODO: read from env
const client = axios.create({
  baseURL: 'https://weather-staging-api.cashnote.kr/',
  headers: {
    Authorization: `Bearer ${getToken()?.token}`,
  },
});

export async function getStat(
  publicId: string,
  dateType: string,
  params: {
    h3_resolution: number;
    start_date: string;
    end_date: string;
  }
): Promise<any> {
  const url = `shops/${publicId}/delivery/stat/${dateType}`;
  const { data } = await client.get(url, { params });

  return data;
}

// @ts-ignore
export async function getTrend(
  publicId: string,
  dataType: string
): Promise<any> {
  const url = `shops/${publicId}/delivery/trend/${dataType}`;
  const { data } = await client.get(url);

  return data;
}

export async function getFutureWeathers(): Promise<
  Array<{ date: string; weather: 'rainy' | 'sunny' }>
> {
  const { data } = await client.get('weather/next');

  return data.response;
}

export async function getPastSalesWithWeather(params: {
  businessId: string;
  startDate: Moment;
  endDate: Moment;
}): Promise<
  Array<{
    date: string;
    weather: 'rainy' | 'sunny';
    amount: number;
    count: number;
  }>
> {
  const { data } = await client.get(`weather/past-sales/${params.businessId}`, {
    params: {
      'start-date': params.startDate.format('YYYY-MM-DD'),
      'end-date': params.endDate.format('YYYY-MM-DD'),
    },
  });

  return data.response;
}

export async function getSalesProjectionByWeather(params: {
  businessId: string;
}): Promise<
  Array<{
    date: string;
    weather: 'rainy' | 'sunny';
    amount: number;
    count: number;
  }>
> {
  const { data } = await client.get(
    `weather/sales-projection/${params.businessId}`
  );

  return data.response;
}

export async function getWeatherAvailableBusinesses(params: {
  businessIds: string[];
}): Promise<string[] | null> {
  if (params.businessIds.length === 0) {
    return null;
  }

  const { data } = await client.get(`weather/existent-business`, {
    params: {
      'public-ids': params.businessIds.join(','),
    },
  });

  return data.response.map((b: any) => b.public_id.toString());
}
