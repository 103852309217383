import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useSnackbar } from 'notistack';

import { RootState } from 'app/rootReducer';
import KakaoMapView from './KakaoMapView';
import MapControlView from './MapControlView';
import OverlayView from './OverlayView';
import PolygonView from './PolygonView';
import PoiView from './PoiView';
import { fetchMapData, Overlay } from 'utils/map';
import useDebounce from 'hooks/useDebounce';
import usePrevious from 'hooks/usePrevious';

/**
 * 맵 업데이트에 필요한 모든 변경사항을 받아서 데이터 API 에 오버레이와 폴리곤 업데이트를
 * 위한 fetch 를 수행하여 오버레이뷰와 폴리곤뷰를 업데이트 한다.
 *
 * 알아두기:
 *
 * a. 맵에서 줌 인/아웃을 하면 level 이 변하고 슬라이스에 저장되는데 이 이벤트를 바탕으로
 *    맵의 북동쪽 모서리(boundsNe) 및 남서쪽 모서리(boundsSw)가 변하게 된다. 따라서
 *    이 두 이벤트를 각각 처리하기보다 한 번에 처리하기 위해서 맵뷰에서는 줌 레벨이 다른
 *    경우(줌인/줌아웃)는 변화된 값을 처리하지 않는다. 이 변화는 KakaoMapView.tsx 에서
 *    처리되고 있다.
 */
const MapView = () => {
  const { enqueueSnackbar } = useSnackbar();
  const payload = useSelector(
    (state: RootState) => ({
      currentMapViewInfo: state.mapView.currentMapViewInfo,
      aggregationCriteria: state.aggregationCriteria.aggregationCriteria,
      intervalRange: state.aggregationCriteria.intervalRange,
      revenueRange: state.aggregationCriteria.revenueRange,
      storeCategoryCombination:
        state.aggregationCriteria.storeCategoryCombination,
      customerTransactions: state.mapExposureCriteria.customerTransactions,
      filterStoreCategory: state.mapExposureCriteria.storeCategory,
      showOverlay: state.mapView.showOverlay,
      showPolygons: state.mapView.showPolygons,
      showPoiMarker: state.mapView.showPoiMarker,
    }),
    shallowEqual
  );

  const {
    currentMapViewInfo,
    aggregationCriteria,
    intervalRange,
    revenueRange,
    storeCategoryCombination,
    customerTransactions,
    filterStoreCategory,
  } = useDebounce(payload, 600);
  const [polygonPaths, setPolygonPaths] = useState<[number, number][][]>([]);
  const [overlaysByGeoLocation, setOverlaysByGeoLocation] = useState<
    Record<string, Overlay>
  >({});

  const prevLevel = usePrevious(currentMapViewInfo.level);

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await fetchMapData({
          currentMapViewInfo,
          aggregationCriteria,
          intervalRange,
          revenueRange,
          storeCategoryCombination,
          customerTransactions,
          filterStoreCategory,
        });
        setPolygonPaths(data.polygonPaths);
        setOverlaysByGeoLocation(data.overlaysByGeoLocation);
      } catch (e) {
        enqueueSnackbar(
          '지도분석에 오류가 발생했습니다. 나중에 다시 시도해 주세요.',
          { variant: 'error' }
        );
      }
    };

    if (prevLevel === undefined || currentMapViewInfo.level === prevLevel) {
      fetch();
    }
  }, [
    currentMapViewInfo,
    aggregationCriteria,
    intervalRange,
    revenueRange,
    storeCategoryCombination,
    customerTransactions,
    filterStoreCategory,
    prevLevel,
    enqueueSnackbar,
  ]);

  return (
    <>
      <KakaoMapView />
      <MapControlView />
      {payload.showOverlay && (
        <OverlayView overlaysByGeoLocation={overlaysByGeoLocation} />
      )}
      {payload.showPolygons && (
        <PolygonView
          polygonPaths={polygonPaths}
          currentMapViewInfo={currentMapViewInfo}
        />
      )}
      {payload.showPoiMarker && <PoiView />}
    </>
  );
};

export default MapView;
