import React from 'react';
import { Mark } from '@material-ui/core/Slider';

import DoubleEndedSlider from '../../components/DoubleEndedSlider';
import QuantizedRange, { QuantizedType } from '../../models/QuantizedRange';

interface QuantizedDoubleEndedSliderProps<T extends string> {
  type: QuantizedType<T>;
  value: QuantizedRange<T>;
  onChanged: (value: QuantizedRange<T>) => void;
  onCommitted: (value: QuantizedRange<T>) => void;
}

const QuantizedDoubleEndedSlider = <T extends string>(
  props: QuantizedDoubleEndedSliderProps<T>
) => {
  const marks: Mark[] = props.type.values.map((_, i) => ({
    value: i,
    label: props.type.labels[i],
  }));

  const onChanged = (_: React.ChangeEvent<{}>, value: number | number[]) => {
    const [to, from] = value as number[];
    props.onChanged({
      from: props.type.values[from],
      to: props.type.values[to],
    });
  };

  const onCommitted = (_: React.ChangeEvent<{}>, value: number | number[]) => {
    const [to, from] = value as number[];
    props.onCommitted({
      from: props.type.values[from],
      to: props.type.values[to],
    });
  };

  return (
    <DoubleEndedSlider
      step={1}
      min={0}
      max={props.type.values.length - 1}
      value={[
        props.type.values.indexOf(props.value.from),
        props.type.values.indexOf(props.value.to),
      ]}
      marks={marks}
      onChange={onChanged}
      onChangeCommitted={onCommitted}
    />
  );
};

export default QuantizedDoubleEndedSlider;
