import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { RetryLink } from '@apollo/link-retry';

import possibleTypes from './possibleTypes.json';

import { getToken } from 'utils/jwtToken';

const retryLink = new RetryLink({
  delay: {
    initial: 600,
  },
  attempts: {
    max: 3,
    retryIf: (error, operation) => {
      const operationIsLogin = !!operation.operationName.match(/login/);
      const definition = operation.query.definitions[0];
      const operationIsQuery =
        'operation' in definition && definition.operation === 'query';

      return !!error && (operationIsLogin || operationIsQuery);
    },
  },
});

const authLink = setContext((_, { headers }) => {
  const token = getToken()?.token;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_CASHNOTE_GRAPHQL_API_ENDPOINT,
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache({ possibleTypes }),
  link: ApolloLink.from([retryLink, authLink, httpLink]),
});

export default apolloClient;
