import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';
import moment from 'moment';

import type { Business } from 'graphql/types';

import { useBusinesses } from 'contexts/AccountContext';
import useApi from 'hooks/useApi';

import Box from 'components/common/Box';
import Text from 'components/common/Text';
import Flex from 'components/common/Flex';
import Position from 'components/common/Position';
import Icon from 'components/common/Icon';

import DailyBox from './DailyBox';
import WeeklyGraph from './WeeklyGraph';

import globalLayouts from 'constants/layouts';
import colors from 'constants/colors';
import { layout } from './consts';

import {
  getPastSalesWithWeather,
  getSalesProjectionByWeather,
  getWeatherAvailableBusinesses,
} from 'api/loon';

const Background = ({ children }: { children: React.ReactNode }) => (
  <Position
    position="absolute"
    width={`calc(100% - ${globalLayouts.sidebar.width}px)`}
    height="100%"
    top="0"
    left={`${globalLayouts.sidebar.width}px`}
    bg="white"
    sx={{ zIndex: 2, overflowY: 'auto' }}
  >
    {children}
  </Position>
);

const DateArrow = styled(Position)`
  position: absolute;
  font-size: 28px;
  color: ${colors.gray500};
  cursor: pointer;
  user-select: none;
  :hover {
    color: ${colors.primary};
  }
`;

const today = moment();
const sunnys = new Array(7).fill('sunny');

function Body({ businesses }: { businesses: Business[] }) {
  const [startDate, setStartDate] = useState(moment().startOf('isoWeek'));
  const [weathers, setWeathers] = useState<string[]>(sunnys);
  const [selectedBusiness, selectBusiness] = React.useState(businesses[0]);

  const { data: pastSalesData } = useApi(getPastSalesWithWeather, {
    businessId: selectedBusiness.id,
    startDate,
    endDate: moment().endOf('isoWeek'),
  });

  const { data: futureSalesData } = useApi(getSalesProjectionByWeather, {
    businessId: selectedBusiness.id,
  });

  if (pastSalesData === undefined || futureSalesData === undefined) {
    alert('해당 사업장은 매출 데이터가 존재하지 않습니다.');
    return <Redirect to="/" />;
  }

  if (!pastSalesData || !futureSalesData) {
    return null;
  }

  const days = [0, 1, 2, 3, 4, 5, 6].map((offset, idx) => {
    const date = moment(startDate).add(offset, 'days');
    const dateString = date.format('YYYY-MM-DD');

    if (date.isBefore(today, 'day')) {
      const datum = pastSalesData.find((data) => data.date === dateString);

      return {
        date,
        weather: datum?.weather || 'sunny',
        estimated: false,
        amount: datum?.amount || 0,
        count: datum?.count || 0,
      };
    }

    const datum = futureSalesData.find(
      (data) => data.date === dateString && data.weather === weathers[idx]
    );

    return {
      date,
      weather: weathers[idx],
      estimated: true,
      amount: datum?.amount || 0,
      count: datum?.count || 0,
    };
  });

  const minSalesAmount = Math.min(
    ...futureSalesData.map((data) => data.amount)
  );
  const maxSalesAmount = Math.max(
    ...futureSalesData.map((data) => data.amount)
  );

  const handlePrev = () => {
    setStartDate((v) => moment(v).subtract(7, 'days'));
  };

  const handleNext = () => {
    setStartDate((v) => moment(v).add(7, 'days'));
  };

  const projectionAvailable = startDate.isSameOrBefore(
    moment().startOf('isoWeek')
  );

  return (
    <Background>
      <Box
        width={layout.width}
        height="768px"
        margin="40px auto 20px"
        sx={{
          position: 'relative',
        }}
      >
        <Flex mb={4} alignItems="center" justifyContent="space-between">
          <Select
            value={selectedBusiness.id}
            onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
              selectBusiness(
                // @ts-ignore
                businesses.find((business) => business.id === e.target.value)
              )
            }
          >
            {businesses.map((business: any) => (
              <MenuItem key={business.id} value={business.id}>
                {business.name}
              </MenuItem>
            ))}
          </Select>
          <Text color="primary" textAlign="right">
            ※ 날씨를 클릭해서 예상 매출 변화를 확인해보세요.
            <br />※ 오전 중에 전일 매출 합산이 집계됩니다.
            <br />※ 현재 서울 지역 사업장에만 제공됩니다.
          </Text>
        </Flex>
        <DateArrow left="0">
          <Icon.Left onClick={handlePrev} />
        </DateArrow>
        <DateArrow right="0">
          {projectionAvailable && <Icon.Right onClick={handleNext} />}
        </DateArrow>
        <Flex
          height="100%"
          mx={`-${layout.spacing}px`}
          justifyContent="space-around"
        >
          {days.map(({ date, weather, ...rest }, idx) => (
            <DailyBox
              key={date.format()}
              date={date}
              weather={weather === 'sunny' ? 'Sun' : 'Rain'}
              {...rest}
              selected={today.isSame(date, 'day')}
              onWeatherClick={() => {
                const n = weathers.slice();
                n[idx] = n[idx] === 'sunny' ? 'rainy' : 'sunny';
                setWeathers(n);
              }}
            />
          ))}
        </Flex>
        <Position
          position="absolute"
          height="500px"
          left={`-${layout.spacing}px`}
          right={`-${layout.spacing}px`}
          bottom="0"
        >
          <WeeklyGraph
            width={layout.width + layout.spacing * 2}
            height={500}
            amountRange={[minSalesAmount, maxSalesAmount]}
            data={days.map((day, idx) => ({ x: idx + 1, y: day.amount }))}
          />
        </Position>
      </Box>
    </Background>
  );
}

const WeatherAnalysisPage = () => {
  const businesses = useBusinesses();
  const { data: availableBusinessIds } = useApi(getWeatherAvailableBusinesses, {
    businessIds: businesses?.map((b) => b!.id) || [],
  });

  if (!businesses) {
    return null;
  }

  if (businesses.length === 0) {
    alert('보유한 사업장이 없습니다.');
    return <Redirect to="/" />;
  }

  if (!availableBusinessIds) {
    return null;
  }

  if (availableBusinessIds.length === 0) {
    alert(
      '날씨분석이 가능한 사업장이 없습니다. 날씨분석은 현재 서울 소재 사업장에만 제공됩니다.'
    );
    return <Redirect to="/" />;
  }

  return (
    <Body
      businesses={
        businesses.filter((b) =>
          availableBusinessIds.includes(b!.id)
        ) as Business[]
      }
    />
  );
};

export default WeatherAnalysisPage;
