import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';

import colors from '../constants/colors';

const DoubleEndedSlider = withStyles({
  root: {
    display: 'block',
    width: 'calc(100% - 12px)',
    marginLeft: '6px',
    padding: '4px 0 25px',
    color: colors.primary,
  },
  marked: {
    marginBottom: 0,
  },
  rail: {
    height: '8px',
    borderRadius: '6px',
    color: '#dddddd',
    opacity: 1,
  },
  track: {
    height: '8px',
  },
  thumb: {
    marginTop: '-4px',
    height: '16px',
    width: '16px',
    border: '1.5px solid #005660',
    background: 'white',
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
  },
  mark: {
    display: 'none',
  },
  markLabel: {
    top: '21px',
    color: colors.gray,
    fontSize: '10px',
    lineHeight: '10px',
    fontWeight: 600,
  },
})(Slider);

export default DoubleEndedSlider;
