import React from 'react';
import { Box, Text } from 'rebass/styled-components';

import type { BoxProps } from 'rebass';

interface TipBoxProps extends BoxProps {
  title: string;
  tips: string[];
}

const TipBox = ({ title, tips, ...rest }: TipBoxProps) => (
  <Box p="13px 15px" bg="#F3F6F6" sx={{ borderRadius: '4px' }} {...rest}>
    <Text fontWeight="bold">{title}</Text>
    {tips.map((tip, index) => (
      <Text key={index} as="p" mt="11px" sx={{ wordBreak: 'break-all' }}>
        - {tip}
      </Text>
    ))}
  </Box>
);

export default TipBox;
