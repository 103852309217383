import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import greenfinch from 'greenfinch';

import 'styles/index.css';
import 'styles/SpoqaHanSans-kr.css';

import App from 'app/App';

import browserHistory from 'browserHistory';

try {
  Sentry.init({
    dsn:
      'https://f424f405c878495c8187bff1589a3750@o42691.ingest.sentry.io/5304372',
    whitelistUrls: [
      /^https:\/\/map\.cashnote\.kr\/static\/js/,
      /^https:\/\/develop\.map\.cashnote\.kr\/static\/js/,
    ],
    environment: process.env.REACT_APP_DEPLOY_ENV, // development | staging | production
    release: process.env.REACT_APP_GIT_SHA, // set as the latest git SHA.
    ignoreErrors: [
      'Failed to fetch',
      'The operation couldn’t be completed. Software caused connection abort',
      '작업을 완료할 수 없습니다. 소프트웨어에 의한 연결 중단',
      'The network connection was lost.',
      '네트워크 연결이 유실되었습니다.',
      'WebKit encountered an internal error',
      'The Internet connection appears to be offline.',
      'Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type',
      '취소됨',
    ],
  });
} catch {
  // invalid dsn causes error: do nothing.
}

if (window.FontFace?.prototype.hasOwnProperty('display')) {
  document.body.classList.add('spoqa-han-sans-loaded');
} else {
  import('fontfaceobserver').then(({ default: FontFaceObserver }) => {
    Promise.all(
      [400, 700].map((weight) =>
        new FontFaceObserver('Spoqa Han Sans', { weight }).load(null, 1000 * 15)
      )
    ).then(() => {
      document.body.classList.add('spoqa-han-sans-loaded');
    });
  });
}

const segmentWriteKey = process.env.REACT_APP_SEGMENT_WRITE_KEY;

if (segmentWriteKey) {
  window.analytics.load(segmentWriteKey);
} else {
  window.analytics.track = (
    _eventName: string,
    _properties?: Object,
    // @ts-ignore
    _options?: any,
    callback?: () => void
  ) => {
    callback && callback();
  };
}

const greenfinchWriteKey = process.env.REACT_APP_GREENFINCH_WRITE_KEY;

if (greenfinchWriteKey) {
  greenfinch.init(greenfinchWriteKey, {
    service_name: 'kcd_map_cashnote',
    debug: process.env.REACT_APP_DEPLOY_ENV !== 'production',
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

if (process.env.NODE_ENV === 'production') {
  browserHistory.listen(() => {
    greenfinch.page();
  });
}
