import React, { useState } from 'react';

import Component from 'components/QueryFilters/styles';
import DoubleEndedSlider from 'components/DoubleEndedSlider';
import { formatCurrency } from 'utils/formatting';

const CustomerTransactionsQueryFilterControl = ({
  from,
  to,
  min,
  max,
  onUpdate,
}: any) => {
  const [value, setValue] = useState<[number, number]>([
    from ?? min,
    to ?? max,
  ]);
  const marks = [
    {
      value: min,
      label: formatCurrency(min),
    },
    {
      value: max,
      label: formatCurrency(max),
    },
  ];

  const handleChange = (_event: any, newValues: number[] | number) => {
    setValue(newValues as [number, number]);
  };

  const handleChangeCommitted = () => {
    const [from, to] = value;
    onUpdate({ priceRange: { from, to } });
  };

  return (
    <Component>
      <p className="description">
        <span className="emphasis">
          {formatCurrency(value[0])} ~ {formatCurrency(value[1])}
        </span>
        인 상권만 노출됩니다.
      </p>
      <DoubleEndedSlider
        min={min}
        max={max}
        value={value}
        marks={marks}
        step={1}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
      />
    </Component>
  );
};

export default CustomerTransactionsQueryFilterControl;
