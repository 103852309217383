import React, { useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchFranchiseStores } from 'features/storeAnalysis/storeSlice';
import { useCashnoteLoginGetAccount } from 'hooks/useCashnoteLogin';

import { getToken, setToken } from 'utils/jwtToken';
import bluebird from 'api/bluebird';

import type { Account } from 'types';

interface AccountContext {
  account: Account | null | undefined;
  loginSucceeded: boolean;
  setAccount: (account: Account | null) => void;
}

const AccountContext = React.createContext<AccountContext>({
  account: undefined,
  loginSucceeded: false,
  setAccount: (_) => {},
});

export const AccountProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const token = getToken();
  const authSource = token?.source;
  const hasToken = !!token;

  const dispatch = useDispatch();
  const [account, setAccount] = useState<Account | null>();

  const [loginSucceeded, setLoginSucceeded] = useState(false);
  const cashnoteAccount = useCashnoteLoginGetAccount(
    !(hasToken && !account && authSource === 'CASHNOTE')
  );

  useEffect(() => {
    async function checkCashnoteAccount() {
      if (cashnoteAccount) {
        setAccount({
          source: 'CASHNOTE',
          name: cashnoteAccount.name,
          cashnoteAccount,
        });
      }
    }

    checkCashnoteAccount();
  }, [cashnoteAccount]);

  useEffect(() => {
    async function checkFranchiseAccount() {
      const { data: user } = await bluebird.auth.info();
      setAccount({
        source: 'FRANCHISE',
        name: user.managerName,
        franchiseAccount: user,
      });

      dispatch(fetchFranchiseStores());
    }

    if (!account && authSource === 'FRANCHISE') {
      checkFranchiseAccount();
    }
  }, [account, authSource, dispatch]);

  return (
    <AccountContext.Provider
      value={{
        account: account,
        loginSucceeded,
        setAccount: (account: Account | null) => {
          setLoginSucceeded(!!account);

          if (!account) {
            setToken(null);
          }
          setAccount(account);
        },
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const { account } = useContext(AccountContext);

  return account;
};

export const useSetAccount = () => {
  const { setAccount } = useContext(AccountContext);

  return setAccount;
};

export const useLoginSucceeded = () => {
  const { loginSucceeded } = useContext(AccountContext);

  return loginSucceeded;
};

export function useBusinesses() {
  const account = useAccount();

  return account?.cashnoteAccount?.businesses?.nodes;
}
