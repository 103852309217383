import debounce from 'lodash/debounce';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { RootState } from 'app/rootReducer';
import { useKakaoMap } from 'hooks/useKakaoMap';
import { setCurrentMapViewInfo } from './mapSlice';

import paths from 'paths/paths';
import { toMapViewInfo } from 'utils/map';

import layouts from 'constants/layouts';

const StyledMapComponent = styled.main`
  width: calc(100% - ${layouts.sidebar.width}px);
  height: 100%;
  position: absolute;
  left: ${layouts.sidebar.width}px;
  top: 0;
  z-index: -1;
`;

const KakaoMapView = () => {
  const dispatch = useDispatch();
  const { ref, kakao, map } = useKakaoMap();
  const { level } = useSelector(
    (state: RootState) => state.mapView.currentMapViewInfo
  );

  useEffect(() => {
    if (!ref || !kakao || !map) {
      return;
    }

    function handleBoundsChanged() {
      if (document.location.pathname !== paths.map()) {
        return;
      }

      dispatch(setCurrentMapViewInfo(toMapViewInfo(map)));
    }

    handleBoundsChanged();

    // @ts-ignore
    kakao.maps.event.addListener(
      map,
      'bounds_changed',
      debounce(() => {
        handleBoundsChanged();
      }, 500)
    );
  }, [ref, kakao, map, dispatch]);

  useEffect(() => {
    map?.setLevel(level);
  }, [map, level]);

  return <StyledMapComponent ref={ref}></StyledMapComponent>;
};

export default KakaoMapView;
