import React from 'react';

import { ReactComponent as Map } from 'assets/icons/icon_map.svg';
import { ReactComponent as Store } from 'assets/icons/icon_store.svg';
import { ReactComponent as Building } from 'assets/icons/icon_building.svg';
import { ReactComponent as Delivery } from 'assets/icons/icon_delivery.svg';
import { ReactComponent as Weather } from 'assets/icons/icon_weather.svg';

const svgIcons = {
  Map,
  Store,
  Building,
  Delivery,
  Weather,
} as const;

export type SvgIconKey = keyof typeof svgIcons;

const SvgIcon = ({
  name,
  size,
  width,
  height,
  onClick,
  ...rest
}: {
  name: SvgIconKey;
  size: string;
  width?: string;
  height?: string;
  onClick?: () => void;
}) => {
  const Icon = svgIcons[name];
  return (
    <Icon
      width={width || size}
      height={height || size}
      onClick={onClick}
      {...rest}
    />
  );
};

export default SvgIcon;
