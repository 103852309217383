import storage from 'local-storage-fallback';
import jwtDecode from 'jwt-decode';

import { AuthSourceType } from 'types';

const TOKEN_KEY = 'map-jwt-token';

type TOKEN_STORAGE = {
  source: AuthSourceType;
  token: string;
};

export function getToken(): TOKEN_STORAGE | null {
  const value = storage.getItem(TOKEN_KEY);

  if (!value) {
    return null;
  }

  let tokenObj;
  try {
    tokenObj = JSON.parse(value);
  } catch {
    setToken(null);
    return null;
  }

  const decoded: any = jwtDecode(tokenObj.token);

  if (new Date().getTime() / 1000 > decoded.exp) {
    setToken(null);
    return null;
  }

  return tokenObj;
}

export function setToken(tokenStorgae: TOKEN_STORAGE | null) {
  if (tokenStorgae) {
    storage.setItem(
      TOKEN_KEY,
      JSON.stringify({
        source: tokenStorgae.source,
        token: tokenStorgae.token,
      })
    );
  } else {
    storage.removeItem(TOKEN_KEY);
  }
}
