import React from 'react';
import { Text, TextProps, Box, BoxProps, Flex } from 'rebass/styled-components';

import colors from 'constants/colors';

const Title = (props: TextProps) => (
  <Text
    color="#555555"
    fontSize="14px"
    fontWeight="bold"
    mb="14px"
    {...props}
  ></Text>
);

const SubTitle = (props: TextProps) => (
  <Text
    color={colors.sub}
    fontSize="12px"
    fontWeight="bold"
    mb="8px"
    {...props}
  ></Text>
);

const Description = (props: TextProps) => (
  <Text
    color={colors.sub}
    fontSize="12px"
    lineHeight="18px"
    mb="20px"
    {...props}
  ></Text>
);

const Button = (props: BoxProps) => (
  <Box
    mr="10px"
    p="4px 13px"
    backgroundColor={colors.white}
    color="#555555"
    fontSize="13px"
    css={{
      border: `1px solid ${colors.componentBorder}`,
      borderRadius: '4px',
      lineHeight: '20px',
    }}
    {...props}
  ></Box>
);

const QueryFilterTooltip = () => (
  <>
    <Title>노출기준 정의</Title>
    <Flex mb="16px">
      <Button>업종</Button>
      <Button>객단가</Button>
    </Flex>
    <SubTitle>업종</SubTitle>
    <Description>
      집계기준 선택 업종이 상권내에 차지하는 비중을 설정하여, 업종별 주요 상권만
      볼 수 있도록 노출 설정합니다.
    </Description>
    <SubTitle>객단가</SubTitle>
    <Description mb="0">
      원하는 객단가 기준에 맞는 행정동 마크만 볼 수 있도록 노출설정합니다.
    </Description>
  </>
);

export default QueryFilterTooltip;
