import React from 'react';

import { Box, Flex, Text } from 'components/common';

interface HintContentsItemProps {
  title: string;
  description: string;
  subDescription?: string;
}

const HintContentsItem = ({
  title,
  description,
  subDescription,
}: HintContentsItemProps) => (
  <Flex
    fontSize="12px"
    sx={{
      lineHeight: '18px',
      '& + &': {
        marginTop: '10px',
      },
    }}
  >
    <Text flex="0 0 70px" mr="11px" fontWeight="bold">
      {title}
    </Text>
    <Box>
      <Text>{description}</Text>
      {subDescription && <Text fontSize="11px">{subDescription}</Text>}
    </Box>
  </Flex>
);

export default HintContentsItem;
