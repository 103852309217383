import React from 'react';
import { Link } from 'react-router-dom';
import { Button as BaseButton, Text, Box } from 'rebass/styled-components';

import type { ButtonProps } from 'rebass';
import type { SpaceProps } from 'styled-system';

import Icon from 'components/common/Icon';

const Button = (props: ButtonProps) => {
  return (
    <BaseButton
      fontSize="16px"
      py="16px"
      width="100%"
      sx={{
        display: 'block',
        cursor: 'pointer',
        outline: 'none',
        borderRadius: '4px',
        transition: 'background 0.15s ease',
        '&:disabled': {
          opacity: 0.5,
          cursor: 'not-allowed',
        },
        lineHeight: 1.618,
      }}
      {...props}
    />
  );
};

interface LinkButtonProps extends SpaceProps {
  to: string;
  onClick?: (e: React.MouseEvent) => void;
  chevron?: 'left' | 'right';
  children: React.ReactNode;
}

export const LinkButton = ({
  to,
  onClick,
  chevron,
  children,
  ...rest
}: LinkButtonProps) => {
  const inner = (
    <Text variant="link">
      {chevron === 'left' && <Icon.Left mr={1} />}
      {children}
      {chevron === 'right' && <Icon.Right ml={1} />}
    </Text>
  );

  if (to.startsWith('/')) {
    return (
      <Box display="inline-block" {...rest}>
        <Link to={to} onClick={onClick}>
          {inner}
        </Link>
      </Box>
    );
  }

  return (
    <Box display="inline-block" {...rest}>
      <a href={to} target="_blank" rel="noopener noreferrer" onClick={onClick}>
        {inner}
      </a>
    </Box>
  );
};

export default Button;
