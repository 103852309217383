export default interface QuantizedRange<T extends string> {
  from: T;
  to: T;
}

export function describeRange<T extends string>(
  value: QuantizedRange<T>,
  type: QuantizedType<T>
): string {
  if (value.from === value.to) {
    return type.labels[type.values.indexOf(value.from)];
  } else {
    const from = type.labels[type.values.indexOf(value.from)];
    const to = type.labels[type.values.indexOf(value.to)];
    return `${from}~${to}`;
  }
}

export interface QuantizedType<T extends string> {
  values: T[];
  labels: string[];
}

export type RevenueQuarterValue =
  | 'lower'
  | 'lower_middle'
  | 'upper'
  | 'upper_middle';
export const RevenueQuarterValues: RevenueQuarterValue[] = [
  'upper',
  'upper_middle',
  'lower_middle',
  'lower',
];
export const RevenueQuarterLabels = ['상위', '중상위', '중하위', '하위'];

export type IntervalValue = '1m' | '2m' | '3m' | '6m' | '1y' | '2y' | '3y';
export type IntervalRange = QuantizedRange<IntervalValue>;
