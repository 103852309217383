import { YearMonthWeek } from 'models/Date';

const ONE_HUNDRED_MILLION = 100000000;
const TEN_THOUSAND = 10000;

export function formatNumber(
  value: number,
  fractionDigits: number = 0
): string {
  // NOTE: Only use ko-KR at this moment
  return value.toLocaleString('ko-KR', {
    maximumFractionDigits: fractionDigits,
  });
}

export function formatHumanReadableNumber(
  value: number,
  withPlusSign: boolean = false,
  fractionDigits: number = 1
): string {
  const abs = Math.abs(value);
  const sign = withPlusSign && value > 0 ? '+' : '';

  if (abs >= ONE_HUNDRED_MILLION) {
    return `${sign}${formatNumber(
      value / ONE_HUNDRED_MILLION,
      fractionDigits
    )}억`;
  }
  if (abs >= TEN_THOUSAND) {
    return `${sign}${formatNumber(value / TEN_THOUSAND, fractionDigits)}만`;
  }
  return `${sign}${formatNumber(value, 0)}`;
}

export function formatCurrency(
  value: number,
  humanReadable: boolean = false,
  withPlusSign: boolean = false,
  fractionDigits: number = 0
): string {
  return humanReadable
    ? `${formatHumanReadableNumber(value, withPlusSign, fractionDigits)}원`
    : `${formatNumber(Math.round(value), 0)}원`;
}

export function formatCount(
  value: number,
  humanReadable: boolean = false,
  withPlusSign: boolean = false,
  fractionDigits: number = 0
): string {
  return humanReadable
    ? `${formatHumanReadableNumber(value, withPlusSign, fractionDigits)}건`
    : `${formatNumber(Math.round(value), 0)}건`;
}

export function formatPopulationCount(count: number): string {
  return `${formatNumber(count, 0)}명`;
}

export function formatSimpleCurrency(
  amount: number,
  plusSign: boolean = false
): string {
  const abs = Math.abs(amount);
  let sign = '';
  if (plusSign && amount > 0) {
    sign = '+';
  }
  if (abs >= ONE_HUNDRED_MILLION) {
    return `${sign}${formatNumber(amount / ONE_HUNDRED_MILLION, 1)}억원`;
  } else if (abs >= TEN_THOUSAND) {
    return `${sign}${formatNumber(amount / TEN_THOUSAND, 1)}만원`;
  } else {
    return `${sign}${formatNumber(amount, 0)}원`;
  }
}

export function formatYearMonthWeek(yearMonthWeek: YearMonthWeek): string {
  let formatted = `${yearMonthWeek.year}년 ${yearMonthWeek.month}월`;
  if (yearMonthWeek.week !== null) {
    formatted += ` ${yearMonthWeek.week}주`;
  }
  return formatted;
}
