import React from 'react';
import styled from 'styled-components';

import colors from '../constants/colors';

const OutsideClick = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const StyledPopup = styled.div`
  position: absolute;
  background-color: ${colors.white};
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.2);
  z-index: 5;
  transform: translateY(-10px);

  > .submit {
    float: right;
    color: ${colors.white};
    background: ${colors.primaryLight};
    border-radius: 4px;
    margin-top: 8px;
    border: none;
    padding: 11px 21px;
    font-size: 14px;
    font-weight: 600;

    :disabled {
      background: #ddd;
    }
  }
`;

export interface PopupProps {
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

const Popup = ({ open, onClose, children }: PopupProps) => {
  if (!open) {
    return null;
  }

  return (
    <React.Fragment>
      <OutsideClick onClick={() => onClose()} />
      <StyledPopup>{children}</StyledPopup>
    </React.Fragment>
  );
};

export default Popup;
