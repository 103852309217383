import React from 'react';
import { parse, addWeeks, getYear, getMonth } from 'date-fns';
import * as _ from 'lodash';

import EmptyDataPlaceholder from 'components/EmptyDataPlaceholder';
import TrendChart from 'components/TrendChart';

import { YearMonthWeek } from 'models/Date';
import { AnalasysText } from '../storeAnalysis/style';
import { ConversionDuration } from './conversionTrackingAnalysisSlice';
import { getStartDatesOfEachWeekInMonth } from 'utils/date';
import { format } from 'date-fns/esm';

interface Stats {
  grandTotal: number;
  customerType: '지속방문고객' | '이탈고객';
  customerTypeTotal: number;
  customerTypePortion: number;
  highest: YearMonthWeek;
}

const toStats = (
  conversions: number[],
  bounces: number[],
  salesStartDate: string,
  conversionDuration: ConversionDuration
): Stats => {
  const conversionTotal = _.sum(conversions);
  const bounceTotal = _.sum(bounces);
  const grandTotal = bounceTotal + conversionTotal;

  const customerType =
    conversionTotal > bounceTotal ? '지속방문고객' : '이탈고객';
  const customerTypeTotal =
    conversionTotal > bounceTotal ? conversionTotal : bounceTotal;
  const customerTypePortion = (customerTypeTotal / grandTotal) * 100;

  const highestIndex =
    conversionTotal > bounceTotal
      ? _.indexOf(conversions, _.max(conversions))
      : _.indexOf(bounces, _.max(bounces));

  const offsetWeeks = conversionDuration.startWeek + highestIndex;
  const dd = parse(salesStartDate, 'yyyy-MM-dd', new Date());
  const highestDate = addWeeks(dd, offsetWeeks);

  const year = getYear(highestDate);
  const month = getMonth(highestDate) + 1;
  const week =
    _.indexOf(
      getStartDatesOfEachWeekInMonth(year, month).map((d) =>
        format(d, 'yyyy-MM-dd')
      ),
      format(highestDate, 'yyyy-MM-dd')
    ) + 1;

  return {
    grandTotal,
    customerType,
    customerTypeTotal,
    customerTypePortion,
    highest: {
      year,
      month,
      week,
    },
  };
};

const TrendTabView = ({
  title,
  data,
  salesStartDate,
  conversionDuration,
}: {
  title: string;
  data: any;
  salesStartDate: string;
  conversionDuration: ConversionDuration;
}) => {
  if (data === null) {
    return <EmptyDataPlaceholder kind="out_of_range" />;
  }

  const [conversions, bounces] = data.values;
  const {
    grandTotal,
    customerType,
    customerTypeTotal,
    customerTypePortion,
    highest: { month, week },
  } = toStats(conversions, bounces, salesStartDate, conversionDuration);

  return (
    <>
      <AnalasysText mt="20px" mb="20px">
        {title} {grandTotal}명 중에 전환기간 동안 {customerType}은{' '}
        <strong>
          {customerTypeTotal}명({customerTypePortion.toFixed()}%)
        </strong>
        으로
        {month}월 {week}주에 가장 높습니다.
      </AnalasysText>
      <TrendChart
        width={350}
        height={250}
        data={data}
        legends={[
          { label: '지속방문고객', color: '#ff7777', borderColor: '#d46868' },
          { label: '이탈고객', color: '#3965ff', borderColor: '#68b4d4' },
        ]}
        yFormatter={(v) => `${v}명`}
      />
    </>
  );
};

export default TrendTabView;
