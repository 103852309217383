const BASE_URL = {
  CASHNOTE: 'https://app.cashnote.kr',
  FRANCHISE_NOTE: 'https://offline.cashnote.kr',
};

export default {
  external: {
    cashnote: {
      account: {
        retrieve() {
          return `${BASE_URL.CASHNOTE}/account/retrieve`;
        },
      },
      password: {
        reset() {
          return `${BASE_URL.CASHNOTE}/password/reset`;
        },
      },
      payment: {
        plans: {
          index(businessId: string) {
            return `${BASE_URL.CASHNOTE}/m/businesses/${businessId}/payment/plans?upgrade=true`;
          },
        },
      },
      integrations: {
        marketAnalysis: {
          index(businessId: string) {
            return `${BASE_URL.CASHNOTE}/m/businesses/${businessId}/integrations/market-analysis`;
          },
        },
      },
    },
    franchiseNote: {
      signup() {
        return `${BASE_URL.FRANCHISE_NOTE}/kcdp`;
      },
      stores() {
        return `${BASE_URL.FRANCHISE_NOTE}/franchise`;
      },
    },
  },
  map() {
    return '/';
  },
  store: {
    index() {
      return '/store';
    },
  },
  delivery: {
    index() {
      return '/delivery';
    },
  },
  weather: {
    index() {
      return '/weather';
    },
  },
  auth: {
    index() {
      return '/auth';
    },
    franchise() {
      return '/auth/franchise';
    },
    cashnote() {
      return '/auth/cashnote';
    },
  },
};
