import _ from 'lodash';
import React, { useState, ChangeEvent, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { setPoi } from 'features/mapView/mapSlice';
import { PoiEntry } from 'models/kakao-maps';
import { useKakaoMap } from 'hooks/useKakaoMap';
import useAutoCompleteFix from 'hooks/useAutoCompleteFix';
import PlaceSearchAutocompleteOption from './PlaceSearchAutocompleteOption';

import { trackButtonClick } from 'utils/trackEvent';

const BorderlessTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      '& fieldset': {
        border: 'none',
      },
    },
    '& .MuiAutocomplete-endAdornment': {
      '& .MuiAutocomplete-popupIndicatorOpen': {
        transform: 'none',
      },
    },
  },
})(TextField);

const PlaceSearchForm = () => {
  const { kakao } = useKakaoMap();
  const dispatch = useDispatch();
  const searchField = useRef<any>();

  const [query, setQuery] = useState<string>('');
  const [places, setPlaces] = useState<PoiEntry[]>([]);

  useAutoCompleteFix(searchField);

  useEffect(() => {
    const fetchPlaces = async () => {
      const placesApi = new kakao.maps.services.Places();
      const places: PoiEntry[] = await new Promise((resolve) => {
        placesApi.keywordSearch(query, (result: PoiEntry[]) => {
          resolve(result);
        });
      });

      setPlaces(places);
    };

    if (!kakao || query === '') {
      return;
    }

    fetchPlaces();
  }, [kakao, query, setPlaces]);

  const delayedQuery = _.debounce((value) => setQuery(value), 500);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.trim() === '') {
      dispatch(setPoi(null));
    } else {
      delayedQuery(event.target.value);
    }
  };

  const onSelect = (_: PoiEntry, value: PoiEntry): boolean => {
    setTimeout(() => dispatch(setPoi(value)), 0);
    return true;
  };

  return (
    <Autocomplete
      autoHighlight
      noOptionsText="연관 검색어가 없습니다."
      options={places}
      getOptionLabel={(option) => option.place_name}
      style={{
        width: '100%',
      }}
      onChange={(_: any, newValue: any | null) => {
        trackButtonClick('Map_PlaceSearch');
        dispatch(setPoi(newValue));
      }}
      getOptionSelected={onSelect}
      popupIcon={<SearchIcon />}
      renderInput={(params) => (
        <BorderlessTextField
          {...params}
          ref={searchField}
          placeholder="주소 또는 장소명으로 검색"
          onChange={onChange}
          variant="outlined"
        />
      )}
      renderOption={(option) => (
        <PlaceSearchAutocompleteOption option={option} keyword={query} />
      )}
    />
  );
};

export default PlaceSearchForm;
