import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components/macro';

import * as Form from 'components/common/Form';
import Button from 'components/common/Button';

import { useFranchiseLoginWithPassword } from 'hooks/useFranchiseLogin';

import paths from 'paths/paths';
import t from 'i18n';

const LinkText = styled(Text)`
  color: initial;
`;

const validates = {
  login: {
    required: t('errors.messages.required'),
  },
  password: {
    required: t('errors.messages.required'),
    minLength: { value: 4, message: t('errors.messages.invalid') },
  },
};

interface FormInput {
  login: string;
  password: string;
}

const Franchise = () => {
  const loginWithPassword = useFranchiseLoginWithPassword();
  const { register, handleSubmit, errors, formState } = useForm<FormInput>({
    mode: 'onBlur',
    defaultValues: {
      login: '',
      password: '',
    },
  });
  const { isSubmitting } = formState;

  const onSubmit = async (values: FormInput) => {
    await loginWithPassword(values);
  };

  return (
    <Box px="30px">
      <Text
        my="40px"
        textAlign="center"
        fontSize="21px"
        color="primary"
        fontWeight="bold"
      >
        상권노트
      </Text>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.InputGroup
          id="login"
          label="아이디"
          error={errors.login?.message}
        >
          <Form.MaskedInput
            inputRef={register(validates.login)}
            name="login"
            autoCapitalize="none"
            onChange={() => {}}
          />
        </Form.InputGroup>
        <Form.InputGroup
          id="password"
          label="비밀번호"
          error={errors.password?.message}
        >
          <Form.Input
            ref={register(validates.password)}
            type="password"
            name="password"
          />
        </Form.InputGroup>
        <Form.Submit disabled={isSubmitting}>로그인</Form.Submit>
      </form>
      <Button variant="outline" mt={3}>
        <a
          href={paths.external.franchiseNote.signup()}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkText>사용 신청하기</LinkText>
        </a>
      </Button>
    </Box>
  );
};

export default Franchise;
