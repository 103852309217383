import { createBrowserHistory } from 'history';

export interface DescriptiveLink {
  label?: string;
  path: string;
}

export interface LocationState {
  from?: DescriptiveLink;
}

const history = createBrowserHistory<LocationState>();

export default history;
