import React, {
  useState,
  useEffect,
  useRef,
  createContext,
  useContext,
  RefObject,
} from 'react';
import { useKakaoMapsApi } from './useKakaoMapsApi';

import { MapLevel } from 'types';

interface KakaoMap {
  ref: null | RefObject<HTMLDivElement>;
  kakao?: any;
  map?: any;
}

const kakaoMapContext = createContext<KakaoMap>({
  ref: null,
  kakao: undefined,
  map: undefined,
});
export const KakaoMapProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const apiKey = process.env.REACT_APP_KAKAO_APP_KEY!!;
  // 강남 어딘가
  const lat = 127.053617;
  const lng = 37.506502;
  const level = MapLevel.m250;

  const kakao = useKakaoMapsApi(apiKey);
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState();

  useEffect(() => {
    if (!kakao || !ref) {
      return;
    }

    const map = new kakao.maps.Map(ref.current, {
      center: new kakao.maps.LatLng(lng, lat),
      level: level,
    });
    setMap(map);
  }, [kakao, ref, lat, lng, level]);

  return (
    <kakaoMapContext.Provider value={{ ref, map, kakao }}>
      {children}
    </kakaoMapContext.Provider>
  );
};

export const useKakaoMap = () => {
  return useContext(kakaoMapContext);
};
