import { useRef, useEffect } from 'react';

import { useKakaoMap } from 'hooks/useKakaoMap';

interface CircleProps {
  center: daum.maps.LatLng;
  radius: number;
  strokeWeight?: number;
  fillOpacity?: number;
  zIndex?: number;
}

const Circle = ({
  center,
  fillOpacity,
  radius,
  strokeWeight,
  zIndex,
}: CircleProps) => {
  const { kakao, map } = useKakaoMap();

  const circle = useRef<daum.maps.Circle>();
  if (!circle.current) {
    circle.current = new kakao.maps.Circle({
      map,
      center,
      fillOpacity,
      radius,
      strokeWeight,
      zIndex,
    });
  }

  useEffect(() => {
    return () => {
      circle.current!.setMap(null);
    };
  }, []);

  return null;
};

export default Circle;
