import React, { useState, useEffect } from 'react';
import { subYears, subMonths, format } from 'date-fns';
import { useSnackbar } from 'notistack';

import { Box } from 'components/common';
import BarChart, { BAR_CHART_COLORS } from 'components/BarChart';
import TrendChart from 'components/TrendChart';
import ViewSwitch from 'components/ViewSwitch';
import Section from 'components/Section';
import HintContents from 'components/HintContents';
import HintContentsItem from 'components/HintContentsItem';
import EmptyDataPlaceholder from 'components/EmptyDataPlaceholder';
import DurationView from 'components/DurationView';
import TipBox from 'components/TipBox';

import { VisitUserData } from '../storeAnalysis/VisitUserDataView';
import { StyledVisitUserView } from '../storeAnalysis/style';
import { AnalasysText } from '../storeAnalysis/style';

import { storeAnalysisTagStatus, storeAnalysisTagTrend } from 'api/plover';
import { TrendChartData, BarChartData } from 'models/ChartData';
import { formatPopulationCount } from 'utils/formatting';
import { trackButtonClick } from 'utils/trackEvent';
import Legend from 'assets/images/tag-analysis-hint.svg';

const TAG_LEGENDS = [
  { label: '자사고객', type: 'a', ...BAR_CHART_COLORS[0] },
  { label: '교차고객', type: 'b', ...BAR_CHART_COLORS[1] },
  { label: '경쟁사고객', type: 'c', ...BAR_CHART_COLORS[2] },
];

const TAG_TYPES = ['자사고객', '교차고객', '경쟁사고객'];

function buildStatusAnalasysText(statusData: BarChartData | null | undefined) {
  if (!statusData) return null;

  const myStore = statusData.values[0] + statusData.values[1];
  const diff = myStore - statusData.values[2];

  return (
    <AnalasysText>
      내 점포의 연간고객(자사+교차)은 <b>{formatPopulationCount(myStore)}</b>
      이고, 경쟁사 대비 <strong>{formatPopulationCount(diff)}</strong> 이{' '}
      {diff >= 0 ? '많습니다' : '적습니다'}
    </AnalasysText>
  );
}

function buildTrendAnalasysText(trendData: TrendChartData | null | undefined) {
  if (!trendData) return null;

  let indexOfMaxType = 0;
  let indexOfMaxMonth = 0;
  let max = -1;
  trendData.values.forEach((_, i) => {
    trendData.values[i].forEach((value, j) => {
      if (value > max) {
        max = value;
        indexOfMaxType = i;
        indexOfMaxMonth = j;
      }
    });
  });

  const sum = trendData.values.reduce(
    (acc: number, monthValues) => acc + monthValues[indexOfMaxMonth],
    0
  );

  const maxValue = trendData.values[indexOfMaxType][indexOfMaxMonth];

  return (
    <AnalasysText>
      <b>{TAG_TYPES[indexOfMaxType]}</b>이{' '}
      <strong>{trendData.labels[indexOfMaxMonth]}월</strong>에{' '}
      <strong>
        {formatPopulationCount(maxValue)}({Math.round((maxValue / sum) * 100)}%)
      </strong>{' '}
      으로 가장 높습니다.
    </AnalasysText>
  );
}

const TIPS = {
  status: {
    title: '현황 데이터 활용TIP!',
    tips: [
      '상권의 구매 고객 규모를 확인하고, 내 점포 고객 중 충성도 높은 자사고객과 유동성 있는 교차고객 규모를 비교하여 어떤 고객군에게 집중하여 마케팅 및 운영전략을 세울지 확인할 수 있습니다.',
      '경쟁사 고객은 입지범위에 동일한 중분류 업종을 기준으로 이전달부터 1년동안의 평균지표를 나타냅니다. 캐시노트 사업자들의 고객만 집계분석하기 때문에 상권 규모를 비교하는 지표로만 사용할 수 있습니다.',
    ],
  },
  trend: {
    title: '트렌드 데이터 활용TIP!',
    tips: [
      '상권의 구매 고객 규모를 흐름으로 확인하여, 어떤 시즌에 어떤 고객군을 타게팅해야 하는지 시즌성 영향을 고려한 전략을 세울 수 있습니다.',
      '전체 추세를 파악하여 고객군별 규모 변화를 확인하여 문제시점을 발견할 수 있습니다.',
      '내 점포 고객은 자사고객과 교차고객의 합을 나타내며, 경쟁사고객과 비교할 수 있는 지표로 활용할 수 있습니다.',
    ],
  },
};

interface TagAnalysisViewProps {
  businessId: number;
}

const CustomerGroupAnalysisView = ({ businessId }: TagAnalysisViewProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [tagData, setTagData] = useState<VisitUserData | null>(null);

  useEffect(() => {
    const doFetchTagData = async () => {
      try {
        const [status, trend] = await Promise.all([
          storeAnalysisTagStatus(businessId),
          storeAnalysisTagTrend(businessId),
        ]);
        setTagData({ status, trend });
      } catch (e) {
        enqueueSnackbar(
          '고객군별 분석에 오류가 발생했습니다. 나중에 다시 시도해 주세요.',
          { variant: 'error' }
        );
      }
    };

    doFetchTagData();
  }, [enqueueSnackbar, businessId]);

  const statusAnalasysText = buildStatusAnalasysText(tagData?.status);
  const trendAnalasysText = buildTrendAnalasysText(tagData?.trend);
  const current = new Date();
  const start = format(subYears(current, 1), 'yyyy년 MM월');
  const end = format(subMonths(current, 1), 'yyyy년 MM월');

  return (
    <StyledVisitUserView>
      <Section
        title="고객군별 분석"
        hint={{
          title: '고객군 정의',
          contents: (
            <HintContents p="20px" width="373px">
              <HintContentsItem
                title="A 자사고객"
                description="타점포 방문하지 않고 내 점포만 방문한 고객"
                subDescription="연간 방문자의 총합을 나타냅니다."
              />
              <HintContentsItem
                title="B 교차고객"
                description="내점포와 타점포 모두 방문한 고객"
                subDescription="연간 방문자의 총합을 나타냅니다."
              />
              <HintContentsItem
                title="C 경쟁사고객"
                description="내 점포를 방문하지 않고 타점포만 방문한 고객"
                subDescription="점포들의 연간 방문자의 평균을 나타냅니다."
              />
              <Box mt="20px">
                <img src={Legend} alt="고객군 정의" />
              </Box>
            </HintContents>
          ),
        }}
      >
        {tagData && (
          <ViewSwitch
            titles={['현황', '트렌드']}
            components={[
              tagData.status === null ? (
                <EmptyDataPlaceholder kind="data_not_ready" />
              ) : (
                <>
                  <DurationView start={start} end={end} />
                  {statusAnalasysText}
                  <BarChart
                    width={350}
                    height={132}
                    data={tagData.status}
                    legends={TAG_LEGENDS}
                  />
                  <TipBox
                    mt="15px"
                    title={TIPS.status.title}
                    tips={TIPS.status.tips}
                  />
                </>
              ),
              tagData.trend === null ? (
                <EmptyDataPlaceholder kind="data_not_ready" />
              ) : (
                <>
                  <DurationView start={start} end={end} />
                  {trendAnalasysText}
                  <TrendChart
                    width={350}
                    height={250}
                    data={tagData.trend}
                    legends={TAG_LEGENDS}
                    yFormatter={formatPopulationCount}
                    onToggleLegend={(v) => {
                      trackButtonClick(
                        `Store_GroupAnalysis_Trend_Legend_${TAG_LEGENDS[v].type}`
                      );
                    }}
                  />
                  <TipBox
                    mt="15px"
                    title={TIPS.trend.title}
                    tips={TIPS.trend.tips}
                  />
                </>
              ),
            ]}
            onChange={(index) => {
              trackButtonClick(
                index === 0
                  ? 'Store_GroupAnalysis_Status'
                  : 'Store_GroupAnalysis_Trend'
              );
            }}
          />
        )}
      </Section>
    </StyledVisitUserView>
  );
};

export default CustomerGroupAnalysisView;
