import styled from 'styled-components';

import colors from 'constants/colors';

const SidebarList = styled.ul`
  list-style-type: none;
  border-top: 1px solid ${colors.border};
`;

export default SidebarList;
