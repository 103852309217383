import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import moment, { Moment } from 'moment';

import { RootState } from 'app/rootReducer';
import { setIntervalRange } from 'features/aggregationCriteria/aggregationCriteriaSlice';
import QuantizedRange, {
  QuantizedType,
  IntervalValue,
} from 'models/QuantizedRange';
import QuantizedDoubleEndedSlider from './QuantizedDoubleEndedSlider';

import { trackButtonClick } from 'utils/trackEvent';

const INTERVALS: [IntervalValue, string][] = [
  ['2y', '2년'],
  ['1y', '1년'],
  ['6m', '6개월'],
  ['3m', '3개월'],
  ['2m', '2개월'],
  ['1m', '이전달'],
];

const intervalType: QuantizedType<IntervalValue> = {
  values: INTERVALS.map(([value]) => value),
  labels: INTERVALS.map(([, label]) => label),
};

const UNIT_MAP = { m: 'months', y: 'years' };
function parseMomentObject(value: IntervalValue) {
  const amount = parseInt(value.substring(0, 1));
  // @ts-ignore
  const unit = UNIT_MAP[value.substring(1)];

  return { [unit]: amount };
}

function formatMoment(value: Moment) {
  return value.format('YYYY년 M월');
}

function formatMomentSimple(value: Moment) {
  return value.format('YYYY/MM');
}
function describeIntervalRange(value: QuantizedRange<IntervalValue>) {
  const now = moment();

  const from = moment(now).subtract(parseMomentObject(value.from));
  const to = moment(now).subtract(parseMomentObject(value.to));
  const duration = moment.duration(from.diff(to));
  const compareFrom = moment(to).subtract(1, 'month');
  const compareTo = moment(compareFrom).subtract(duration);

  if (value.from === value.to) {
    return { aggr: formatMoment(from) };
  }

  const aggr = `${formatMoment(to)} - ${formatMoment(from)}`;
  const compare = `${formatMoment(compareTo)} - ${formatMoment(compareFrom)}`;
  // FIXME: 집계 결과를 알 수 있어야 함.
  if ((value.to === '2y' && value.from === '1y') || value.to !== '2y') {
    return {
      aggr,
      compare,
    };
  }

  return { aggr };
}

const Interval = () => {
  const dispatch = useDispatch();
  const intervalRange = useSelector(
    (state: RootState) => state.aggregationCriteria.intervalRange,
    shallowEqual
  );
  const [currentIntervalRange, setCurrentIntervalRange] = useState(
    intervalRange
  );

  useEffect(() => {
    setCurrentIntervalRange(intervalRange);
  }, [intervalRange]);

  const description = describeIntervalRange(currentIntervalRange);

  return (
    <div className="control-section">
      <h3>집계기간</h3>
      <div className="description">
        {description.aggr && <>집계 {description.aggr}</>}
        {description.compare && (
          <span className="normal"> | 비교 {description.compare}</span>
        )}
      </div>
      <QuantizedDoubleEndedSlider
        type={intervalType}
        value={currentIntervalRange}
        onChanged={setCurrentIntervalRange}
        onCommitted={(v) => {
          const now = moment();
          const from = moment(now).subtract(parseMomentObject(v.to));
          const to = moment(now).subtract(parseMomentObject(v.from));

          // FIXME: from, to가 request하고 반대로 되어 있다.
          trackButtonClick('Map_Interval', {
            from: v.to,
            to: v.from,
            fromDate: formatMomentSimple(from),
            toDate: formatMomentSimple(to),
          });
          dispatch(setIntervalRange(v));
        }}
      />
    </div>
  );
};

export default Interval;
