import jwtDecode from 'jwt-decode';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useSetAccount } from 'contexts/AccountContext';
import { fetchFranchiseStores } from 'features/storeAnalysis/storeSlice';

import { setToken } from 'utils/jwtToken';
import { identifyUser } from 'utils/trackEvent';
import t from 'i18n';
import bluebird from 'api/bluebird';

export function useFranchiseLoginWithPassword() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const setAccount = useSetAccount();

  return async (params: { login: string; password: string }) => {
    if (loading) {
      return;
    }

    setLoading(true);
    try {
      const { data } = await bluebird.auth.login(
        params.login.trim(),
        params.password.trim()
      );

      const { jwtToken, message } = data;

      if (message) {
        alert(
          '아이디나 비밀번호가 틀립니다.\n확인 후 정확하게 다시 입력해주세요.'
        );
        return;
      }

      loginSuccess(jwtToken, params.login);
      setAccount({ source: 'FRANCHISE', name: data.managerName });
      dispatch(fetchFranchiseStores());
    } catch (error) {
      alert(t('errors.messages.generic'));
    } finally {
      setLoading(false);
    }
  };
}

function loginSuccess(token: string, name: string) {
  const { sub: userId } = jwtDecode(token);

  identifyUser(`FR-${userId}`, {
    username: name,
    name,
  });

  setToken({ source: 'FRANCHISE', token });
}
