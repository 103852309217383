import React from 'react';
import styled from 'styled-components';

import Popup from 'components/Popup';
import colors from 'constants/colors';
import { ReactComponent as DropdownHandIcon } from 'assets/icons/DropdownHand.svg';

const StyledDropdown = styled.div`
  cursor: pointer;

  > .dropdown {
    width: 100%;
    height: 40px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    > .title {
      font-size: 13px;
      color: ${colors.gray};
      margin-left: 14px;
    }

    > .contents {
      font-size: 13px;
      color: ${colors.black};
      margin-left: 8px;
    }

    > svg {
      margin-left: auto;
      margin-right: 14px;
    }
  }
`;

interface DropDownProps {
  title: string;
  contents: string;
  open: boolean;
  onClick: () => void;
  onClose: () => void;
  children: React.ReactElement;
}

const Dropdown = ({
  title,
  contents,
  open,
  onClick,
  onClose,
  children,
}: DropDownProps) => {
  return (
    <StyledDropdown>
      <div className="dropdown" onClick={onClick}>
        <span className="title">{title}</span>
        <span className="contents">{contents}</span>
        <DropdownHandIcon />
      </div>
      <Popup open={open} onClose={onClose}>
        {children}
      </Popup>
    </StyledDropdown>
  );
};

export default Dropdown;
