import React from 'react';
import { h3ToGeo, h3ToGeoBoundary } from 'h3-js';
import { scaleLinear } from 'd3-scale';
import { interpolateLab } from 'd3-interpolate';
import { max } from 'd3-array';

import Polygon from 'components/map/Polygon';
import CustomOverlay from 'components/map/CustomOverlay';
import { useKakaoMap } from 'hooks/useKakaoMap';
interface HexbinH3Props {
  data: any[];
  zIndex: number;
}

const HexbinH3 = ({ data, zIndex }: HexbinH3Props) => {
  const { kakao } = useKakaoMap();
  const colorScale = scaleLinear()
    // @ts-ignore
    .domain([0, max(data.map((r) => r.metric?.order_cnt ?? 0))])
    // @ts-ignore
    .range(['white', 'rgb(242, 117, 165)'])
    // @ts-ignore
    .interpolate(interpolateLab);

  const toPosition = (h3Index: any) => {
    const hexCenterCoordinates = h3ToGeo(h3Index);
    return new kakao.maps.LatLng(
      hexCenterCoordinates[0],
      hexCenterCoordinates[1]
    );
  };

  return (
    <div>
      {data.map(({ h3_index, metric }) => (
        <React.Fragment key={h3_index}>
          <Polygon
            path={h3ToGeoBoundary(h3_index).map((p) => {
              return new kakao.maps.LatLng(p[0], p[1]);
            })}
            strokeWeight={1}
            strokeColor="white"
            fillColor={colorScale(metric.order_cnt)}
            fillOpacity={0.8}
            zIndex={zIndex}
          />
          <CustomOverlay
            position={toPosition(h3_index)}
            xAnchor={0}
            yAnchor={0}
          >
            <div
              style={{
                color: 'gray',
                fontSize: '20px',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '40px',
                height: '40px',
                textAlign: 'center',
                zIndex: 1,
                transform: 'translate(-20px, -20px)',
              }}
            >
              {metric.order_cnt}
            </div>
          </CustomOverlay>
        </React.Fragment>
      ))}
    </div>
  );
};

export default HexbinH3;
