import { combineReducers } from '@reduxjs/toolkit';

import aggregationCriteriaReducer from 'features/aggregationCriteria/aggregationCriteriaSlice';
import storeReducer from 'features/storeAnalysis/storeSlice';
import storeCategoriesReducer from 'features/aggregationCriteria/storeCategoriesSlice';
import mapViewReducer from 'features/mapView/mapSlice';
import mapExposureCriteriaReducer from 'features/mapExposureCriteria/mapExposureCriteriaSlice';
import conversionTrackingAnalysisReducer from 'features/conversionTrackingAnalysis/conversionTrackingAnalysisSlice';

const rootReducer = combineReducers({
  aggregationCriteria: aggregationCriteriaReducer,
  mapExposureCriteria: mapExposureCriteriaReducer,
  store: storeReducer,
  conversionTrackingAnalysis: conversionTrackingAnalysisReducer,
  storeCategories: storeCategoriesReducer,
  mapView: mapViewReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
