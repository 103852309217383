import get from 'lodash/get';

export function hasErrorWithCode(error: any, code: string) {
  if (!error) {
    return false;
  }

  const errors = Array.isArray(error) ? error : error.graphQLErrors;
  return errors.some((e: any) => e.extensions && e.extensions.code === code);
}

export function extractErrors(data: any, path = 'response') {
  if (!data) {
    return {};
  }

  const dataPath = get(data, path);
  if (!dataPath) {
    throw Error(
      `extractErrors: path "${path}" is not included in ${JSON.stringify(
        data
      )}.`
    );
  }

  if (!dataPath.errors) {
    return {};
  }

  return dataPath.errors.reduce((acc: any, error: any) => {
    acc[error.field] = error.messages[0];
    return acc;
  }, {});
}
