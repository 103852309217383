const colors = {
  primary: '#005660',
  primaryLight: '#13AD91',
  red: '#FF3030',
  blue: '#53AFF8',
  gray: '#767676',
  white: '#FFFFFF',
  black: '#000000',
  sub: '#6F6C7A',
  inactive: '#4B4A50',
  border: '#D9D9D9',
  componentBorder: '#DFDEE3',
  positive: '#BC101F',
  negative: '#3066CE',
  charcoal: '#474747',
  darkgrey: '#AEAEAE',
  maroon: '#C53A51',
  concrete: '#F2F2F2',
  teal: '#00BDBD',

  gray100: '#FCFCFD',
  gray300: '#E4E3E8',
  gray500: '#9491A1',
  gray600: '#6F6C7A',
  gray700: '#2E2B3B',
};

export default colors;
