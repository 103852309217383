import React from 'react';

import Box from 'components/common/Box';
import Flex from 'components/common/Flex';
import Text from 'components/common/Text';

import { formatNumber } from 'utils/formatting';

import colors from 'constants/colors';
import { layout } from './consts';

import WeatherIcon from './WeatherIcon';

import type { Moment } from 'moment';

interface DailyBoxProps {
  date: Moment;
  weather: 'Sun' | 'Rain';
  estimated: boolean;
  amount: number;
  count: number;
  selected: boolean;
  onWeatherClick: () => void;
}

const DailyBox = ({
  date,
  weather,
  estimated,
  amount,
  selected,
  onWeatherClick,
}: DailyBoxProps) => {
  const weekDay = date.format('ddd');
  const day = date.format('D');
  const dateColor = selected
    ? 'white'
    : date.weekday() === 0
    ? 'red'
    : date.weekday() === 6
    ? 'blue'
    : undefined;

  return (
    <Flex
      flexDirection="column"
      height="100%"
      mx={`${layout.spacing}px`}
      sx={{
        flex: '1',
        textAlign: 'center',
      }}
    >
      <Box height="70px">
        <Text color={selected ? '#1A73E8' : undefined}>{weekDay}</Text>
        <Box py="2px">
          <Text
            mx="auto"
            bg={selected ? '#1A73E8' : undefined}
            color={dateColor}
            fontSize="24px"
            fontWeight={selected ? 'bold' : undefined}
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
            }}
          >
            {day}
          </Text>
        </Box>
      </Box>
      <Box
        py={4}
        sx={{
          flex: '1',
          border: estimated ? `1px solid ${colors.gray300}` : undefined,
          // background: 'linear-gradient(#D2E4FE, #3E34AD)',
          background: estimated
            ? undefined
            : 'linear-gradient(#D3F8F7, #04BDBE)',
          borderRadius: '20px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        }}
      >
        <WeatherIcon
          name={weather}
          size="56px"
          onClick={onWeatherClick}
          usePointer={estimated}
        />
        <Text mt={3} color="#406D77" fontSize="16px">
          {estimated ? '예상 매출' : '매출 합산'}
        </Text>
        <Box px={3} fontSize="26px" sx={{ textAlign: 'right' }}>
          <Text as="span" color="primary">
            {formatNumber(amount)}
          </Text>
          <Text as="span" ml={1} fontSize="0.6em" color="gray600">
            원
          </Text>
        </Box>
        {/* <Box px={3} fontSize="26px" sx={{ textAlign: 'right' }}>
          <Text as="span" color="primary">
            {formatNumber(count)}
          </Text>
          <Text as="span" ml={1} fontSize="0.6em" color="gray600">
            건
          </Text>
        </Box> */}
      </Box>
    </Flex>
  );
};

export default DailyBox;
