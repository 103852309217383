import {
  subDays,
  addDays,
  addWeeks,
  lastDayOfMonth,
  format,
  eachMonthOfInterval,
  subMonths,
} from 'date-fns';

/**
 * 해당 년, 월의 첫 주의 첫 날짜를 Date 로 돌려줌.
 *
 * 예. 2019년 8월은 Date(2019, 6, 29)
 *
 * @param year
 * @param month
 */
export function getFirstDateOfFirstWeekInMonth(
  year: number,
  month: number
): Date {
  const possibleFirstDate = new Date(year, month - 1);

  // 1일이 월화수목이면 첫 주는 1일이 든 주, 첫 주의 첫 날은 1일이 든 주의 월요일
  const friday = 5;
  if (possibleFirstDate.getDay() < friday) {
    return subDays(possibleFirstDate, possibleFirstDate.getDay() - 1);
  }

  // 1일이 금토일이면 그 다음주가 첫 주, 그 주의 월요일이 첫 날
  const offset = 8 - possibleFirstDate.getDay();

  return addDays(possibleFirstDate, offset);
}

/**
 * 해당 년, 월의 모든 주의 첫 날짜를 Date 로 돌려줌.
 *
 * 예: 2019년 8월은 [
 *  Date(2019, 6, 29),
 *  Date(2019, 7, 5),
 *  Date(2019, 7, 12),
 *  Date(2019, 7, 19),
 *  Date(2019, 7, 26)
 * ]
 *
 * @param year
 * @param month
 */
export function getStartDatesOfEachWeekInMonth(
  year: number,
  month: number
): Date[] {
  const dates = [];
  const lastDate = lastDayOfMonth(new Date(year, month - 1));
  let startDateOfWeek = getFirstDateOfFirstWeekInMonth(year, month);

  while (addDays(startDateOfWeek, 3) <= lastDate) {
    dates.push(startDateOfWeek);
    startDateOfWeek = addWeeks(startDateOfWeek, 1);
  }

  return dates;
}

/**
 * 테스트에서 mock 하기 쉽게 하기 위해서 만듬
 */
export function currentDate() {
  return new Date();
}

/**
 * 현재 월을 제외한 최근 12개월을 yyyy-MM 포멧으로 돌려줌
 *
 * 예. 오늘이 2020-08-25 이면
 * ["2019-08", "2019-09", "2019-10", "2019-11", "2019-12", "2020-01",
 *  "2020-02", "2020-03", "2020-04", "2020-05", "2020-06", "2020-07"]
 */
export const getRecentMonths = (): string[] => {
  return eachMonthOfInterval({
    start: subMonths(currentDate(), 12),
    end: subMonths(currentDate(), 1),
  }).map((date) => format(date, 'yyyy-MM'));
};
