import React from 'react';
import styled from 'styled-components';

import colors from '../constants/colors';
import { ReactComponent as EmptyDataIcon } from 'assets/icons/EmptyData.svg';

const StyledEmptyDataPlaceholder = styled.div`
  display: flex;
  width: 100%;
  height: 192px;
  justify-content: center;
  align-items: center;

  > .inner {
    text-align: center;

    svg {
      margin-bottom: 16px;
    }

    > .message {
      color: ${colors.sub};
      font-size: 14px;
    }
  }
`;

type MessageKind = 'data_not_ready' | 'out_of_range';

interface EmptyDataPlaceholderProps {
  kind: MessageKind;
}

const EmptyDataPlaceholder: React.FC<EmptyDataPlaceholderProps> = (props) => {
  let message;
  switch (props.kind) {
    case 'data_not_ready':
      message = (
        <React.Fragment>
          아직 분석할 충분한 데이터가 쌓이지 않았습니다.
          <br />
          다음달부터 분석이 가능합니다.
        </React.Fragment>
      );
      break;
    case 'out_of_range':
      message = (
        <React.Fragment>해당 기간 내에 데이터가 없습니다.</React.Fragment>
      );
      break;
    default:
      message = null;
  }

  return (
    <StyledEmptyDataPlaceholder>
      <div className="inner">
        <EmptyDataIcon />
        <div className="message">{message}</div>
      </div>
    </StyledEmptyDataPlaceholder>
  );
};

export default EmptyDataPlaceholder;
