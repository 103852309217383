import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { select, scaleLinear, line, curveCatmullRom, easeLinear } from 'd3';

import usePrevious from 'hooks/usePrevious';

import colors from 'constants/colors';

const SvgContainer = styled.svg`
  .line-inner {
    fill: none;
    stroke: ${colors.primary};
    stroke-width: 4;
    stroke-linecap: round;
  }
  .line-outer {
    fill: none;
    stroke: white;
    stroke-width: 8;
    stroke-linecap: round;
  }
  .dot-outer {
    fill: ${colors.primary};
  }
  .dot-inner {
    fill: white;
  }
`;

function pathEnter(className: string, lineFunc: d3.Line<any>) {
  return (
    enter: d3.Selection<d3.EnterElement, any[], d3.BaseType, unknown>
  ) => {
    const path = enter
      .append('path')
      .attr('class', className)
      .attr('d', lineFunc);

    if (!path.node()) {
      return enter;
    }

    const totalLength = path.node()!.getTotalLength();
    path
      .attr('stroke-dasharray', `${totalLength} ${totalLength}`)
      .attr('stroke-dashoffset', totalLength)
      .transition()
      .duration(700)
      .ease(easeLinear)
      .attr('stroke-dashoffset', 0)
      .on('end', () => {
        path.attr('stroke-dasharray', null);
      });

    return enter;
  };
}

const LOWER = 50_0000;
const UPPER = 250_0000;

interface WeeklyGraphProps {
  width: number;
  height: number;
  data: any[];
  amountRange: [number, number];
}

const WeeklyGraph = ({
  width,
  height,
  data,
  amountRange,
}: WeeklyGraphProps) => {
  const svgRef = useRef(null);

  const prevData = usePrevious(data);

  const lower = Math.min(
    LOWER,
    (Math.ceil(amountRange[0] / LOWER) - 1) * LOWER
  );
  const upper = Math.max(
    UPPER,
    (Math.ceil(amountRange[1] / LOWER) + 1) * LOWER
  );

  useEffect(() => {
    select(svgRef.current).append('g');
  }, [svgRef]);

  useEffect(() => {
    if (JSON.stringify(prevData) === JSON.stringify(data)) {
      return;
    }

    const svg = select(svgRef.current).select('g');

    const colWidth = width / 7;
    const x = scaleLinear()
      .domain([1, 7])
      .range([colWidth / 2, width - colWidth / 2]);
    const y = scaleLinear()
      .domain([lower, upper])
      .range([height - 20, 0]);

    const curvedLine = line<{ x: number; y: number }>()
      .curve(curveCatmullRom.alpha(0.5))
      .x((d) => x(d.x))
      .y((d) => y(d.y));

    svg
      .selectAll('.line-outer')
      .data([data])
      .join(pathEnter('line-outer', curvedLine), (update) =>
        update.call((update) =>
          update.transition().duration(100).attr('d', curvedLine)
        )
      );

    svg
      .selectAll('.line-inner')
      .data([data])
      .join(pathEnter('line-inner', curvedLine), (update) =>
        update.call((update) =>
          update.transition().duration(100).attr('d', curvedLine)
        )
      );

    function circleEnter(className: string, r: number) {
      return (
        enter: d3.Selection<
          d3.EnterElement,
          { x: any; y: number },
          d3.BaseType,
          unknown
        >
      ) => {
        return enter
          .append('circle')
          .attr('class', className)
          .attr('cx', (d) => x(d.x))
          .attr('cy', (d) => y(d.y))
          .attr('r', 0)
          .call((enter) =>
            enter.transition().delay(700).duration(100).attr('r', r)
          );
      };
    }

    svg
      .selectAll('.dot-outer')
      .data(data)
      .join(circleEnter('dot-outer', 10), (update) =>
        update.call((update) =>
          update
            .transition()
            .duration(100)
            .attr('cy', (d) => y(d.y))
        )
      );

    svg
      .selectAll('.dot-inner')
      .data(data)
      .join(circleEnter('dot-inner', 8), (update) =>
        update.call((update) =>
          update
            .transition()
            .duration(100)
            .attr('cy', (d) => y(d.y))
        )
      );
  }, [data, height, prevData, svgRef, width, lower, upper]);

  return <SvgContainer ref={svgRef} width={width} height={height} />;
};

export default WeeklyGraph;
