import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core';

import Section from 'components/Section';
import { formatCount, formatCurrency } from 'utils/formatting';

const useStyles = makeStyles((_) => ({
  table: {
    '& tr *': {
      fontSize: '13px',
    },
  },
}));

const DeliveryStatView = (props: { stat: any }) => {
  const classes = useStyles();

  return (
    <>
      <Section title="내 배달">
        <TableContainer>
          <Table aria-label="simple table" className={classes.table}>
            <TableBody>
              <TableRow key="total_order_cnt">
                <TableCell component="th" scope="row">
                  배달 수
                </TableCell>
                <TableCell align="right">
                  {formatCount(props.stat.total_order_cnt)}
                </TableCell>
              </TableRow>
              <TableRow key="total_order_amount_sum">
                <TableCell component="th" scope="row">
                  배달 매출
                </TableCell>
                <TableCell align="right">
                  {formatCurrency(props.stat.total_order_amount_sum)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Section>
    </>
  );
};

export default DeliveryStatView;
