import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import _ from 'lodash';

import { RootState } from 'app/rootReducer';
import { trackButtonClick } from 'utils/trackEvent';
import { QueryFilterStoreCategory } from 'models/QueryFilters';

import StoreCategoryQueryFilterControl from './StoreCategoryQueryFilterControl';
import QueryFilterItem from './QueryFilterItem';
import { setStoreCategory, clearPopup } from './mapExposureCriteriaSlice';

function describe(filter?: QueryFilterStoreCategory): string | null {
  if (filter === undefined) {
    return null;
  }

  const {
    large,
    medium,
    small,
    revenuePercentileRange: { from, to },
  } = filter;
  const categoryString = _.takeWhile(
    [large, medium, small],
    (x) => x !== undefined
  ).join(' > ');

  return `${categoryString} / ${from}%~${to}%`;
}

const QUERY_FILTER_NAME = '업종';

const StoreCategoryQueryFilter = ({ currentPopupKey }: any) => {
  const dispatch = useDispatch();
  const {
    storeCategoryCombination,
    storeCategoryQueryFilter,
    categoryLookup,
    storeCategory,
  } = useSelector(
    (state: RootState) => ({
      storeCategoryCombination:
        state.aggregationCriteria.storeCategoryCombination,
      storeCategoryQueryFilter: state.mapExposureCriteria.storeCategory,
      categoryLookup: state.storeCategories.categoryLookup,
      storeCategory: state.mapExposureCriteria.storeCategory,
    }),
    shallowEqual
  );
  const [filterInput, setFilterInput] = useState<QueryFilterStoreCategory>();
  const filterValue = {
    large: storeCategoryCombination.large,
    medium: storeCategoryCombination.medium ?? storeCategoryQueryFilter?.medium,
    small: storeCategoryCombination.small ?? storeCategoryQueryFilter?.small,
    revenuePercentileRange: {
      from: storeCategoryQueryFilter?.revenuePercentileRange.from ?? 0,
      to: storeCategoryQueryFilter?.revenuePercentileRange.to ?? 100,
    },
  };

  useEffect(() => {
    if (currentPopupKey === QUERY_FILTER_NAME) {
      trackButtonClick('Map_Filter_StoreCategory');
    }
  }, [currentPopupKey]);

  const handleOnCommit = () => {
    const input = filterInput ?? filterValue;

    trackButtonClick('Map_Filter_StoreCategory_Apply', {
      depth1: input.large,
      depth2: input.medium ?? '전체',
      depth3: input.small ?? '전체',
      min: `${input.revenuePercentileRange.from}%`,
      max: `${input.revenuePercentileRange.to}%`,
    });
    dispatch(setStoreCategory(input));
    dispatch(clearPopup());
  };

  const handleOnClearFilter = () => {
    trackButtonClick('Map_Filter_StoreCategory_Clear');
    setFilterInput(undefined);
    dispatch(setStoreCategory(undefined));
    dispatch(clearPopup());
  };

  const handleOnUpdate = (value: QueryFilterStoreCategory) => {
    setFilterInput(value);
  };

  const handleOnClose = () => {
    // '저장' 버튼을 누르지 않고 팝업을 벗어나면 작업하던게 날아가고 기본값으로 돌아온다.
    setFilterInput({
      large: storeCategoryCombination.large,
      medium:
        storeCategoryCombination.medium ?? storeCategoryQueryFilter?.medium,
      small: storeCategoryCombination.small ?? storeCategoryQueryFilter?.small,
      revenuePercentileRange: {
        from: 0,
        to: 100,
      },
    });
    dispatch(clearPopup());
  };

  return (
    <QueryFilterItem
      currentPopupKey={currentPopupKey}
      name={QUERY_FILTER_NAME}
      description={describe(storeCategory)}
      onCommit={handleOnCommit}
      onClearFilter={handleOnClearFilter}
      onClose={handleOnClose}
      enabled={!!storeCategoryCombination.large}
    >
      <StoreCategoryQueryFilterControl
        filterValue={filterValue}
        categoryLookup={categoryLookup}
        onUpdate={handleOnUpdate}
      />
    </QueryFilterItem>
  );
};

export default StoreCategoryQueryFilter;
