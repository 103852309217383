import * as React from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

const useApi = <T = any, E = any>(
  promiseFn: (params?: any) => Promise<T>,
  ...params: any
) => {
  const [{ loading, error, data }, dispatch] = React.useReducer<
    React.Reducer<StateType<T, E>, ActionType<T, E>>
  >(reducer, {
    loading: false,
    error: null,
    data: null,
  });

  useDeepCompareEffect(() => {
    dispatch({ type: REQUEST });
    promiseFn(...params)
      .then((data) => {
        dispatch({ type: REQUEST_SUCCESS, payload: { data } });
      })
      .catch((error) => {
        dispatch({ type: REQUEST_FAILURE, payload: { error } });
      });
  }, [params, promiseFn]);

  return { data, error, loading };
};

type StateType<T, E> = {
  loading: boolean;
  data: T | null;
  error: E | null;
};

type ActionType<T, E> = {
  type: string;
  payload?: Partial<StateType<T, E>>;
};

const REQUEST = 'REQUEST';
const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
const REQUEST_FAILURE = 'REQUEST_FAILURE';

const reducer = <T = any, E = any>(
  state: StateType<T, E>,
  action: ActionType<T, E>
): StateType<T, E> => {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case REQUEST_FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default useApi;
