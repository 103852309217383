import { useEffect, useRef } from 'react';

function useAutoCompleteFix(inputElement: any) {
  const compositionEnded = useRef(true);
  const setCompositionEnd = (ended: boolean) => {
    compositionEnded.current = ended;
  };

  useEffect(() => {
    const element = inputElement?.current;
    const onCompositionStart = () => setCompositionEnd(false);
    const onCompositionEnd = () => setCompositionEnd(true);
    const onKeyDown = (e: any) => {
      if (e.key === 'ArrowDown' && compositionEnded.current === false) {
        e.stopPropagation();
      }
    };

    if (element) {
      element.addEventListener('compositionstart', onCompositionStart);
      element.addEventListener('compositionend', onCompositionEnd);
      element.addEventListener('keydown', onKeyDown);
    }

    return () => {
      element.removeEventListener('compositionstart', onCompositionStart);
      element.removeEventListener('compositionend', onCompositionEnd);
      element.removeEventListener('keydown', onKeyDown);
    };
  }, [inputElement]);
}

export default useAutoCompleteFix;
