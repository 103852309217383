import React from 'react';
import styled from 'styled-components';
import { useSelector, shallowEqual } from 'react-redux';

import { RootState } from 'app/rootReducer';
import CustomerTransactionsQueryFilterControl from './CustomerTransactionsQueryFilter';
import StoreCategoryQueryFilterControl from './StoreCategoryQueryFilter';

const QueryFilterComponent = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
`;

const MapExposureCriteriaForm = () => {
  const currentPopupKey = useSelector(
    (state: RootState) => state.mapExposureCriteria.currentPopupKey,
    shallowEqual
  );

  return (
    <QueryFilterComponent>
      <StoreCategoryQueryFilterControl currentPopupKey={currentPopupKey} />
      <CustomerTransactionsQueryFilterControl
        currentPopupKey={currentPopupKey}
      />
    </QueryFilterComponent>
  );
};

export default MapExposureCriteriaForm;
