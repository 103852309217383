import * as Sentry from '@sentry/browser';
import greenfinch from 'greenfinch';

type EventTracker = (
  name: string,
  properties?: any,
  options?: any,
  callback?: () => void
) => void;

export function identifyUser(
  userId: string,
  properties: {
    username: string;
    name: string;
    alias?: boolean;
    [key: string]: any;
  }
) {
  const { alias, ...props } = properties;

  if (alias) {
    window.analytics.alias(userId);
  }

  window.analytics.identify(userId, props);

  if (greenfinch.config) {
    greenfinch.register({
      user_id: userId,
      ...props,
    });
  }

  Sentry.setUser({
    id: userId,
    username: props.username,
  });
}

export function clearUser() {
  Sentry.setUser(null);

  if (greenfinch.config) {
    greenfinch.reset();
  }
}

export const track: EventTracker = (
  eventName,
  properties,
  options,
  callback
) => {
  if (process.env.REACT_APP_SEGMENT_LOG_ON_CONSOLE === 'true') {
    properties
      ? console.log(`Track: "${eventName}"`, properties)
      : console.log(`Track: "${eventName}"`);
  }

  if (greenfinch.config) {
    greenfinch.track(eventName, properties);
  }

  if (!properties) {
    window.analytics.track(eventName);
    return;
  }

  callback
    ? window.analytics.track(eventName, properties, options, callback)
    : window.analytics.track(eventName, properties);
};

export const trackButtonClick: EventTracker = (buttonName, properties) =>
  track(`Clicked ${buttonName} Button`, properties);

export const trackPageView: EventTracker = (pageName) => {
  track(`Viewed ${pageName}`);
};

export const trackEnter: EventTracker = (formName) => {
  track(`Entered ${formName} Form`);
};
