import React from 'react';

const APP_KEY = process.env.REACT_APP_KAKAO_APP_KEY;
const MAX_RETRY_COUNT = 3;
let isKakaoIntialized = false;

function withKakao(WrappedComponent) {
  class WithKakao extends React.Component {
    state = {
      loading: !window.Kakao,
      error: false,
    };

    retryCount = 0;

    componentDidMount() {
      this.loadKakaoScript();
    }

    loadKakaoScript = () => {
      if (window.Kakao) {
        this.handleLoad();
        return;
      }

      this.script = document.createElement('script');
      this.script.type = 'text/javascript';
      this.script.src = '//developers.kakao.com/sdk/js/kakao.min.js';
      this.script.async = true;
      this.script.defer = true;
      this.script.onload = this.handleLoad;
      this.script.onerror = this.handleError;

      document.body.appendChild(this.script);
    };

    handleLoad = () => {
      if (!isKakaoIntialized) {
        window.Kakao.init(APP_KEY);
        isKakaoIntialized = true;
      }
      this.setState({ loading: false, error: false });
    };

    handleError = () => {
      document.body.removeChild(this.script);
      if (this.retryCount < MAX_RETRY_COUNT) {
        this.retryCount++;
        this.loadKakaoScript();
      } else {
        this.setState({ loading: false, error: true });
      }
    };

    render() {
      return (
        <WrappedComponent
          isKakaoLoaded={!this.state.loading && !this.state.error}
          {...this.props}
        />
      );
    }
  }

  return WithKakao;
}

export default withKakao;
