import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'app/rootReducer';
import { formatCurrency } from 'utils/formatting';
import { trackButtonClick } from 'utils/trackEvent';
import { CustomerTransactions } from 'models/QueryFilters';

import QueryFilterItem from './QueryFilterItem';
import CustomerTransactionsQueryFilterControl from './CustomerTransactionsQueryFilterControl';
import {
  setCustomerTransactions,
  clearPopup,
} from './mapExposureCriteriaSlice';

const QUERY_FILTER_NAME = '객단가';

const CustomerTransactionsQueryFilter = ({ currentPopupKey }: any) => {
  const dispatch = useDispatch();
  const { from, to, min, max } = useSelector((state: RootState) => ({
    from: state.mapExposureCriteria.customerTransactions?.priceRange?.from,
    to: state.mapExposureCriteria.customerTransactions?.priceRange?.to,
    min: state.mapExposureCriteria.customerTransactionsRange?.min,
    max: state.mapExposureCriteria.customerTransactionsRange?.max,
  }));
  const [input, setInput] = useState<CustomerTransactions>();

  useEffect(() => {
    setInput({
      priceRange: {
        from: from ?? min,
        to: to ?? max,
      },
    });
  }, [from, max, min, to]);

  useEffect(() => {
    if (currentPopupKey === QUERY_FILTER_NAME) {
      trackButtonClick('Map_Filter_CustomerTransactions');
    }
  }, [currentPopupKey]);

  const handleOnCommit = () => {
    if (input) {
      trackButtonClick('Map_Filter_CustomerTransactions_Apply', {
        min: input.priceRange.from,
        max: input.priceRange.to,
      });
    }

    dispatch(setCustomerTransactions(input));
    dispatch(clearPopup());
  };

  const handleOnClearFilter = () => {
    trackButtonClick('Map_Filter_CustomerTransactions_Clear');
    setInput(undefined);
    dispatch(setCustomerTransactions(undefined));
    dispatch(clearPopup());
  };

  const handleOnUpdate = (value: any) => {
    setInput(value);
  };

  const handleOnClose = () => {
    dispatch(clearPopup());
  };

  const description =
    from !== undefined && to !== undefined
      ? `${formatCurrency(from)} ~ ${formatCurrency(to)}`
      : null;

  return (
    <QueryFilterItem
      currentPopupKey={currentPopupKey}
      name={QUERY_FILTER_NAME}
      description={description}
      onCommit={handleOnCommit}
      onClearFilter={handleOnClearFilter}
      onClose={handleOnClose}
      enabled={true}
    >
      <CustomerTransactionsQueryFilterControl
        onUpdate={handleOnUpdate}
        from={from}
        to={to}
        min={min}
        max={max}
      />
    </QueryFilterItem>
  );
};

export default CustomerTransactionsQueryFilter;
