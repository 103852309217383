import { useState, useEffect } from 'react';

const initialized: HTMLScriptElement[] = [];

export function useKakaoMapsApi(apiKey: string) {
  const [kakaoApi, setKakaoApi] = useState<any>();

  useEffect(() => {
    if (window.kakao) {
      // if window.kakao object is already available just use it
      setKakaoApi(window.kakao);
      return;
    }
    const src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${apiKey}&autoload=false&libraries=services`;
    const existingScript = initialized.find((el) => el.src === src);

    const onLoad = () => {
      // need to wait for loading maps
      window.kakao.maps.load(() => {
        setKakaoApi(window.kakao);
      });
    };

    if (existingScript) {
      // if script tag was added by other element just check when it is loaded
      existingScript.addEventListener('load', onLoad);
      return;
    }

    const script = document.createElement(`script`);
    script.src = src;
    script.async = true;
    script.defer = true;
    script.addEventListener(`load`, onLoad);
    (document.head as any).appendChild(script);
    initialized.push(script);

    return () => {
      script.removeEventListener(`load`, onLoad);
      script.remove();
      initialized.splice(
        initialized.findIndex((el) => el.src === src),
        1
      );
    };
  }, [apiKey]);

  return kakaoApi;
}
