import styled from 'styled-components';

import layouts from 'constants/layouts';
import colors from 'constants/colors';

// FIXME(seungjoo): styled를 직접 쓰지 않기(emotion recommended)
const SideNav = styled.nav`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${layouts.sidebar.width}px;
  background: white;
  /* z-index: 10; */
  border-right: 1px solid ${colors.border};
`;

export default SideNav;
