import React, { useState } from 'react';
import _ from 'lodash';

import Component from '../../components/QueryFilters/styles';
import DoubleEndedSlider from 'components/DoubleEndedSlider';
import StoreCategoryPicker from 'components/StoreCategoryPicker';

import { StoreCategoryCombination } from 'models/StoreCategories';
import { QueryFilterStoreCategory } from 'models/QueryFilters';

const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 100,
    label: '100%',
  },
];

/**
 * 업종분류에서 적어도 대분류가 선택되었을 때 이 컴포넌트가 enabled 된다는 가정 하에 구현되었다.
 * 초기값과 카테고리 리스트에서 보여주는 값을 정하는 것이 관건이다.
 *
 * 1. 초기값
 *
 * - 대분류는 늘 업종분류의 대분류로 고정되어 있다.
 * - 중분류는 업종분류의 중분류가 있으면 해당 값을, 아니면 필터에서 선택된 값을 갖는다.
 * - 소분류는 업종분류의 소분류가 있으면 해당 값을, 아니면 필터에서 선택된 값을 갖는다.
 *
 * 2. 카테고리 리스트 값
 *
 * - 대분류는 늘 업종분류의 대분류로 고정되어 있다.
 * - 중분류는
 *   a: 업종분류의 중분류가 설정되어 있으면 해당 값을 고정으로
 *   b: 아니면 중분류에서 보여줄 수 있는 모든 값을 보여준다.
 * - 소분류는
 *   a: 업종분류의 소분류가 설정되어 있으면 해당 값을 고정으로
 *   b: 아니면 업종분류의 중분류나 이 컴포넌트 스테이트의 중분류가 있다면 해당 값을 기반으로한 소분류 값들을 보여준다.
 */
const StoreCategoryQueryFilterControl = ({
  filterValue,
  categoryLookup,
  onUpdate,
}: any) => {
  const [filterCatComb, setFilterCatComb] = useState<QueryFilterStoreCategory>(
    filterValue
  );

  if (!categoryLookup || !filterCatComb?.large) {
    return null;
  }

  const {
    large,
    medium,
    small,
    revenuePercentileRange: { from, to },
  } = filterCatComb;

  const largeCategories = [large];
  const mediumCategories = medium ? [medium] : ['', ...categoryLookup[large]];
  const smallCategories = small
    ? [small]
    : medium
    ? ['', ...categoryLookup[`${large}:${medium}`]]
    : undefined;

  const categoryString = _.takeWhile(
    [large, medium, small],
    (x) => x !== undefined
  ).join(' > ');

  const handleStoreCategoryPickerOnChange = (
    value: StoreCategoryCombination
  ) => {
    const newValue = {
      ...value,
      revenuePercentileRange: { from, to },
    };
    setFilterCatComb(newValue);
    onUpdate(newValue);
  };

  return (
    <Component>
      <StoreCategoryPicker
        largeCategories={largeCategories}
        mediumCategories={mediumCategories}
        smallCategories={smallCategories}
        storeCategoryCombination={filterCatComb}
        onChange={handleStoreCategoryPickerOnChange}
      />
      <p className="description">
        <span className="emphasis">{categoryString}</span>
        &nbsp;업종이 매출 비중의&nbsp;
        <span className="emphasis">
          {from}%~{to}%
        </span>
        인 상권만 노출됩니다.
      </p>
      <DoubleEndedSlider
        step={10}
        min={0}
        max={100}
        value={[from, to]}
        marks={marks}
        onChange={(_, value) => {
          const [from, to] = value as number[];
          setFilterCatComb({
            ...filterCatComb,
            revenuePercentileRange: { from, to },
          });
        }}
        onChangeCommitted={(_, value) => {
          const [from, to] = value as number[];
          onUpdate({
            ...filterCatComb,
            revenuePercentileRange: { from, to },
          });
        }}
      />
    </Component>
  );
};

export default StoreCategoryQueryFilterControl;
