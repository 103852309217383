import React from 'react';

import { ReactComponent as Sun } from 'assets/icons/weather-sun.svg';
import { ReactComponent as Rain } from 'assets/icons/weather-rain.svg';
// import { ReactComponent as Snow } from 'assets/icons/weather-snow.svg';

const weatherIcons = {
  Sun,
  Rain,
  // Snow,
} as const;

export type WeatherIconKey = keyof typeof weatherIcons;

const SIZE = '28px';

const WeatherIcon = ({
  name,
  size = SIZE,
  onClick,
  usePointer,
}: {
  name: WeatherIconKey;
  size?: string;
  onClick: () => void;
  usePointer: boolean;
}) => {
  const Icon = weatherIcons[name];
  return (
    <Icon
      width={size}
      height={size}
      onClick={onClick}
      style={{ cursor: usePointer ? 'pointer' : 'default' }}
    />
  );
};

export default WeatherIcon;
