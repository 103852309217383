import styled from 'styled-components/macro';
import { space, borderColor, width } from 'styled-system';

import type { BaseProps } from 'rebass';
import type { SpaceProps, BorderColorProps, WidthProps } from 'styled-system';

interface HRKnownProps
  extends BaseProps,
    SpaceProps,
    BorderColorProps,
    WidthProps {}

interface HRProps
  extends HRKnownProps,
    Omit<React.HTMLProps<HTMLDivElement>, keyof HRKnownProps> {}

const Hr = styled.hr<HRProps>(
  {
    height: 0,
    width: '100%',
    border: 0,
    borderTop: '1px solid',
    borderColor: '#E7E7E7',
  },
  space,
  borderColor,
  width
);

export default Hr;
