import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import colors from '../constants/colors';
import { ChartLegend, TrendChartData } from '../models/ChartData';

interface TrendChartViewProps {
  width: number;
  height: number;
  data: TrendChartData;
  legends: ChartLegend[];
  hiddenIndices: number[];
  yFormatter?: (n: number) => string;
}

const StyledTrendChartView = styled.svg`
  > .tick,
  > .label {
    font-size: 12px;
    line-height: 15px;
    fill: #767676;
  }

  > .line {
    stroke-width: 2px;
  }

  > .bullet {
    stroke-width: 2px;

    :not(:hover) {
      fill: white;
    }
  }

  > .positive {
    fill: #3965ff;
  }

  > .negative {
    fill: #ff1919;
  }

  > .tooltip {
    display: none;

    rect {
      fill: white;
      stroke-width: 2px;
    }

    text {
      border: black 1px;
      font-size: 13px;
      font-weight: bold;
    }
  }
`;

const roundNumber = (n: number): number => {
  if (n >= 10000) {
    return Math.ceil(n / 10000) * 10000;
  } else if (n >= 1000) {
    return Math.ceil(n / 1000) * 1000;
  } else if (n >= 100) {
    return Math.ceil(n / 100) * 100;
  } else {
    return n;
  }
};

const TrendChartView: React.FC<TrendChartViewProps> = (props) => {
  const rows = props.legends.length;
  const columns = props.data.labels.length;

  const tooltipRef = useRef<(SVGElement | null)[][]>([]);
  const textRef = useRef<(SVGTextElement | null)[][]>([]);

  if (rows === 0 || columns === 0) {
    return <div />;
  }

  const max = roundNumber(
    Math.max(
      ...props.data.values
        .filter((_, e) => props.hiddenIndices.indexOf(e) < 0)
        .map((v) => Math.max(...v)),
      10
    )
  );
  const formatter = props.yFormatter ?? ((v) => `${Math.round(v)}`);
  const formattedMax = formatter(max);
  const formattedMax_2 = formatter(max / 2);
  const maxDigits = Math.max(formattedMax.length, formattedMax_2.length);

  const sx = maxDigits * 9 + 4;
  const sy = 6;
  const sw = props.width - sx - 4;
  const sh = props.height - sy - 24;

  const spacing = sw / columns;

  const elements = [];
  const bullets = [];
  const tooltips = [];
  for (let i = 0; i < columns; ++i) {
    const x = sx + spacing * i + spacing / 2;
    elements.push(
      <text
        textAnchor="middle"
        key={`legend-${x}`}
        x={x}
        y={sy + sh + 16}
        className="label"
      >
        {props.data.labels[i]}
      </text>
    );
  }
  let index = 0;
  for (let i = 0; i < rows; ++i) {
    if (tooltipRef.current[i] === undefined) {
      tooltipRef.current[i] = [];
    }
    if (textRef.current[i] === undefined) {
      textRef.current[i] = [];
    }

    if (props.hiddenIndices.indexOf(i) >= 0) {
      continue;
    }

    const color = props.legends[i].color;

    let prev: number[] | null = null;
    for (let j = 0; j < columns; ++j) {
      const x = sx + spacing * j + spacing / 2;
      const y = sy + sh - (props.data.values[i][j] / max) * sh;
      if (prev !== null) {
        elements.push(
          <line
            key={`line-${index}`}
            className="line"
            x1={prev[0]}
            x2={x}
            y1={prev[1]}
            y2={y}
            stroke={color}
          />
        );
      }

      const onMouseOver = () => {
        const element = tooltipRef.current[i][j];
        if (element === null) {
          return;
        }
        element.style.display = 'block';

        const rect = element.firstChild as SVGRectElement;
        const text = element.lastChild as SVGTextElement;
        let textbbox = text.getBBox();

        if (textbbox.y < 0) {
          text.setAttribute('y', `${y + 30}`);
          textbbox = text.getBBox();
        }

        rect.setAttribute('x', `${textbbox.x - 6}`);
        rect.setAttribute('y', `${textbbox.y - 6}`);
        rect.setAttribute('width', `${textbbox.width + 12}`);
        rect.setAttribute('height', `${textbbox.height + 10}`);
      };

      const onMouseOut = () => {
        const element = tooltipRef.current[i][j];
        if (element === null) {
          return;
        }
        element.style.display = 'none';
      };

      tooltips.push(
        <g
          key={`tooltip-${index}`}
          className="tooltip"
          ref={(el) => (tooltipRef.current[i][j] = el)}
        >
          <rect rx="4" ry="4" stroke={color} />
          <text
            x={x}
            y={y - 20}
            textAnchor="middle"
            ref={(el) => (textRef.current[i][j] = el)}
          >
            {props.data.captions[i][j]}
          </text>
        </g>
      );

      bullets.push(
        <circle
          cx={x}
          cy={y}
          fill={color}
          stroke={color}
          className="bullet"
          r="3.4"
          key={`bullet-${index}`}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        />
      );

      prev = [x, y];
      ++index;
    }
  }

  return (
    <StyledTrendChartView width={props.width} height={props.height}>
      <line x1={sx} x2={sx} y1={sy} y2={sy + sh} stroke={colors.black} />
      <line
        x1={sx}
        x2={sx + sw}
        y1={sy + sh}
        y2={sy + sh}
        stroke={colors.black}
      />

      <text x={sx - 4} y={sy + 4} textAnchor="end" className="tick">
        {formattedMax}
      </text>
      {max > 0 ? (
        <text x={sx - 4} y={sy + sh / 2 + 4} textAnchor="end" className="tick">
          {formattedMax_2}
        </text>
      ) : null}
      <text x={sx - 4} y={sy + sh + 4} textAnchor="end" className="tick">
        0
      </text>

      {elements}
      {bullets}
      {tooltips}
    </StyledTrendChartView>
  );
};

interface TrendChartLegendItemProps {
  active: boolean;
  color: string;
}

const TrendChartLegendItem = styled.div<TrendChartLegendItemProps>`
  display: flex;
  flex-flow: row;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  cursor: pointer;
  color: ${(props) => (props.active ? colors.primary : colors.gray)};
  border: 1px solid
    ${(props) => (props.active ? colors.primary : colors.componentBorder)};

  > .color {
    width: 16px;
    height: 4px;
    margin-right: 5px;
    background-color: ${(props) => props.color};
  }
`;

interface TrendChartProps {
  width: number;
  height: number;
  data: TrendChartData;
  legends: ChartLegend[];
  showLegend?: boolean;
  yFormatter?: (n: number) => string;
  onToggleLegend?: (index: number) => void;
}

const StyledTrendChart = styled.div`
  > .legends {
    margin-top: 16px;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const TrendChart: React.FC<TrendChartProps> = (props) => {
  const [hiddenIndices, setHiddenIndices] = useState<number[]>([]);

  const createToggle = (value: number) => {
    return () => {
      props.onToggleLegend?.(value);
      const index = hiddenIndices.indexOf(value);
      if (index >= 0) {
        setHiddenIndices([
          ...hiddenIndices.slice(0, index),
          ...hiddenIndices.slice(index + 1),
        ]);
      } else {
        setHiddenIndices([...hiddenIndices, value]);
      }
    };
  };

  return (
    <StyledTrendChart className="chart trend-chart">
      <TrendChartView
        width={props.width}
        height={props.height}
        data={props.data}
        legends={props.legends}
        hiddenIndices={hiddenIndices}
        yFormatter={props.yFormatter}
      />
      {(props.showLegend === undefined || props.showLegend === true) && (
        <div className="legends">
          {props.legends.map((value, index) => (
            <TrendChartLegendItem
              key={`legend-item-${index}`}
              active={hiddenIndices.indexOf(index) < 0}
              color={value.color}
              onClick={createToggle(index)}
            >
              <div className="color" />
              {value.label}
            </TrendChartLegendItem>
          ))}
        </div>
      )}
    </StyledTrendChart>
  );
};

export default TrendChart;
