import styled from 'styled-components';

const SidebarBlock = styled.div`
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 0;
`;

export default SidebarBlock;
