import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useKakaoMap } from 'hooks/useKakaoMap';
import paths from 'paths';

const PolygonView = ({ polygonPaths, currentMapViewInfo }: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { kakao, map } = useKakaoMap();
  const polygons = useRef<any[]>();

  useEffect(() => {
    if (!kakao) {
      return;
    }

    polygons.current?.forEach((p) => p.setMap(null));

    if (polygonPaths.length === 0 || currentMapViewInfo.level >= 11) {
      return;
    }

    const newPolygons = polygonPaths.map((polygon: any) => {
      return new kakao.maps.Polygon({
        // @ts-ignore
        path: polygon.map(([x, y]) => {
          return new kakao.maps.LatLng(x, y);
        }),
        strokeWeight: 2,
        strokeColor: '#1E93FF',
        fillColor: '#59EBFF',
        fillOpacity: 0.3,
      });
    });

    newPolygons.forEach((p: any) => p.setMap(map));
    polygons.current = newPolygons;

    return () => {
      polygons.current?.forEach((o) => o.setMap(null));
      polygons.current = [];
    };
  }, [kakao, map, polygonPaths, currentMapViewInfo]);

  useEffect(() => {
    if (pathname === paths.map() && currentMapViewInfo.level >= 11) {
      enqueueSnackbar('지도를 확대해주세요.', { variant: 'warning' });
    }
  }, [currentMapViewInfo, enqueueSnackbar, pathname]);

  return null;
};

export default PolygonView;
