export const ko = {
  errors: {
    messages: {
      generic:
        '일시적인 장애가 발생하였습니다.\n잠시 후 다시 시도해주시기 바랍니다.',
      required: '필수로 입력해야하는 값입니다.',
      invalid: '올바르지 않은 형식입니다.',
    },
  },
};

const memoized: { [key: string]: Function } = {};

const t = (path: string) => {
  if (!memoized[path]) {
    // eslint-disable-next-line no-new-func
    memoized[path] = Function('obj', `return obj.${path};`);
  }
  return memoized[path](ko);
};

export default t;
