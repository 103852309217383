import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { subYears, subMonths, format } from 'date-fns';
import { useSnackbar } from 'notistack';

import { Box } from 'components/common';
import Container from './Container';
import StoreSelection from './StoreSelection';
import StoreInfoBox from './StoreInfoBox';
import StoreAnalysisMock from './StoreAnalysisMock';
import CustomerGroupAnalysisView from '../customerGroupAnalysis/CustomerGroupAnalysisView';
import CustomerVisitAnalysisView from '../customerVisitAnalysis/CustomerVisitAnalysisView';
import ConversionTrackingAnalysisView from '../conversionTrackingAnalysis/ConversionTrackingAnalysisView';

import { RootState } from 'app/rootReducer';
import { setCurrentStore } from 'features/storeAnalysis/storeSlice';
import {
  setCurrentMapLevel,
  setPoi,
  setShowOverlay,
  setShowPolygons,
} from 'features/mapView/mapSlice';

import { useAccount } from 'contexts/AccountContext';
import { useKakaoMap } from 'hooks/useKakaoMap';

import { trackButtonClick } from 'utils/trackEvent';

import colors from 'constants/colors';

import Store from 'models/Store';

import { MapLevel } from 'types';

const Spacer = () => (
  <Box
    height="11px"
    bg="#F4F4F4"
    sx={{
      borderTop: `1px solid ${colors.border}`,
    }}
  />
);

const StoreAnalysis = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { kakao } = useKakaoMap();
  const dispatch = useDispatch();
  const { currentStore, franchises } = useSelector(
    (state: RootState) => ({
      currentStore: state.store.currentStore,
      franchises: state.store.franchises,
    }),
    shallowEqual
  );

  const current = new Date();
  const start = format(subYears(current, 1), 'yyyy년 MM월');
  const end = format(subMonths(current, 1), 'yyyy년 MM월');

  useEffect(() => {
    dispatch(setCurrentMapLevel(MapLevel.m100));
    dispatch(setShowPolygons(true));
    dispatch(setShowOverlay(true));

    return () => {
      dispatch(setPoi(null));
    };
  }, [dispatch]);

  useEffect(() => {
    function setPosition(store: Store) {
      const geocoderApi = new kakao.maps.services.Geocoder();
      geocoderApi.addressSearch(store.address, (result: any, status: any) => {
        if (status === kakao.maps.services.Status.OK) {
          dispatch(setPoi(result[0]));
        }
      });
    }

    if (kakao && currentStore) {
      setPosition(currentStore);
    }
  }, [kakao, dispatch, currentStore]);

  const account = useAccount();

  if (!account) {
    return <StoreAnalysisMock />;
  }

  if (currentStore === undefined) {
    enqueueSnackbar(
      '연동 완료된 가맹점이 없습니다. 연동할 수 있도록 가맹점주에게 안내해주세요.',
      { variant: 'warning' }
    );
    history.push('/');
  }

  return (
    <Container>
      <StoreSelection
        currentStore={currentStore}
        franchises={franchises}
        onSelect={(store) => {
          trackButtonClick('Store_StoreSelection');
          dispatch(setCurrentStore(store));
        }}
      />
      {currentStore && (
        <>
          <StoreInfoBox store={currentStore} start={start} end={end} />
          <CustomerGroupAnalysisView businessId={currentStore.businessId} />
          <Spacer />
          <CustomerVisitAnalysisView businessId={currentStore.businessId} />
          <Spacer />
          <ConversionTrackingAnalysisView
            businessId={currentStore.businessId}
          />
        </>
      )}
    </Container>
  );
};

export default StoreAnalysis;
