import React, { useEffect } from 'react';
import { Route as ReactRoute } from 'react-router-dom';

import { useAccount } from 'contexts/AccountContext';

import { trackPageView } from 'utils/trackEvent';
import paths from 'paths';

import type { RouteProps as ReactRouteProps } from 'react-router-dom';

interface RouteProps extends ReactRouteProps {
  pageName?: string;
  isPrivate?: boolean;
}

const Route = ({ pageName, isPrivate = false, ...rest }: RouteProps) => {
  const account = useAccount();
  const accessGranted = !isPrivate || !!account;

  useEffect(() => {
    if (pageName) {
      trackPageView(pageName);
    }
  }, [pageName]);

  useEffect(() => {
    if (!accessGranted) {
      paths.replace(paths.map());
    }
  }, [accessGranted]);

  return accessGranted ? <ReactRoute {...rest} /> : null;
};

export default Route;
