import React, { useEffect, useState } from 'react';
import { format, startOfMonth, subMonths } from 'date-fns';
import styled from 'styled-components';

import { Box, Flex, Text } from 'components/common';
import DeliveryTrendView from './DeliveryTrendView';
import DeliveryStatView from './DeliveryStatView';
import DeliveryStatControl from './DeliveryStatControl';
import DeliveryMapView from './DeliveryMapView';
import { getStat } from 'api/loon';
import StoreIcon from 'assets/icons/Store.svg';

const Container = styled.div`
  position: absolute;
  top: 20px;
  left: 84px;
  width: 410px;
  background: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
`;

const DeliveryAnalysisPage = () => {
  // TODO: 룬은 businessId 대신에 publicId 를 받아야 하는데
  // 이 부분도 이후에 수정 필요함
  // @ts-ignore
  const [currentStore, setCurrentStore] = useState({
    businessId: 123,
    public_id: '56359760',
    name: '바른치킨 미사점',
    address: '경기 하남시 미사강변한강로220번길 36',
    registrationNumber: '123',
    allowed: true,
    type: '',
  });

  const today = new Date();
  const startOfCurrentMonth = startOfMonth(today);
  const periods = [
    { label: '이번달', value: startOfCurrentMonth },
    { label: '지난달', value: subMonths(startOfCurrentMonth, 1) },
    { label: '3개월 전', value: subMonths(startOfCurrentMonth, 2) },
  ];
  const deliveryApps = [
    { label: '전체', value: '전체' },
    { label: '배달의 민족', value: '배달의 민족', disabled: true },
    { label: '요기요', value: '요기요', disabled: true },
  ];
  const [period, setPeriod] = useState<Date>(startOfCurrentMonth);
  const [deliveryApp, setDeliveryApp] = useState<string>('전체');
  const [hexBins, setHexBins] = useState<any[]>([]);
  const [stat, setStat] = useState<any>({});

  useEffect(() => {
    async function fetchData() {
      // 바른치킨 미사점
      // public_id: '45419945', - 바른치킨 인천 어딘가
      const resp = await getStat('56359760', 'month', {
        h3_resolution: 9,
        start_date: format(period, 'yyyyMMdd'),
        end_date: format(period, 'yyyyMMdd'),
      });
      setHexBins(resp.data);
      setStat(resp.stat);
    }

    fetchData();
  }, [currentStore, period]);

  return (
    <>
      <Container>
        <Box p="20px">
          <Flex alignItems="center">
            <Text px="2px" fontSize="20px" lineHeight="1" fontWeight="bold">
              {currentStore.name}
            </Text>
          </Flex>
          <Flex mt="8px" alignItems="center" sx={{ lineHeight: '1' }}>
            <img src={StoreIcon} alt="Store icon" />
            <Text ml="8px" color="#333333" fontSize="12px">
              {currentStore.address}
            </Text>
          </Flex>
        </Box>
        <DeliveryStatControl
          period={period}
          periodOptions={periods}
          until={today}
          onPeriodChange={setPeriod}
          deliveryApp={deliveryApp}
          deliveryAppOptions={deliveryApps}
          onDeliveryAppChange={setDeliveryApp}
        />
        <DeliveryStatView stat={stat} />
        <DeliveryTrendView publicId={currentStore.public_id} />
      </Container>
      <DeliveryMapView
        store={currentStore}
        hexBins={hexBins}
        circleRadiuses={[]}
      />
    </>
  );
};

export default DeliveryAnalysisPage;
