import React, { useEffect, useState } from 'react';

import Section from 'components/Section';
import ViewSwitch from 'components/ViewSwitch';
import EmptyDataPlaceholder from 'components/EmptyDataPlaceholder';
import TrendChart from 'components/TrendChart';
import { getTrend } from 'api/loon';
import { getISOWeek } from 'date-fns';

type DateTypes = 'week' | 'month';

interface DeliveryTrendView {
  publicId: string;
}

const DeliveryTrendView = ({ publicId }: DeliveryTrendView) => {
  const dateTypes: DateTypes[] = ['week', 'month'];

  const [dateType, setPeriod] = useState<DateTypes>(dateTypes[0]);
  const [data, setData] = useState<any>({
    labels: [],
    values: [],
    captions: [],
  });

  useEffect(() => {
    async function fetchData() {
      // @ts-ignore
      const resp = await getTrend(publicId, dateType);
      const records = {
        labels: [],
        values: [[]],
        captions: [[]],
      };

      for (const r of resp.data) {
        const label =
          dateType === 'month'
            ? new Date(r.target_date).getMonth() + 1
            : getISOWeek(new Date(r.target_date));
        // @ts-ignore
        records.labels.push(label);
        // @ts-ignore
        records.values[0].push(r.total_order_cnt);
        // @ts-ignore
        records.captions[0].push(r.total_order_cnt.toString());
      }

      setData(records);
    }

    fetchData();
  }, [publicId, dateType]);

  return (
    <>
      <Section title="배달 수 추이 분석">
        <ViewSwitch
          titles={['주간', '월간']}
          components={[
            data.labels.length === 0 ? (
              <EmptyDataPlaceholder kind="data_not_ready" />
            ) : (
              <TrendChart
                width={350}
                height={176}
                data={data}
                legends={[
                  {
                    label: '',
                    color: '#ff7777',
                    borderColor: '#d46868',
                  },
                ]}
                showLegend={false}
              />
            ),
            data.labels.length === 0 ? (
              <EmptyDataPlaceholder kind="data_not_ready" />
            ) : (
              <TrendChart
                width={350}
                height={176}
                data={data}
                legends={[
                  {
                    label: '',
                    color: '#ff7777',
                    borderColor: '#d46868',
                  },
                ]}
                showLegend={false}
              />
            ),
          ]}
          onChange={(index) => setPeriod(dateTypes[index])}
        />
      </Section>
    </>
  );
};

export default DeliveryTrendView;
