import React from 'react';
import styled from 'styled-components';

import colors from 'constants/colors';
import { AGGR_CRITERIA_TRANSACTION_COUNT } from 'constants/index';
import { formatCount, formatCurrency } from 'utils/formatting';

interface StyledMarkerProps {
  diff: number | null;
}

const StyledMarker = styled.div<StyledMarkerProps>`
  min-width: 120px;
  font-weight: bold;

  border: 1px solid black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  > .upper {
    width: 100%;
    height: 58px;
    background-color: ${(props) =>
      props.diff === null
        ? colors.primary
        : props.diff >= 0
        ? colors.positive
        : colors.negative};
    color: white;

    > .area-name {
      padding: 8px 10px 0;
      font-size: 11px;
      line-height: 16px;
    }

    > .amount {
      padding: 4px 10px 0;
      font-size: 18px;
      line-height: 24px;
    }
  }

  > .lower {
    border-top: 1px solid black;
    display: flex;
    align-items: center;
    background: white;
    width: 100%;
    height: 27px;
    color: ${(props) =>
      props.diff === null
        ? colors.primary
        : props.diff >= 0
        ? colors.positive
        : colors.negative};
    justify-content: space-between;
    padding: 0px 10px;

    > .diff,
    > .percent {
      font-size: 11px;
      font-weight: 600;
    }

    > .percent {
      justify-content: flex-end;
    }
  }
`;

interface MapMarkerProps {
  name: string;
  aggregationCriteria: any;
  amount: number;
  prev_amount: number;
}

function lastAddr(name: string) {
  const addr = name.split(' ');
  return addr[addr.length - 1];
}

const MapMarker = (props: MapMarkerProps) => {
  const formatter =
    props.aggregationCriteria === AGGR_CRITERIA_TRANSACTION_COUNT
      ? formatCount
      : formatCurrency;

  let diff = null;
  let lower = null;
  if (props.prev_amount > 0) {
    diff = props.amount - props.prev_amount;
    const percent = (diff / props.prev_amount) * 100.0;
    let percentText;
    if (percent > 0.0) {
      percentText = `▲ ${percent.toFixed(0)}%`;
    } else if (percent < 0.0) {
      percentText = `▼ ${Math.abs(percent).toFixed(0)}%`;
    } else {
      percentText = '0%';
    }
    lower = (
      <div className="lower">
        <div className="diff">{formatter(diff, true, true)}</div>
        <div className="percent">{percentText}</div>
      </div>
    );
  }

  return (
    <StyledMarker diff={diff}>
      <div className="upper">
        <div className="area-name">{lastAddr(props.name)}</div>
        <div className="amount">{formatter(props.amount, true)}</div>
      </div>
      {lower}
    </StyledMarker>
  );
};

export default MapMarker;
