import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import MapMarker from 'features/mapView/MapMarker';
import { useKakaoMap } from 'hooks/useKakaoMap';
import paths from 'paths';

const OverlayView = ({ overlaysByGeoLocation }: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const { kakao, map } = useKakaoMap();
  const overlays = useRef<any[]>();

  useEffect(() => {
    if (!kakao || !map) {
      return;
    }

    overlays.current?.forEach((o) => o.setMap(null));

    if (
      overlays.current &&
      pathname === paths.map() &&
      isEmpty(overlaysByGeoLocation)
    ) {
      enqueueSnackbar(
        '지도화면에 표시할 수 있는 데이터가 충분하지 않아 미노출된 지역이 있습니다.',
        { variant: 'warning' }
      );
      overlays.current = [];

      return;
    }

    const bounds = map.getBounds();
    const posWithinCurrentMapRange = Object.values(overlaysByGeoLocation)
      .map((o: any) => {
        const { position: pos } = o;
        const position = new kakao.maps.LatLng(pos.lat, pos.lng);

        return {
          ...o,
          position,
        };
      })
      .filter((o: any) => bounds.contain(o.position));

    const newOverlays = posWithinCurrentMapRange.map((overlay: any) => {
      const {
        name,
        aggregationCriteria,
        amount,
        prev_amount,
        position,
      } = overlay;
      const container = document.createElement('div');
      ReactDOM.render(
        <MapMarker
          name={name}
          aggregationCriteria={aggregationCriteria}
          amount={amount}
          prev_amount={prev_amount}
        />,
        container
      );

      return new kakao.maps.CustomOverlay({
        position,
        content: container,
        xAnchor: 0.5,
        yAnchor: 0.5,
      });
    });

    newOverlays.forEach((o) => o.setMap(map));
    overlays.current = newOverlays;

    return () => {
      overlays.current?.forEach((o) => o.setMap(null));
      overlays.current = [];
    };
  }, [enqueueSnackbar, kakao, map, overlaysByGeoLocation, pathname]);

  return null;
};

export default OverlayView;
