import React, { useState } from 'react';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Position from 'components/common/Position';
import IconHint from 'components/common/IconHint';
import colors from 'constants/colors';

const Heading2 = styled.h2`
  margin: 0 0 14px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${colors.primary};
`;

const SectionHeaderTooltip = withStyles({
  tooltipArrow: {
    top: -41,
    left: -4,
    width: 280,
    padding: 20,
    border: `1px solid ${colors.componentBorder}`,
    boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.15)',
    backgroundColor: colors.white,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  arrow: {
    top: '41px !important',
    color: colors.white,
  },
})(Tooltip);

interface SectionHeaderProps {
  title: string;
  tooltip: any;
}

const SectionHeader = ({ title, tooltip }: SectionHeaderProps) => {
  const [helpSelected, setHelpSelected] = useState(false);

  const handleOpen = () => {
    setHelpSelected(true);
  };

  const handleClose = () => {
    setHelpSelected(false);
  };

  return (
    <Position position="relative">
      <Heading2>{title}</Heading2>
      <SectionHeaderTooltip
        title={tooltip}
        arrow
        placement="right-start"
        onOpen={handleOpen}
        onClose={handleClose}
      >
        <Position
          position="absolute"
          top="-2px"
          right="0"
          css={{ lineHeight: 0, cursor: 'help' }}
        >
          <IconHint size="20px" selected={helpSelected} />
        </Position>
      </SectionHeaderTooltip>
    </Position>
  );
};

export default SectionHeader;
