import React from 'react';
import styled from 'styled-components';

import colors from 'constants/colors';

interface TabBarProps {
  value: number;
  titles: string[];
  onChange: (index: number) => void;
}

const StyledTabBar = styled.div`
  > .switch {
    display: flex;
    width: 100%;
    list-style-type: none;
    border-bottom: 1px solid #c4c4c4;
    margin: 0;
    padding: 0;
    justify-content: space-around;
  }
`;

const StyledTabBarItem = styled.li<{ enabled: boolean; selected: boolean }>`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 34px;
  float: left;
  cursor: pointer;
  font-size: 14px;
  opacity: ${(props) => (props.enabled ? 1 : 0.3)};
  color: ${(props) => (props.selected ? colors.primary : colors.sub)};
  border-bottom: ${(props) =>
    props.selected ? `2px solid ${colors.primary}` : 'none'};
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
`;

const TabBar = ({ value, titles, onChange }: TabBarProps) => {
  if (titles.length === 0) {
    return <div />;
  }

  return (
    <StyledTabBar>
      <ul className="switch">
        {titles.map((title, index) => (
          <StyledTabBarItem
            key={index}
            selected={value === index}
            enabled={true}
            onClick={() => onChange(index)}
          >
            {title}
          </StyledTabBarItem>
        ))}
      </ul>
    </StyledTabBar>
  );
};

export default TabBar;
