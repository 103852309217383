import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
  setCurrentMapLevel,
  setPoi,
  setShowOverlay,
  setShowPolygons,
} from 'features/mapView/mapSlice';
import { PoiEntry } from 'models/kakao-maps';
import { RootState } from 'app/rootReducer';
import { MapLevel } from 'types';

import { useKakaoMap } from 'hooks/useKakaoMap';
import Circle from 'components/map/Circle';
import HexbinH3 from './HexbinH3';

interface DeliveryMapViewProps {
  store: any;
  hexBins: any[];
  circleRadiuses: number[];
}

const DeliveryMapView = ({
  store,
  hexBins,
  circleRadiuses,
}: DeliveryMapViewProps) => {
  const { kakao, map } = useKakaoMap();
  const dispatch = useDispatch();

  const { lng, lat } = useSelector(
    (state: RootState) => ({
      lng: state.mapView.currentPoi?.x,
      lat: state.mapView.currentPoi?.y,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(setCurrentMapLevel(MapLevel.m250));
    dispatch(setShowPolygons(false));
    dispatch(setShowOverlay(false));

    return () => {
      dispatch(setPoi(null));
    };
  }, [map, dispatch]);

  useEffect(() => {
    function setPosition(store: any) {
      const geocoderApi = new kakao.maps.services.Geocoder();
      geocoderApi.addressSearch(
        store.address,
        ([poiEntry]: PoiEntry[], status: any) => {
          if (status === kakao.maps.services.Status.OK) {
            dispatch(setPoi(poiEntry));
          }
        }
      );
    }

    if (kakao && map && store) {
      setPosition(store);
    }
  }, [dispatch, kakao, map, store]);

  if (!kakao || !map) {
    return null;
  }

  return (
    <>
      {lat &&
        lng &&
        circleRadiuses.map((r) => (
          <Circle
            key={r}
            center={new kakao.maps.LatLng(lat, lng)}
            radius={r}
            strokeWeight={1}
            fillOpacity={0.06}
            zIndex={1}
          />
        ))}
      <HexbinH3 data={hexBins} zIndex={3} />
    </>
  );
};

export default DeliveryMapView;
