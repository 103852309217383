import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  format,
  parse,
  addWeeks,
  endOfWeek,
  getYear,
  getMonth,
} from 'date-fns';
import _ from 'lodash';

import { AppThunk } from 'app/store';
import { fetchStoreAnalysisCohortTrend, CohortTrend } from 'api/plover';
import { currentDate, getStartDatesOfEachWeekInMonth } from 'utils/date';
import { TrendChartData } from 'models/ChartData';

export type SalesPeriod = Record<'year' | 'month' | 'week', number>;
export type ConversionDuration = Record<'startWeek' | 'endWeek', number>;
export type UserType = 'all' | 'new' | 'revisit';

interface conversionTrackingAnalysisState {
  // salesStartDate is much more for debugging purpose.
  // It can be easily derived from salesPeriod.
  salesStartDate: string;
  salesPeriod: SalesPeriod;
  conversionDuration: ConversionDuration;
  userType: UserType;
  chartData: any | null;
  loading: boolean;
  error: string | null;
}

// Initiate state from first week of previous month.
const CURRENT_DATE = currentDate();
const year = getYear(CURRENT_DATE);

// month from Date is zero based but month for getStartDatesOfEachWeekInMonth
// is starting from 1.
const month = getMonth(CURRENT_DATE);
const salesStartDate = getStartDatesOfEachWeekInMonth(year, month)[0];

const initialState: conversionTrackingAnalysisState = {
  salesStartDate: format(salesStartDate, 'yyyy-MM-dd'),
  salesPeriod: {
    year,
    month,
    week: 1,
  },
  conversionDuration: {
    startWeek: 1,
    endWeek: 3,
  },
  userType: 'all',
  chartData: null,
  loading: false,
  error: null,
};

const conversionTrackingAnalysis = createSlice({
  name: 'conversionTrackingAnalysis',
  initialState,
  reducers: {
    setSalesStartDate(state, action: PayloadAction<string>) {
      state.salesStartDate = action.payload;
    },
    setSalesPeriod(state, action: PayloadAction<SalesPeriod>) {
      state.salesPeriod = action.payload;
    },
    setConversionDuration(state, action: PayloadAction<ConversionDuration>) {
      state.conversionDuration = action.payload;
    },
    setUserType(state, action: PayloadAction<UserType>) {
      state.userType = action.payload;
    },
    fetchChartStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchChartSuccess(state, action: PayloadAction<TrendChartData | null>) {
      state.chartData = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchChartDateFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setSalesStartDate,
  setSalesPeriod,
  setConversionDuration,
  setUserType,
  fetchChartStart,
  fetchChartSuccess,
  fetchChartDateFailure,
} = conversionTrackingAnalysis.actions;

export default conversionTrackingAnalysis.reducer;

function toCohortTrendChart(
  data: CohortTrend,
  salesStartDate: string,
  conversionDuration: ConversionDuration
): TrendChartData | null {
  if (data.conversion_stat.length === 0 && data.bounce_stat.length === 0) {
    return null;
  }

  const labels = _.range(
    conversionDuration.startWeek,
    conversionDuration.endWeek + 1
  ).map((value) => ({
    label: `${value}주`,
    value: format(
      addWeeks(parse(salesStartDate, 'yyyy-MM-dd', new Date()), value),
      'yyyy-MM-dd'
    ),
  }));

  const bounce_stat = new Map<string, number>(
    data.bounce_stat.map((obj: any) => [obj.date, obj.count])
  );
  const conversion_stat = new Map<string, number>(
    data.conversion_stat.map((obj: any) => [obj.date, obj.count])
  );
  const values = [
    labels.map((item) => conversion_stat.get(item.value) ?? 0),
    labels.map((item) => bounce_stat.get(item.value) ?? 0),
  ];
  const captions = values.map((sublist: number[]) =>
    sublist.map((item: number) => `${item}`)
  );

  return {
    labels: labels.map((value) => value.label),
    values,
    captions,
  };
}

export const fetchChartData = (
  businessId: number,
  salesStartDate: string,
  conversionDuration: ConversionDuration,
  userType: UserType
): AppThunk => async (dispatch) => {
  const payload = {
    salesDate: salesStartDate,
    conversionDuration: {
      from: format(
        addWeeks(
          parse(salesStartDate, 'yyyy-MM-dd', new Date()),
          conversionDuration.startWeek
        ),
        'yyyy-MM-dd'
      ),
      to: format(
        endOfWeek(
          addWeeks(
            parse(salesStartDate, 'yyyyy-MM-dd', new Date()),
            conversionDuration.endWeek
          ),
          { weekStartsOn: 1 }
        ),
        'yyyy-MM-dd'
      ),
    },
    userType,
  };

  try {
    dispatch(fetchChartStart());

    const data = await fetchStoreAnalysisCohortTrend(businessId, payload);
    const chartData = toCohortTrendChart(
      data,
      salesStartDate,
      conversionDuration
    );
    dispatch(fetchChartSuccess(chartData));
  } catch (err) {
    dispatch(fetchChartDateFailure(err));
  }
};
