import React from 'react';

import Position from 'components/common/Position';

import layouts from 'constants/layouts';

import crossCheck from 'assets/cross-check.svg';

interface FloatingContainerProps {
  onClose?: () => void;
  children: React.ReactNode;
}

const FloatingContainer = ({ onClose, children }: FloatingContainerProps) => {
  return (
    <Position
      position="absolute"
      top={0}
      left={layouts.sidebar.width}
      width={layouts.floatingContainer.width}
      height="100%"
      bg="white"
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
        boxShadow: 'container',
      }}
    >
      {onClose && (
        <Position position="absolute" top={12} right={16} onClick={onClose}>
          <img src={crossCheck} alt="닫기" style={{ cursor: 'pointer' }} />
        </Position>
      )}
      {children}
    </Position>
  );
};

export default FloatingContainer;
