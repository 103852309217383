import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';

import { fetchSearchStore } from 'api/plover';

import Store from 'models/Store';

interface InfoBoxItemProps {
  label: string;
  value: string;
  valueColor?: string;
}

const InfoBoxItem = ({ label, value, valueColor }: InfoBoxItemProps) => (
  <Flex>
    <Text width="53px">{label}</Text>
    <Text flex="1" ml="10px" fontWeight="bold" color={valueColor}>
      {value}
    </Text>
  </Flex>
);

interface StoreInfoBoxProps {
  store: Store;
  start: string;
  end: string;
}

const StoreInfoBox = ({ store, start, end }: StoreInfoBoxProps) => {
  const [address, setAddress] = useState('');
  const [classification, setClassification] = useState('');

  useEffect(() => {
    async function fetchInfo() {
      const info = await fetchSearchStore(store.businessId.toString());
      setAddress(
        `${info.address.sido} ${info.address.sigungu} ${info.address.dong}`
      );
      setClassification(
        info.classification
          ? `${info.classification.depth_1_name} > ${info.classification.depth_2_name} > ${info.classification.depth_3_name}`
          : ''
      );
    }

    if (store) {
      fetchInfo();
    }
  }, [store]);

  return (
    <Box p="16px 20px" bg="concrete">
      <InfoBoxItem
        label="입지범위"
        value={address}
        valueColor="teal"
      ></InfoBoxItem>
      <InfoBoxItem label="업종" value={classification}></InfoBoxItem>
      <InfoBoxItem label="분석기간" value={`${start} ~ ${end}`}></InfoBoxItem>
    </Box>
  );
};

export default StoreInfoBox;
