import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Text } from 'rebass/styled-components';

import * as Form from 'components/common/Form';
import { LinkButton } from 'components/common/Button';

import { useCashnoteLoginWithPassword } from 'hooks/useCashnoteLogin';

import paths from 'paths';
import t from 'i18n';

const validates = {
  login: {
    required: t('errors.messages.required'),
  },
  password: {
    required: t('errors.messages.required'),
    minLength: { value: 4, message: t('errors.messages.invalid') },
  },
};

interface IFormInput {
  login: string;
  password: string;
}

const Cashnote = () => {
  const loginWithPassword = useCashnoteLoginWithPassword();
  const { register, handleSubmit, setError, errors, formState } = useForm<
    IFormInput
  >({
    mode: 'onBlur',
    defaultValues: {
      login: '',
      password: '',
    },
  });
  const { isSubmitting } = formState;

  const onSubmit = async (values: IFormInput) => {
    const data = await loginWithPassword(values);

    if (data?.login.errors) {
      data.login.errors.forEach((error: any) => {
        setError(error.field, error.messages[0]);
      });
    }
  };

  return (
    <Box px="30px">
      <Text
        my="40px"
        textAlign="center"
        fontSize="21px"
        color="primary"
        fontWeight="bold"
      >
        가맹점 고객 로그인
      </Text>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.InputGroup
          id="login"
          label="휴대전화번호 또는 아이디"
          error={errors.login?.message}
        >
          <Form.MaskedInput
            inputRef={register(validates.login)}
            name="login"
            autoCapitalize="none"
            onChange={() => {}}
          />
        </Form.InputGroup>
        <Form.InputGroup
          id="password"
          label="비밀번호"
          error={errors.password?.message}
        >
          <Form.Input
            ref={register(validates.password)}
            type="password"
            name="password"
          />
        </Form.InputGroup>
        <Form.Submit disabled={isSubmitting}>로그인</Form.Submit>
      </form>
      <Text mt="2rem" textAlign="center">
        <LinkButton to={paths.external.cashnote.account.retrieve()}>
          아이디 찾기
        </LinkButton>
        <LinkButton to={paths.external.cashnote.password.reset()} ml={3}>
          비밀번호 재발급
        </LinkButton>
      </Text>
    </Box>
  );
};

export default Cashnote;
