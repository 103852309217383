import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import styled from 'styled-components';

import SectionHeader from 'features/mapAnalysis/SectionHeader';
import AggregationTooltip from 'features/mapAnalysis/AggregationTooltip';
import QueryFilterTooltip from 'features/mapAnalysis/QueryFilterTooltip';

import RefreshIcon from 'assets/icons/Refresh.svg';

import { RootState } from 'app/rootReducer';
import {
  setCurrentMapViewInfo,
  setShowOverlay,
  setShowPolygons,
} from 'features/mapView/mapSlice';
import { clear } from 'features/aggregationCriteria/aggregationCriteriaSlice';
import {
  setStoreCategory,
  setCustomerTransactions,
} from 'features/mapExposureCriteria/mapExposureCriteriaSlice';
import { useKakaoMap } from 'hooks/useKakaoMap';

import colors from 'constants/colors';
import { toMapViewInfo } from 'utils/map';
import PlaceSearchForm from 'features/placeSearch/PlaceSearchForm';
import AggregationCriteriaForm from 'features/aggregationCriteria/AggregationCriteriaForm';
import MapExposureCriteriaForm from 'features/mapExposureCriteria/MapExposureCriteriaForm';

const Container = styled.div`
  position: absolute;
  top: 20px;
  /* bottom: 20px; */
  left: 84px;
  width: 410px;
  /* max-height: 712px; */
  /* overflow-y: scroll; */
  background: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;

  .search-query-input {
    border-bottom: solid 1px ${colors.border};
    padding: 8px 20px;
  }

  > .section {
    border-bottom: solid 1px ${colors.border};
    padding: 20px;
  }

  > .search-query-input {
    display: flex;
    align-items: center;

    input {
      width: 366px;
      border: none;
      font-size: 14px;
    }

    input ::placeholder {
      color: ${colors.inactive};
    }
  }

  > .search-query-filters {
    padding-bottom: 10px;
  }

  > .clear-button {
    display: flex;
    width: 100%;
    height: 29px;
    padding: 0;
    border: none;
    border-radius: 4px;
    background-color: transparent;
    color: ${colors.primary};
    font-size: 12px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #f7f7f8;

    img {
      margin-right: 4px;
    }
  }
`;

const MapAnalysis = () => {
  const { map } = useKakaoMap();
  const dispatch = useDispatch();
  const { aggregationCriteria, revenueRange, intervalRange } = useSelector(
    (state: RootState) => state.aggregationCriteria,
    shallowEqual
  );

  useEffect(() => {
    if (!map) {
      return;
    }

    dispatch(setCurrentMapViewInfo(toMapViewInfo(map)));
    dispatch(setShowPolygons(true));
    dispatch(setShowOverlay(true));
  }, [map, dispatch]);

  const handleClearFilters = () => {
    dispatch(clear());
    dispatch(setStoreCategory(undefined));
    dispatch(setCustomerTransactions(undefined));
  };

  return (
    <Container>
      <div className="search-query-input">
        <PlaceSearchForm />
      </div>
      <div className="section search-aggregation-criteria">
        <SectionHeader title="지도 집계기준" tooltip={<AggregationTooltip />} />
        <AggregationCriteriaForm
          aggregationCriteria={aggregationCriteria}
          revenueRange={revenueRange}
          intervalRange={intervalRange}
        />
      </div>
      <div className="section search-query-filters">
        <SectionHeader title="지도 노출기준" tooltip={<QueryFilterTooltip />} />
        <MapExposureCriteriaForm />
      </div>
      <button className="clear-button" onClick={handleClearFilters}>
        <img src={RefreshIcon} alt="refresh" /> 필터 초기화
      </button>
    </Container>
  );
};

export default MapAnalysis;
