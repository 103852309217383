import React from 'react';
import { Text, TextProps, Box, Flex } from 'rebass/styled-components';

import colors from 'constants/colors';

const Title = (props: TextProps) => (
  <Text
    color="#555555"
    fontSize="14px"
    fontWeight="bold"
    mb="14px"
    {...props}
  ></Text>
);

const SubTitle = (props: TextProps) => (
  <Text
    color={colors.sub}
    fontSize="12px"
    fontWeight="bold"
    mb="8px"
    {...props}
  ></Text>
);

const Description = (props: TextProps) => (
  <Text
    color={colors.sub}
    fontSize="12px"
    lineHeight="18px"
    mb="20px"
    {...props}
  ></Text>
);

const Marker = () => (
  <Box
    width="162px"
    mb="16px"
    backgroundColor="#74757A"
    color={colors.white}
    css={{
      border: '1px solid #53575F',
      fontSize: '10px',
      lineHeight: 1,
      fontWeight: 'bold',
    }}
  >
    <Box p="9px 9px 11px">
      <Text mb="10px">행정동명</Text>
      <Text fontSize="16px">월 집계기준의 결과</Text>
    </Box>
    <Flex
      justifyContent="space-between"
      p="8px 9px"
      css={{
        borderTop: '1px solid #53575F',
        backgroundColor: colors.white,
        color: '#575C67',
      }}
    >
      <Text>비교기간 증감율</Text>
      <Text>비교기간 증감액</Text>
    </Flex>
  </Box>
);

const AggregationTooltip = () => (
  <>
    <Title>집계기준 정의</Title>
    <Marker />
    <SubTitle>지도 위 마크</SubTitle>
    <Description>
      지도에 노출된 행정 기준으로 캐시노트와 연동된 업체들의 정보를 집계한 값이
      나타납니다.
    </Description>
    <SubTitle>집계기준 세부필터</SubTitle>
    <Description>
      집계되는 업체를 범위, 집계기간, 업종분류, 객단가 등 세부조건으로 상세히
      필터할 수 있습니다.
    </Description>
    <SubTitle>마크의 증감율</SubTitle>
    <Description mb="0">
      상승하락 정보는 집계기간의 동일한 이전기간 대비 평균매출의 상승/하락
      금액과 상승/하락율 입니다.
    </Description>
  </>
);

export default AggregationTooltip;
