import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { RootState } from 'app/rootReducer';
import StoreCategoryPicker from 'components/StoreCategoryPicker';

import { buildStoreCategories } from './storeCategoriesSlice';
import { setStoreCategoryCombination } from './aggregationCriteriaSlice';
import { setStoreCategory as setQueryFilterStoreCategory } from 'features/mapExposureCriteria/mapExposureCriteriaSlice';

import { trackButtonClick } from 'utils/trackEvent';

const StoreCategoriesComponent = () => {
  const dispatch = useDispatch();
  const {
    largeCategories,
    categoryLookup,
    storeCategoryCombination,
  } = useSelector(
    (state: RootState) => ({
      largeCategories: state.storeCategories.largeCategories,
      categoryLookup: state.storeCategories.categoryLookup,
      storeCategoryCombination:
        state.aggregationCriteria.storeCategoryCombination,
    }),
    shallowEqual
  );
  const { large, medium } = storeCategoryCombination;

  useEffect(() => {
    if (!largeCategories || !categoryLookup) {
      dispatch(buildStoreCategories());
    }
  }, [dispatch, largeCategories, categoryLookup]);

  if (!largeCategories || !categoryLookup) {
    return null;
  }

  return (
    <StoreCategoryPicker
      storeCategoryCombination={storeCategoryCombination}
      largeCategories={['', ...largeCategories]}
      mediumCategories={['', ...(categoryLookup[large as string] ?? [])]}
      smallCategories={['', ...(categoryLookup[`${large}:${medium}`] ?? [])]}
      onChange={(value) => {
        trackButtonClick('Map_StoreCategory', {
          depth1: value.large ?? '전체',
          depth2: value.medium ?? '전체',
          depth3: value.small ?? '전체',
        });
        dispatch(setStoreCategoryCombination(value));
        dispatch(setQueryFilterStoreCategory(undefined));
      }}
    />
  );
};

export default StoreCategoriesComponent;
