import range from 'lodash/range';
import * as d3 from 'd3-geo';
import polylabel from 'polylabel';
import simplify from 'simplify-js';

import {
  MarketAreaSearchParams,
  marketAreaSearch,
  marketAreaGeoPolygons,
} from 'api/plover';
import { RevenueQuarterValues } from 'models/QuantizedRange';
import { MapLevel, GeoLevel } from 'types';

export interface MapViewInfo {
  boundsNe: { lat: number; lng: number } | null;
  boundsSw: { lat: number; lng: number } | null;
  level: MapLevel;
}

export interface GeoInfo {
  geoPoints: Record<'lat' | 'lon', number>[];
  geoLevel: GeoLevel;
}

export function toMapViewInfo(map: any): MapViewInfo {
  const bounds = map.getBounds();
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  const mapLevel: MapLevel = map.getLevel();

  return {
    boundsNe: {
      lat: ne.getLat(),
      lng: ne.getLng(),
    },
    boundsSw: {
      lat: sw.getLat(),
      lng: sw.getLng(),
    },
    level: mapLevel,
  };
}

export function toGeoInfo({ boundsNe, boundsSw, level }: MapViewInfo): GeoInfo {
  if (!boundsNe || !boundsSw) {
    return {
      geoPoints: [],
      geoLevel: GeoLevel.Dong,
    };
  }

  let geoLevel: GeoLevel;

  if (level <= MapLevel.m500) {
    geoLevel = GeoLevel.Dong;
  } else if (level <= MapLevel.km4) {
    geoLevel = GeoLevel.Gu;
  } else {
    geoLevel = GeoLevel.Si;
  }

  return {
    geoPoints: [
      { lat: boundsNe.lat, lon: boundsNe.lng },
      { lat: boundsNe.lat, lon: boundsSw.lng },
      { lat: boundsSw.lat, lon: boundsSw.lng },
      { lat: boundsSw.lat, lon: boundsNe.lng },
    ],
    geoLevel: geoLevel,
  };
}

export interface Overlay {
  name: string;
  aggregationCriteria: string;
  amount: number;
  prev_amount: number;
  count: number;
  position?: { lat: number; lng: number };
}

export const fetchMapData = async ({
  currentMapViewInfo,
  aggregationCriteria,
  intervalRange,
  revenueRange,
  storeCategoryCombination,
  customerTransactions,
  filterStoreCategory,
}: any): Promise<{
  polygonPaths: [number, number][][];
  overlaysByGeoLocation: Record<string, Overlay>;
}> => {
  const storeCategory = !storeCategoryCombination.large
    ? undefined
    : {
        category1: storeCategoryCombination.large,
        category2: storeCategoryCombination.medium,
        category3: storeCategoryCombination.small,
      };

  const queryFilterStoreCategory = filterStoreCategory
    ? {
        category1: filterStoreCategory.large,
        category2: filterStoreCategory.medium,
        category3: filterStoreCategory.small,
        revenuePercentileRange: filterStoreCategory.revenuePercentileRange,
      }
    : undefined;

  const geoInfo = toGeoInfo(currentMapViewInfo);
  if (geoInfo.geoPoints.length !== 4) {
    return {
      polygonPaths: [],
      overlaysByGeoLocation: {},
    };
  }

  const params: MarketAreaSearchParams = {
    ...geoInfo,
    aggregationCriteria,
    interval: {
      from: intervalRange.to,
      to: intervalRange.from,
    },
    revenuePercentileRange: range(revenueRange[0], revenueRange[1] + 1).map(
      (value) => RevenueQuarterValues[value]
    ),
    storeCategory,
    queryFilters: {
      customerTransactions,
      storeCategory: queryFilterStoreCategory,
    },
  };

  const markerInfo = await marketAreaSearch(params);

  if (markerInfo.length === 0) {
    return {
      polygonPaths: [],
      overlaysByGeoLocation: {},
    };
  }

  const geoLocationsToFetch = markerInfo.map((marker: any) => {
    return marker.geo_location;
  });

  const overlaysByGeoLocation: Record<string, Overlay> = {};
  markerInfo.forEach((marker: any) => {
    const { geo_location, value, prev_value, count } = marker;
    overlaysByGeoLocation[geo_location] = {
      name: '',
      amount: value as number,
      prev_amount: prev_value as number,
      aggregationCriteria,
      count,
    };
  });
  const geoLocations = await marketAreaGeoPolygons(
    geoLocationsToFetch,
    geoInfo.geoLevel
  );

  // polygons from fetched data
  geoLocations.forEach((loc: any) => {
    let maxArea = 0;
    let maxPolygon: any[] = [];

    loc.polygons.forEach((p: any) => {
      const area = d3.geoArea({ type: 'Polygon', coordinates: p });
      if (area > maxArea) {
        maxPolygon = p;
        maxArea = area;
      }
    });

    const p = polylabel(maxPolygon);
    const [y, x] = p;

    const { geo_location, address } = loc;
    overlaysByGeoLocation[geo_location] = {
      ...overlaysByGeoLocation[geo_location],
      ...{ name: address, position: { lat: x, lng: y } },
    };
  });

  let threshold: number = 0.00001;
  if (geoInfo.geoLevel === GeoLevel.Dong) {
    threshold = 0.00001;
  } else if (geoInfo.geoLevel === GeoLevel.Gu) {
    threshold = 0.001;
  } else {
    threshold = 0.01;
  }

  const polygonPaths = geoLocations.flatMap((loc: any) => {
    const polygons: [number, number][][] = [];
    loc.polygons.forEach((polygonArray: [number, number][][]) => {
      polygonArray.forEach((polygon) => {
        const reduced = simplify(
          polygon.map(([y, x]: any) => ({ x, y })),
          threshold,
          false
        );
        polygons.push(reduced.map(({ x, y }) => [x, y]));
      });
    });

    return polygons;
  });

  return {
    polygonPaths,
    overlaysByGeoLocation,
  };
};
