import { gql } from '@apollo/client';

export default gql`
  fragment account on User {
    _id: login
    name
    businesses {
      nodes {
        id
        name
        registrationNumber
        acceptances {
          terms
          acceptedAt
        }
        franchiseBranch {
          id
          approvedAt
        }
        role
      }
    }
  }
`;
