import moment from 'moment';
import 'moment/locale/ko';
import React from 'react';
import { Router, Switch } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';

import ErrorBoundary from 'ErrorBoundary';
import ThemeProvider from 'ThemeProvider';
import { AccountProvider } from 'contexts/AccountContext';
import { KakaoMapProvider } from 'hooks/useKakaoMap';

import Route from 'components/Route';

import MapView from 'features/mapView/MapView';
import Sidebar from 'features/sidebar/Sidebar';
import MapAnalysis from 'features/mapAnalysis/MapAnalysisPage';
import StoreAnalysis from 'features/storeAnalysis/StoreAnalysisPage';
import DeliveryAnalysis from 'features/deliveryAnalysis/DeliveryAnalysisPage';
import WeatherAnalysisPage from 'features/weatherAnalysis/WeatherAnalysisPage';
import Auth from 'features/auth';

import apolloClient from 'graphql/apolloClient';
import browserHistory from 'browserHistory';
import store from './store';

moment.locale('ko');

function App() {
  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <ReduxProvider store={store}>
          <ThemeProvider>
            <AccountProvider>
              <KakaoMapProvider>
                <SnackbarProvider>
                  <Router history={browserHistory}>
                    <MapView />
                    <Sidebar />
                    <Switch>
                      <Route path="/auth">
                        <Auth />
                      </Route>
                      <Route path="/store" pageName="Store">
                        <StoreAnalysis />
                      </Route>
                      <Route path="/delivery" pageName="Delivery">
                        <DeliveryAnalysis />
                      </Route>
                      <Route path="/weather" pageName="Weather">
                        <WeatherAnalysisPage />
                      </Route>
                      <Route path="/" pageName="Map">
                        <MapAnalysis />
                      </Route>
                    </Switch>
                  </Router>
                </SnackbarProvider>
              </KakaoMapProvider>
            </AccountProvider>
          </ThemeProvider>
        </ReduxProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

export default App;
