import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import StoreCategories from './StoreCategories';
import DoubleEndedSlider from 'components/DoubleEndedSlider';
import colors from 'constants/colors';
import QuantizedRange, {
  IntervalRange,
  RevenueQuarterLabels,
  RevenueQuarterValues,
  describeRange,
} from 'models/QuantizedRange';
import {
  setAggregationCriteria,
  setRevenueRange,
} from 'features/aggregationCriteria/aggregationCriteriaSlice';
import ControlSectionInterval from './Interval';

import { trackButtonClick } from 'utils/trackEvent';

const StyledAggregationCriteria = styled.div`
  > .aggregation-criteria {
    /* display: grid;
    grid-gap: 1px;
    grid-template-columns: 1fr 1fr 1fr; */
    display: flex;
    /* border-radius: 4px; */
    margin-bottom: 20px;
    /* padding: 1px;
    background-color: ${colors.componentBorder}; */
  }

  > .revenue-percentile {
    font-weight: bold;
    font-size: 14px;
  }

  > .emphasis {
    color: ${colors.red};
  }

  > .control-section {
    position: relative;
    margin-top: 20px;
    color: ${colors.black};

    h3 {
      margin: 0 0 10px;
      font-size: 13px;
      line-height: 14px;
      font-weight: bold;
    }

    > .description {
      position: absolute;
      top: 0;
      right: 0;
      color: ${colors.red};
      font-size: 11px;
      line-height: 14px;
      font-weight: bold;

      .normal {
        color: ${colors.gray}
      }
    }
  }
`;

interface AggregationCriteriaProps {
  aggregationCriteria: string;
  revenueRange: number[];
  // TODO(seungjoo): remove
  intervalRange: IntervalRange;
}

const AggregationCriteriaTypes = [
  { value: 'average-revenue', label: '월평균매출', enabled: true },
  { value: 'customer-transactions', label: '객단가', enabled: true },
  { value: 'transaction-count', label: '결제건수', enabled: true },
  // { value: 'paying-customers', label: '결제인구수', enabled: false },
  // { value: 'business-entities', label: '사업자수', enabled: false },
  // { value: '', label: '가구수', enabled: false },
  // { value: '', label: '유동인구수', enabled: false },
  // { value: '', label: '주거인구수', enabled: false },
];

interface AggregationCriteriaItemProps {
  active: boolean;
  enabled: boolean;
}

const AggregationCriteriaItem = styled.div<AggregationCriteriaItemProps>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  z-index: ${(props) => (props.active ? 1 : 0)};
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.active ? colors.primary : colors.componentBorder};
  background-color: ${(props) =>
    props.active ? colors.primary : colors.white};
  color: ${(props) =>
    props.enabled ? (props.active ? colors.white : '#555555') : '#ccc'};
  font-size: 14px;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  cursor: pointer;

  :not(:first-child):not(:last-child) {
    margin: 0 -1px;
  }

  :first-child {
    border-radius: 4px 0 0 4px;
  }

  :last-child {
    border-radius: 0 4px 4px 0;
  }
`;

const RevenueMarks = RevenueQuarterLabels.map((value, index) => {
  return {
    value: index,
    label: value,
  };
});

const RevenueIndexedType = {
  values: _.range(0, RevenueQuarterValues.length).map((v) => v.toString()),
  labels: RevenueQuarterLabels,
};

// NOTE: hmm...
const RevenueQuarterBoundaries = [
  [0, 25],
  [26, 50],
  [51, 75],
  [76, 100],
];
function describeBoundaries(value: QuantizedRange<string>, type: any): string {
  const lower = RevenueQuarterBoundaries[type.values.indexOf(value.from)][0];
  const upper = RevenueQuarterBoundaries[type.values.indexOf(value.to)][1];

  return `${lower}~${upper}%`;
}

const AggregationCriteriaForm: React.FC<AggregationCriteriaProps> = (props) => {
  const dispatch = useDispatch();

  const [currentRevenueRange, setCurrentRevenueRange] = useState(
    props.revenueRange
  );

  useEffect(() => {
    setCurrentRevenueRange(props.revenueRange);
  }, [props.revenueRange]);

  // const roundedCount = Math.ceil(AggregationCriteriaTypes.length / 4) * 4;
  const roundedCount = 3;

  return (
    <StyledAggregationCriteria>
      <div className="aggregation-criteria">
        {_.range(0, roundedCount).map((index) => {
          const type = AggregationCriteriaTypes[index];
          if (type !== undefined) {
            return (
              <AggregationCriteriaItem
                key={index}
                enabled={type.enabled}
                active={props.aggregationCriteria === type.value}
                onClick={() => {
                  if (type.enabled) {
                    trackButtonClick(`Map_Criteria_${type.value}`);
                    dispatch(setAggregationCriteria(type.value));
                  }
                }}
              >
                {type.label}
              </AggregationCriteriaItem>
            );
          } else {
            return (
              <AggregationCriteriaItem
                key={index}
                enabled={false}
                active={false}
              />
            );
          }
        })}
      </div>
      <div className="control-section">
        <h3>업체매출범위</h3>
        <div className="description">
          {describeRange(
            {
              from: currentRevenueRange[0].toString(),
              to: currentRevenueRange[1].toString(),
            },
            RevenueIndexedType
          )}
          &nbsp;(
          {describeBoundaries(
            {
              from: currentRevenueRange[0].toString(),
              to: currentRevenueRange[1].toString(),
            },
            RevenueIndexedType
          )}
          )
        </div>
        <DoubleEndedSlider
          marks={RevenueMarks}
          min={0}
          max={RevenueMarks.length - 1}
          step={1}
          value={[...currentRevenueRange]}
          onChange={(_: React.ChangeEvent<{}>, value: number | number[]) => {
            setCurrentRevenueRange(value as number[]);
          }}
          onChangeCommitted={(
            _: React.ChangeEvent<{}>,
            value: number | number[]
          ) => {
            const values = value as number[];
            trackButtonClick('Map_RevenueRange', {
              from: values[0],
              to: values[1],
            });
            dispatch(setRevenueRange(values));
          }}
        />
      </div>
      <ControlSectionInterval />
      <div className="control-section">
        <h3>업종분류</h3>
        <StoreCategories />
      </div>
    </StyledAggregationCriteria>
  );
};

export default AggregationCriteriaForm;
