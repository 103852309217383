import React from 'react';

import { ReactComponent as Hint } from 'assets/icons/Hint.svg';
import { ReactComponent as HintEmpty } from 'assets/icons/HintEmpty.svg';

interface IconHintProps {
  size: string;
  selected: boolean;
  onClick?: () => void;
}

const IconHint = ({ size, selected, onClick, ...rest }: IconHintProps) => {
  return selected ? (
    <Hint width={size} height={size} onClick={onClick} {...rest} />
  ) : (
    <HintEmpty width={size} height={size} onClick={onClick} {...rest} />
  );
};

export default IconHint;
