import React from 'react';
import styled from 'styled-components';
import { subYears, subMonths, format } from 'date-fns';

import BarChart, { BAR_CHART_COLORS } from 'components/BarChart';
import ViewSwitch from 'components/ViewSwitch';
import TrendChart from 'components/TrendChart';
import EmptyDataPlaceholder from 'components/EmptyDataPlaceholder';
import DurationView from 'components/DurationView';
import TipBox from 'components/TipBox';

import { AnalasysText } from './style';

import { trackButtonClick } from 'utils/trackEvent';
import { TrendChartData, BarChartData } from 'models/ChartData';

export interface VisitUserData {
  status: BarChartData | null;
  trend: TrendChartData | null;
}

const VISIT_USER_LEGENDS = [
  { label: '신규방문', type: 'new', ...BAR_CHART_COLORS[0] },
  { label: '2회 방문', type: 'visit_2', ...BAR_CHART_COLORS[1] },
  { label: '3~9회 방문', type: 'visit_3_9', ...BAR_CHART_COLORS[2] },
  { label: '10회 이상', type: 'visit_10_more', ...BAR_CHART_COLORS[3] },
];

const VISIT_USER_TYPES = [
  '신규방문',
  '2회 방문',
  '3~9회 방문',
  '10회 이상 방문',
];

function userType(index: number, subject: string) {
  // FIXME:
  return subject === '매출비중이'
    ? VISIT_USER_TYPES[index] + ' 고객'
    : VISIT_USER_TYPES[index];
}

function buildStatusAnalasysText(
  statusData: BarChartData | null,
  subject: string
) {
  if (statusData === null) return null;

  const sum = statusData.values.reduce((acc: number, value) => acc + value);
  const indexOfMaxValue = statusData.values.indexOf(
    Math.max(...statusData.values)
  );

  return (
    <AnalasysText>
      <b>{userType(indexOfMaxValue, subject)}</b>의 {subject}{' '}
      <strong>
        {statusData.captions[indexOfMaxValue]} (
        {Math.round((statusData.values[indexOfMaxValue] / sum) * 100)}%)
      </strong>{' '}
      으로 가장 높습니다.
    </AnalasysText>
  );
}

function buildTrendAnalasysText(
  trendData: TrendChartData | null,
  subject: string,
  yFormatter: (n: number) => string
) {
  if (trendData === null) return null;

  let indexOfMaxUserType = 0;
  let indexOfMaxMonth = 0;
  let max = -1;
  trendData.values.forEach((_, i) => {
    trendData.values[i].forEach((value, j) => {
      if (value > max) {
        max = value;
        indexOfMaxUserType = i;
        indexOfMaxMonth = j;
      }
    });
  });

  const sum = trendData.values.reduce(
    (acc: number, monthValues) => acc + monthValues[indexOfMaxMonth],
    0
  );

  const maxValue = trendData.values[indexOfMaxUserType][indexOfMaxMonth];

  return (
    <AnalasysText>
      <b>{userType(indexOfMaxUserType, subject)}</b>의 {subject}{' '}
      <strong>{trendData.labels[indexOfMaxMonth]}월</strong>에{' '}
      <strong>
        {yFormatter(maxValue)}({Math.round((maxValue / sum) * 100)}%)
      </strong>{' '}
      으로 가장 높습니다.
    </AnalasysText>
  );
}

const Wrapper = styled.div`
  margin-top: 20px;
`;

interface VisitUserDataViewProps {
  data: VisitUserData | null;
  subject: string;
  yFormatter: (n: number) => string;
  eventPrefix: string;
}

const VisitUserDataView = ({
  data,
  subject,
  yFormatter,
  eventPrefix,
}: VisitUserDataViewProps) => {
  if (data === null) return null;

  const { status: statusData, trend: trendData } = data;
  const statusAnalasysText = buildStatusAnalasysText(statusData, subject);
  const trendAnalasysText = buildTrendAnalasysText(
    trendData,
    subject,
    yFormatter
  );

  const current = new Date();
  const start = format(subYears(current, 1), 'yyyy년 MM월');
  const end = format(subMonths(current, 1), 'yyyy년 MM월');

  return (
    <Wrapper>
      <ViewSwitch
        titles={['현황', '트렌드']}
        components={[
          statusData === null ? (
            <EmptyDataPlaceholder kind="data_not_ready" />
          ) : (
            <>
              <DurationView start={start} end={end} />
              {statusAnalasysText}
              <BarChart
                width={350}
                height={176}
                data={statusData}
                legends={VISIT_USER_LEGENDS}
              />
              <TipBox
                mt="20px"
                title="현황 데이터 활용TIP!"
                tips={[
                  '신규고객 / 재방문고객 규모를 파악하고, 매출에 영향력 있는 고객군을 이해하여, 그에 맞는 마케팅 및 프로모션을 효과적으로 진행할 수 있습니다.',
                ]}
              />
            </>
          ),
          trendData === null ? (
            <EmptyDataPlaceholder kind="data_not_ready" />
          ) : (
            <>
              <DurationView start={start} end={end} />
              {trendAnalasysText}
              <TrendChart
                width={350}
                height={250}
                data={trendData}
                legends={VISIT_USER_LEGENDS}
                yFormatter={yFormatter}
                onToggleLegend={(v) =>
                  trackButtonClick(
                    `${eventPrefix}_Trend_Legend_${VISIT_USER_LEGENDS[v].type}`
                  )
                }
              />
            </>
          ),
        ]}
        onChange={(index) =>
          trackButtonClick(
            index === 0 ? `${eventPrefix}_Status` : `${eventPrefix}_Trend`
          )
        }
      />
    </Wrapper>
  );
};

export default VisitUserDataView;
