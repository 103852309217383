import React, { useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import DoubleEndedSlider from 'components/DoubleEndedSlider';
import { ConversionDuration } from './conversionTrackingAnalysisSlice';

const ConversionDurationMarks = _.range(1, 14).map((value) => ({
  label: `${value}주`,
  value: value,
}));

const StyledConversionDurationControl = styled.div`
  width: 344px;
`;

const ConversionDurationControl = ({
  conversionDuration,
  onUpdate,
}: {
  conversionDuration: ConversionDuration;
  onUpdate: (value: any) => void;
}) => {
  const [currentValue, setCurrentValue] = useState(conversionDuration);

  return (
    <StyledConversionDurationControl>
      <DoubleEndedSlider
        min={1}
        max={13}
        step={1}
        marks={ConversionDurationMarks}
        value={[currentValue.startWeek, currentValue.endWeek]}
        onChange={(_, value: number | number[]) => {
          const [startWeek, endWeek] = value as number[];
          setCurrentValue({ startWeek, endWeek });
        }}
        onChangeCommitted={(_, value: number | number[]) => {
          const value_ = value as number[];
          onUpdate({ startWeek: value_[0], endWeek: value_[1] });
        }}
      />
    </StyledConversionDurationControl>
  );
};

export default ConversionDurationControl;
