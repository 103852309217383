import React, { useState, useEffect, useRef } from 'react';
import Popover from '@material-ui/core/Popover';
import { Box, Text, Flex } from 'rebass/styled-components';

import { Account } from 'types';
import { Business as CashnoteBusiness } from 'graphql/types';

import Button from 'components/common/Button';

import { useCashnoteLogout } from 'hooks/useCashnoteLogin';
import { useAccount, useLoginSucceeded } from 'contexts/AccountContext';
import paths from 'paths';

import userProfile from 'assets/user-profile.svg';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPoi } from 'features/mapView/mapSlice';

function validBusinessNames(account: Account | null | undefined) {
  if (!account) {
    return '';
  }

  if (account.source === 'CASHNOTE') {
    const businesses = account.cashnoteAccount!.businesses!
      .nodes as CashnoteBusiness[];

    return businesses.map((business) => business.name).join(', ');
  } else if (account.source === 'FRANCHISE') {
    return '';
  }

  return '';
}

const UserProfile = () => {
  const account = useAccount();
  const loginSucceeded = useLoginSucceeded();
  const logout = useCashnoteLogout();
  const history = useHistory();
  const dispatch = useDispatch();

  const anchorElement = useRef();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(loginSucceeded);
  }, [loginSucceeded]);

  const handleClick = () => {
    if (account) {
      setOpen(true);
    } else {
      paths.push(paths.auth.index());
    }
  };

  const handleLogout = () => {
    setOpen(false);
    logout();
    dispatch(setPoi(null));
    history.push('/');
  };

  return (
    <>
      <Flex
        height="64px"
        alignItems="center"
        justifyContent="center"
        sx={{ cursor: 'pointer' }}
        ref={anchorElement}
        onClick={handleClick}
      >
        <img src={userProfile} alt="User Profile" />
      </Flex>
      <Popover
        open={open && !!account}
        anchorEl={anchorElement.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: -10,
        }}
      >
        <Box p="20px" maxWidth="300px">
          <Flex alignItems="center">
            <Text fontSize="14px" mr="8px">
              <Text as="span" color="primary" fontWeight="bold">
                {account?.name}
              </Text>{' '}
              님
            </Text>
            <Button
              variant="outline"
              onClick={handleLogout}
              py="4px"
              px="8px"
              width="initial"
              fontSize="11px"
            >
              로그아웃
            </Button>
          </Flex>
          <Text fontSize="14px" mt="10px" sx={{ wordBreak: 'break-all' }}>
            {validBusinessNames(account)}
          </Text>
        </Box>
      </Popover>
    </>
  );
};

export default UserProfile;
