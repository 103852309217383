import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Store, { Franchise } from 'models/Store';
import { AppThunk } from 'app/store';
import bluebird from 'api/bluebird';

export interface StoreState {
  franchises?: Franchise[];
  currentStore?: Store;
}

const initialState: StoreState = {
  franchises: [],
  currentStore: undefined,
};

const store = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setCurrentStore(state, action: PayloadAction<Store | undefined>) {
      state.currentStore = action.payload;
    },
    setFranchises(state, action: PayloadAction<Franchise[] | undefined>) {
      state.franchises = action.payload;
    },
  },
});

export const { setCurrentStore, setFranchises } = store.actions;

export default store.reducer;

export const fetchFranchiseStores = (): AppThunk => async (
  dispatch,
  getState
) => {
  let {
    data: { brands },
  } = await bluebird.franchiseStores.stores();

  dispatch(setFranchises(brands));

  brands?.some((b: any) => {
    if (b.stores.length > 0) {
      const store = b.stores.find((s: any) => s.allowed);
      if (store) {
        dispatch(setCurrentStore(store));

        return true;
      }
    }
  });

  const { store } = getState();

  if (store.currentStore === undefined) {
    dispatch(setCurrentStore(brands?.[0].stores[0]));
  }
};
