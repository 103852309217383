import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IntervalRange } from 'models/QuantizedRange';
import { StoreCategoryCombination } from 'models/StoreCategories';

export interface AggregationCriteriaState {
  aggregationCriteria: string;
  revenueRange: number[];
  intervalRange: IntervalRange;
  storeCategoryCombination: StoreCategoryCombination;
}

const initialState: AggregationCriteriaState = {
  aggregationCriteria: 'average-revenue',
  intervalRange: {
    from: '1m',
    to: '2m',
  },
  revenueRange: [0, 3],
  storeCategoryCombination: {},
};

const aggregationCriteria = createSlice({
  name: 'aggregationCriteria',
  initialState,
  reducers: {
    setAggregationCriteria(state, action: PayloadAction<string>) {
      state.aggregationCriteria = action.payload;
    },
    setRevenueRange(state, action: PayloadAction<number[]>) {
      state.revenueRange = action.payload;
    },
    setIntervalRange(state, action: PayloadAction<IntervalRange>) {
      state.intervalRange = action.payload;
    },
    setStoreCategoryCombination(
      state,
      action: PayloadAction<StoreCategoryCombination>
    ) {
      state.storeCategoryCombination = action.payload;
    },
    clear(state) {
      Object.keys(initialState).forEach((key) => {
        // @ts-ignore
        state[key] = initialState[key];
      });
    },
  },
});

export const {
  setAggregationCriteria,
  setRevenueRange,
  setIntervalRange,
  setStoreCategoryCombination,
  clear,
} = aggregationCriteria.actions;

export default aggregationCriteria.reducer;
