import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Text } from 'components/common';
import Position from 'components/common/Position';
import Button from 'components/common/Button';
import Container from './Container';

import paths from 'paths';

import mockSide from 'assets/images/store-anaysis-mock-side.jpg';

const StoreAnanysisMock = () => (
  <React.Fragment>
    <Container
      sx={{
        backgroundImage: `url(${mockSide})`,
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Position
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        bg="gray700"
        opacity="0.8"
      />
      <Position position="absolute" top="0" left="0" right="0" bottom="0">
        <Box m="40px" p="44px 20px" bg="gray100" sx={{ borderRadius: '20px' }}>
          <Text fontSize="22px" fontWeight="bold">
            우리 고객은 내 가게를 자주 올까, 옆 가게를 자주 갈까?
          </Text>
          <Text mt="3">
            내 상권의 우리매장과 경쟁매장의 고객을 비교해보세요.
            <br />
            (비대면 바우처로 90% 할인된 금액으로 상권노트를 이용해보세요!)
          </Text>
          <Box mt="3">
            <a
              href={paths.external.franchiseNote.signup()}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="primary" fontWeight="bold">
                입점 신청하기
              </Button>
            </a>
            <Box mt="3">
              <Link to={paths.auth.index()}>
                <Text
                  color="primary"
                  textAlign="center"
                  fontSize="16px"
                  fontWeight="bold"
                >
                  [로그인 하기]
                </Text>
              </Link>
            </Box>
          </Box>
        </Box>
      </Position>
    </Container>
  </React.Fragment>
);

export default StoreAnanysisMock;
